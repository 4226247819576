import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post } from 'src/lib/request'
import {
  TLiveOrder,
  TLiveOrderCombinedBucket,
  TLiveOrderDistributedBucket,
} from './bucket.codecs'
import { Mixed, TypeOf } from 'io-ts'

const bucket = 'purchases/checkouts/events/:eventId/buckets/me'
const tickets = `${bucket}/tickets`
const passes = `${bucket}/passes`
const process = `${bucket}/process`

type BucketRequestInput<Input, T extends Mixed> = {
  distributedBucket: boolean
  codec: T
  input: Input
}

const bucketRequestFactory = <Input>(
  request: <C extends Mixed>(
    input: BucketRequestInput<Input, C>,
  ) => Promise<TypeOf<C>>,
) =>
  [
    async (input: Input) =>
      await request({
        distributedBucket: true,
        codec: TLiveOrderDistributedBucket,
        input,
      }),
    async (input: Input) =>
      await request({
        distributedBucket: false,
        codec: TLiveOrderCombinedBucket,
        input,
      }),
  ] as const

export type GetBucketInput = {
  eventId: UUID
}

const getBucket = async <T extends Mixed>({
  input,
  distributedBucket,
  codec,
}: BucketRequestInput<GetBucketInput, T>) => {
  const query = new URLSearchParams({
    distributedBucket: String(distributedBucket),
  })

  return pipe(
    await get(bucket, {
      params: {
        eventId: input.eventId,
      },
      query,
    }),
    decodeJson(codec),
  )
}
export const [getDistributedBucket, getCombinedBucket] =
  bucketRequestFactory(getBucket)

type AddTicketToBucketInput = {
  eventId: UUID
  seatId: UUID
  variationId: UUID
  companionPass: boolean
  companionSeatId?: UUID
}

const addTicketToBucket = async <T extends Mixed>({
  input,
  distributedBucket,
  codec,
}: BucketRequestInput<AddTicketToBucketInput, T>) => {
  const query = new URLSearchParams({
    seatId: input.seatId,
    variationId: input.variationId,
    companionPass: String(input.companionPass),
    distributedBucket: String(distributedBucket),
  })

  if (input.companionSeatId) {
    query.set('companionSeatId', String(input.companionSeatId))
  }

  return pipe(
    await post(tickets, {
      params: {
        eventId: input.eventId,
      },
      query,
    }),
    decodeJson(codec),
  )
}
export const [addTicketToDistributedBucket, addTicketToCombinedBucket] =
  bucketRequestFactory(addTicketToBucket)

type AddCompanionPassToTicketInput = {
  eventId: UUID
  seatId: UUID
  variationId: UUID
  companionSeatId?: UUID
  purchaseId?: UUID
}

const addCompanionPassToTicket = async <T extends Mixed>({
  input,
  distributedBucket,
  codec,
}: BucketRequestInput<AddCompanionPassToTicketInput, T>) => {
  const query = new URLSearchParams({
    seatId: input.seatId,
    variationId: input.variationId,
    distributedBucket: String(distributedBucket),
  })

  if (input.purchaseId) {
    query.set('purchaseId', input.purchaseId)
  }
  if (input.companionSeatId) {
    query.set('companionSeatId', String(input.companionSeatId))
  }

  return pipe(
    await post(passes, {
      params: {
        eventId: input.eventId,
      },
      query,
    }),
    decodeJson(codec),
  )
}
export const [
  addCompanionPassToTicketDistributedBucket,
  addCompanionPassToTicketCombinedBucket,
] = bucketRequestFactory(addCompanionPassToTicket)

type RemoveTicketFromBucketInput = {
  eventId: UUID
  purchaseId?: UUID
  seatId: UUID
  variationId: UUID
}

const removeTicketFromBucket = async <T extends Mixed>({
  input,
  distributedBucket,
  codec,
}: BucketRequestInput<RemoveTicketFromBucketInput, T>) => {
  const query = new URLSearchParams({
    seatId: input.seatId,
    variationId: input.variationId,
    distributedBucket: String(distributedBucket),
  })

  if (input.purchaseId) {
    query.set('purchaseId', input.purchaseId)
  }

  return pipe(
    await del(tickets, {
      params: {
        eventId: input.eventId,
      },
      query,
    }),
    decodeJson(codec),
  )
}
export const [
  removeTicketFromDistributedBucket,
  removeTicketFromCombinedBucket,
] = bucketRequestFactory(removeTicketFromBucket)

type RemoveCompanionPassFromTicketInput = {
  eventId: UUID
  seatId: UUID
  variationId: UUID
  purchaseId?: UUID
}

const removeCompanionPassFromTicket = async <T extends Mixed>({
  input,
  distributedBucket,
  codec,
}: BucketRequestInput<RemoveCompanionPassFromTicketInput, T>) => {
  const query = new URLSearchParams({
    seatId: input.seatId,
    variationId: input.variationId,
    distributedBucket: String(distributedBucket),
  })

  if (input.purchaseId) {
    query.set('purchaseId', input.purchaseId)
  }

  return pipe(
    await del(passes, {
      params: {
        eventId: input.eventId,
      },
      query,
    }),
    decodeJson(codec),
  )
}
export const [
  removeCompanionPassFromTicketDistributedBucket,
  removeCompanionPassFromTicketCombinedBucket,
] = bucketRequestFactory(removeCompanionPassFromTicket)

type ProcessOrderInput = {
  eventId: UUID
  email: string
  saveEmail: boolean
}

export const createProcessOrderRequest =
  <T extends Mixed>({
    distributedBucket,
    codec,
  }: {
    distributedBucket?: boolean
    codec: T
  }) =>
  async (input: ProcessOrderInput) => {
    const query = new URLSearchParams({
      email: input.email,
      saveEmail: String(input.saveEmail),
    })

    if (distributedBucket !== undefined) {
      query.set('distributedBucket', String(distributedBucket))
    }

    return pipe(
      await patch(process, {
        query,
        params: {
          eventId: input.eventId,
        },
      }),
      decodeJson(codec),
    )
  }

export const processOrder = createProcessOrderRequest({ codec: TLiveOrder })

type CancelProcessOrderInput = {
  eventId: UUID
}

export const cancelProcessOrder = async (input: CancelProcessOrderInput) => {
  return await patch(`${process}/cancel`, {
    params: { eventId: input.eventId },
  })
}
