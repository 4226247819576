import { SvgIcon, SvgIconProps } from '@mui/material'

export const TicketsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <rect
      x="2.83382"
      y="4.80208"
      width="18.3333"
      height="14.3344"
      rx="0.833333"
      stroke="currentColor"
      strokeWidth="1.66667"
      fill="transparent"
    />
    <rect
      x="6.46354"
      y="8.4401"
      width="1.16667"
      height="7.16667"
      stroke="currentColor"
      strokeWidth="0.833333"
      fill="transparent"
    />
    <rect
      x="9.29492"
      y="8.27344"
      width="0.5"
      height="7.5"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <rect
      x="11.293"
      y="8.27344"
      width="0.5"
      height="7.5"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <rect
      x="13.4577"
      y="8.4401"
      width="2.16667"
      height="7.16667"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.833333"
    />
    <rect
      x="17.2891"
      y="8.27344"
      width="0.5"
      height="7.5"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </SvgIcon>
)
