import { intersection, literal, number, type, TypeOf } from 'io-ts'
import { TEvent } from '../public'

export const TTicketsEvent = intersection([
  TEvent,
  type({
    payload: type({
      payloadType: literal('WITH_TICKETS_COUNT'),
      ticketsCount: number,
    }),
  }),
])
export type ITicketsEvent = TypeOf<typeof TTicketsEvent>
