import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 11.8605C9.93321 11.8605 8.25 10.1773 8.25 8.11051C8.25 6.04372 9.93321 4.36051 12 4.36051C14.0668 4.36051 15.75 6.04372 15.75 8.11051C15.75 10.1773 14.0668 11.8605 12 11.8605ZM20.25 20.9997C20.25 21.1378 20.1381 21.2497 20 21.2497H4C3.86193 21.2497 3.75 21.1378 3.75 20.9997C3.75 17.5549 6.55428 14.7497 10 14.7497H14C17.4448 14.7497 20.25 17.555 20.25 20.9997Z"
        stroke="white"
        strokeWidth="1.5"
        fill="none"
      />
    </SvgIcon>
  )
}
