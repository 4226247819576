import { Typography, Chip, Stack } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { NextLink } from 'src/components/next-link'

type Props = {
  label: string
  href: string
  isDisabled: boolean
  onClose: () => void
  menuItemType: 'main' | 'sub'
}

export const MenuItemLink = ({
  label,
  href,
  onClose,
  menuItemType,
  isDisabled,
}: Props) => {
  const { t } = useTranslation()
  const fontVariant = menuItemType === 'main' ? 'h2ExtraBold' : 'h3ExtraBold'

  return (
    <NextLink
      href={href}
      color="common.white"
      onClick={onClose}
      disabled={isDisabled}
      sx={{ '&&': { textDecorationThickness: { xs: '1.5px', xl: '3px' } } }}
    >
      <Stack direction="row" spacing={1}>
        <Typography display="inline-block" variant={fontVariant}>
          {label}
        </Typography>
        {isDisabled && <Chip variant="comingSoon" label={t('coming')} />}
      </Stack>
    </NextLink>
  )
}
