import { SvgIcon, SvgIconProps } from '@mui/material'

export const AppleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M18.0489 12.6262C18.0386 10.8229 18.8343 9.46385 20.4412 8.46173C19.5425 7.13967 18.1828 6.41253 16.3906 6.27239C14.6936 6.1349 12.8369 7.28774 12.1571 7.28774C11.4386 7.28774 9.79571 6.31999 8.503 6.31999C5.83519 6.36229 3 8.50403 3 12.8616C3 14.1492 3.22918 15.4792 3.68755 16.8489C4.30043 18.6522 6.50987 23.0705 8.81459 22.9991C10.0197 22.9701 10.8721 22.1213 12.4403 22.1213C13.9622 22.1213 14.7502 22.9991 16.0944 22.9991C18.4197 22.9648 20.418 18.9483 21 17.1398C17.8815 15.63 18.0489 12.7188 18.0489 12.6262ZM15.3425 4.56164C16.6481 2.96988 16.5296 1.52089 16.491 1C15.3373 1.06875 14.0034 1.80646 13.2438 2.71339C12.4069 3.68643 11.915 4.88951 12.0206 6.24595C13.267 6.34378 14.4051 5.68539 15.3425 4.56164Z"
        fill="black"
      />
    </SvgIcon>
  )
}
