import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FabniteFooterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 19.9998C40 31.0453 31.0457 39.9996 20.0002 39.9996C8.9543 39.9996 0 31.0453 0 19.9998C0 8.95393 8.9543 0 20.0002 0C31.0457 0 40 8.95393 40 19.9998Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2517 16.0201C29.6214 15.7537 30.6305 15.0727 31.171 14.0497C31.7276 12.9953 31.7626 11.5577 31.2608 10.384C30.2283 8.57894 28.6813 7.5242 26.2466 6.96435C25.3776 6.76472 24.4313 6.60742 23.4445 6.60742C21.2072 6.60742 18.762 7.41654 16.5438 10.3778C14.2628 13.4227 13.7518 16.0822 13.2113 18.8982C12.5836 22.1675 11.9343 25.5481 8.57196 29.9419C8.40882 30.1404 8.37269 30.2609 8.38948 30.3054C8.40846 30.355 8.52087 30.4506 9.05845 30.5328C9.59385 30.6152 14.6672 29.8835 15.7956 27.2299C16.2285 26.212 16.539 25.2503 16.8394 24.3197C17.8423 21.2124 18.6343 18.7584 23.0174 17.9143C23.3072 17.8588 23.5364 17.7716 23.7182 17.6478C24.058 17.4164 24.0521 17.2084 24.0112 16.8296C23.9342 16.1176 23.5269 16.0617 22.6872 16.0114C21.7689 15.9559 20.5116 15.88 19.8843 14.1544C19.427 12.8971 19.7445 11.5753 20.7784 10.4322C22.0664 9.0074 24.1791 8.17128 25.5882 8.52857C26.2802 8.70412 26.732 9.15046 26.8944 9.81907C27.2262 11.184 26.4254 11.8774 25.7824 12.4347C25.4076 12.7592 25.0536 13.0658 24.9652 13.449C24.8123 14.1157 25.0266 14.85 25.5247 15.3657C26.1466 16.0088 27.1152 16.2413 28.2517 16.0201Z"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2208 30.5369C21.7748 30.5621 22.3639 30.3442 22.8219 29.9391C23.3215 29.4975 23.6135 28.8793 23.6442 28.1979C23.6617 27.8041 23.4854 27.426 23.1478 27.1329C22.8007 26.8322 22.3263 26.6537 21.8121 26.6308C21.1664 26.6052 20.5548 26.845 20.0982 27.3158C19.6748 27.7519 19.4168 28.3431 19.3902 28.9377C19.3504 29.8293 20.1033 30.4869 21.2208 30.5369Z"
        fill="#00247A"
      />
    </SvgIcon>
  )
}
