import { IApplicationLocale } from 'src/app/locale'
import { NextLink } from 'src/components/next-link'
import { useLocale } from 'src/lib/use-locale'

type Props = {
  href: string
  icons: Record<IApplicationLocale, () => JSX.Element>
}

export const AppLink = ({ href, icons }: Props) => {
  const { locale } = useLocale()

  const Icon = icons[locale]

  return (
    <NextLink href={href} target="_blank" display="flex">
      <Icon />
    </NextLink>
  )
}
