
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { CssBaseline, ThemeProvider, Box } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';
import { NextPage } from 'next';
import Router from 'next/router';
import { DefaultSeo } from 'next-seo';
import { ResponsiveStyleValue } from '@mui/system';
import Script from 'next/script';
import { Session } from 'next-auth';
import { initStripe } from 'src/modules/payments/utils';
import { theme } from 'src/app/theme';
import { useBoolean } from 'src/lib/use-boolean';
import { IApiLocale, apiLocaleToApplicationLocale, applicationLocales, localeToLanguageAttribute, } from 'src/app/locale';
import { getAbsoluteUrlWithLocale } from 'src/lib/get-path-with-locale';
import { toasterProps } from 'src/components/toaster';
import { GlobalLoading, GlobalLoadingProvider, } from 'src/layouts/global-loading';
import { NavigationBar } from 'src/layouts/navigation-bar';
import { AccessTokenHandler } from 'src/app/access-token-handler';
import { Footer } from 'src/layouts/footer';
import { env } from 'src/app/env/env';
import { GoogleMapsLoadingStatusProvider } from 'src/lib/use-load-google-maps';
import { useLocale } from 'src/lib/use-locale';
import { IPGeolocationProvider } from 'src/lib/use-geolocation';
import { UserGeolocationProvider } from 'src/lib/use-user-geolocation';
import '../src/assets/styles/global.css';
initStripe();
type NextPageWithProps = NextPage & {
    displayNavBar?: boolean;
    displayFooter?: boolean;
    displayFooterBreakpoints?: ResponsiveStyleValue<string>;
    paddingBottom?: number;
    isAuthRequired?: boolean;
};
type AppPropsWithLayout = AppProps<{
    session: Session;
    disableNavigationBarGradient?: boolean;
    locales?: Array<IApiLocale>;
}> & {
    Component: NextPageWithProps;
};
// eslint-disable-next-line react/function-component-definition
function CustomApp({ Component, pageProps: { session, disableNavigationBarGradient, locales, ...pageProps }, }: AppPropsWithLayout) {
    const { t } = useTranslation();
    const router = useRouter();
    const { locale: currentLocale } = useLocale();
    const queryClient = useMemo(() => new QueryClient(), []);
    const [refetchSessionInterval, setRefetchSessionInterval] = useState(0);
    const isNavBar = Component.displayNavBar ?? true;
    const isFooter = Component.displayFooter ?? true;
    const paddingBottom = Component.paddingBottom ?? {
        xs: 4,
        sm: 6,
        lg: 9,
        xl: 10,
    };
    const isAuthRequired = Component.isAuthRequired ?? false;
    const isPageLoading = useBoolean(false);
    const startPageLoading = isPageLoading.setTrue;
    const finishPageLoading = isPageLoading.setFalse;
    const globalLoading = {
        startGlobalLoading: startPageLoading,
        endGlobalLoading: finishPageLoading,
    };
    useEffect(() => {
        Router.events.on('routeChangeStart', startPageLoading);
        Router.events.on('routeChangeComplete', finishPageLoading);
        Router.events.on('routeChangeError', finishPageLoading);
        return () => {
            Router.events.off('routeChangeStart', startPageLoading);
            Router.events.off('routeChangeComplete', finishPageLoading);
            Router.events.off('routeChangeError', finishPageLoading);
        };
    }, [finishPageLoading, startPageLoading]);
    const isSeoDisabled = env.environment === 'testing' || env.environment === 'staging';
    const pageLocales = locales?.map((locale) => apiLocaleToApplicationLocale[locale]);
    const pageLanguageAlternates = pageLocales ?? applicationLocales;
    const arePageTranslationsAbsent = pageLanguageAlternates.length < 2;
    const pageDefaultLocale = pageLanguageAlternates[0];
    return (<>
      <DefaultSeo defaultTitle={t('fabnite')} titleTemplate={`%s // ${t('fabnite')}`} title={t(`pages.${router.pathname}`)} dangerouslySetAllPagesToNoFollow={isSeoDisabled} dangerouslySetAllPagesToNoIndex={isSeoDisabled} languageAlternates={!arePageTranslationsAbsent
            ? pageLanguageAlternates.map((locale) => ({
                hrefLang: localeToLanguageAttribute[locale],
                href: getAbsoluteUrlWithLocale({
                    locale,
                    path: router.asPath,
                }),
            }))
            : undefined} canonical={arePageTranslationsAbsent &&
            pageDefaultLocale !== undefined &&
            currentLocale !== pageDefaultLocale
            ? getAbsoluteUrlWithLocale({
                locale: pageDefaultLocale,
                path: router.asPath,
            })
            : undefined}/>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <Script id="data-layer">
        window.dataLayer = window.dataLayer || [];
      </Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${env.gtmAuth}&gtm_preview=${env.gtmPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${env.gtmId}');
        `}
      </Script>
      <Script src={`https://www.google.com/recaptcha/api.js?render=${env.reCaptchaKey}`}/>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster {...toasterProps}/>
        <QueryClientProvider client={queryClient}>
          <SessionProvider session={session} refetchInterval={refetchSessionInterval}>
            <GoogleMapsLoadingStatusProvider>
              <GlobalLoadingProvider {...globalLoading}>
                <UserGeolocationProvider>
                  <IPGeolocationProvider>
                    <Box maxWidth="100%" minHeight="100svh" display="flex" flex={1} flexDirection="column">
                      {isNavBar ? (<NavigationBar isPageLoading={isPageLoading.isTrue} isDisableGradient={disableNavigationBarGradient}/>) : (<GlobalLoading isPageLoading={isPageLoading.isTrue} sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
            }}/>)}
                      <Box flexGrow={1} pb={paddingBottom} minHeight={{ xs: '384px', sm: '456px' }} mt={isPageLoading.isTrue ? '-1px' : 0} sx={{ backgroundColor: 'backgroundColor.main' }}>
                        <AccessTokenHandler setInterval={setRefetchSessionInterval} isAuthRequired={isAuthRequired}>
                          <main>
                            <Component {...pageProps}/>
                          </main>
                        </AccessTokenHandler>
                      </Box>
                      {isFooter && (<Box display={Component.displayFooterBreakpoints}>
                          <Footer />
                        </Box>)}
                    </Box>
                  </IPGeolocationProvider>
                </UserGeolocationProvider>
              </GlobalLoadingProvider>
            </GoogleMapsLoadingStatusProvider>
          </SessionProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>);
}

    export default __appWithI18n(CustomApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  