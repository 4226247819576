import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { decodeJson, get } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { TPublicArticleDetails, TPublicArticlePreview } from '.'

const publicRoute = `contents/public` as const
const articles = `${publicRoute}/articles` as const
const explore = `${publicRoute}/explore` as const
const articlesExplore = `${explore}/articles` as const

export type GetPublicArticleInput = {
  articleSlug: string
} & LocaleInput

export const getPublicArticle = async (input: GetPublicArticleInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${articles}/${input.articleSlug}`, {
      headers,
    }),
    decodeJson(TPublicArticleDetails),
  )
}

export type GetFeaturedArticlesInput = LocaleInput

export const getFeaturedArticles = async (input: GetFeaturedArticlesInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${articlesExplore}/featured`, {
      headers,
    }),
    decodeJson(array(TPublicArticlePreview)),
  )
}

export const getPopularArticles = async () => {
  return pipe(
    await get(`${articlesExplore}/popular`, {}),
    decodeJson(array(TPublicArticlePreview)),
  )
}
