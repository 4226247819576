import { Locale } from 'date-fns'
import { nb, enUS } from 'date-fns/locale'
import { isRight } from 'fp-ts/lib/Either'
import { TypeOf } from 'io-ts'
import { nonEmptyArray } from 'io-ts-types'
import { enumToKeyof } from 'src/lib/types'

export enum ApplicationLocale {
  en = 'en',
  no = 'no',
}
const TApplicationLocale = enumToKeyof(ApplicationLocale)
export type IApplicationLocale = TypeOf<typeof TApplicationLocale>

// Probably locale will pass decoding 99% of the time. If inside the url is provided non system locale(de, fr, etc.) - 404 will be returned
export const decodeApplicationLocale = (locale: string) => {
  const decodedLocale = TApplicationLocale.decode(locale)

  return isRight(decodedLocale) ? decodedLocale.right : defaultApplicationLocale
}

export const applicationLocales: Array<IApplicationLocale> = [
  ApplicationLocale.en,
  ApplicationLocale.no,
]

export const defaultApplicationLocale: IApplicationLocale = ApplicationLocale.en

export enum ApiLocale {
  EN = 'EN',
  NO = 'NO',
}
const TApiLocale = enumToKeyof(ApiLocale)
export type IApiLocale = TypeOf<typeof TApiLocale>
export const TAvailableLanguages = nonEmptyArray(TApiLocale)

export const localeToLanguageAttribute: Record<IApplicationLocale, string> = {
  [ApplicationLocale.en]: 'en',
  [ApplicationLocale.no]: 'nb',
}

export const applicationLocaleToApiLocale: Record<
  IApplicationLocale,
  IApiLocale
> = {
  [ApplicationLocale.en]: ApiLocale.EN,
  [ApplicationLocale.no]: ApiLocale.NO,
}

export const apiLocaleToApplicationLocale: Record<
  IApiLocale,
  IApplicationLocale
> = Object.fromEntries(
  Object.entries(applicationLocaleToApiLocale).map(([k, v]) => [v, k]),
) as Record<IApiLocale, IApplicationLocale>

export const localeToDateFnsLocale: Record<IApplicationLocale, Locale> = {
  [ApplicationLocale.en]: enUS,
  [ApplicationLocale.no]: nb,
}
