import { SvgIcon, SvgIconProps } from '@mui/material'

export const FacebookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M22 11.9999C22 6.47704 17.5229 1.99989 12 1.99989C6.47715 1.99989 2 6.47704 2 11.9999C2 16.9912 5.65686 21.1282 10.4375 21.8784V14.8905H7.89844V11.9999H10.4375V9.79677C10.4375 7.29052 11.9304 5.90615 14.2146 5.90615C15.3087 5.90615 16.4531 6.10146 16.4531 6.10146V8.5624H15.1921C13.9499 8.5624 13.5625 9.33324 13.5625 10.1241V11.9999H16.3359L15.8926 14.8905H13.5625V21.8784C18.3431 21.1282 22 16.9912 22 11.9999Z"
        fill="#1877F2"
      />
    </SvgIcon>
  )
}
