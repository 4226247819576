import { pipe } from 'fp-ts/lib/function'
import {
  decodeJsonWithTotal,
  InfiniteScrollInput,
  InfiniteScrollPaginationInput,
  SearchInput,
} from 'src/lib/get-list'
import { decodeJson, get } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { TMonths, IMonth } from '..'
import {
  mapInfinitePaginationToUrlParams,
  mapSeeAllQueryParams,
} from '../public.utils'
import { TEvent, TEventArtists, TEventDetails } from './event.codecs'

const publicRoute = 'cms/public' as const
const events = `${publicRoute}/events` as const
const organizations = `${publicRoute}/organizations`

export type GetEventInput = {
  eventSlug: string
} & LocaleInput

export const getEvent = async (input: GetEventInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${events}/${input.eventSlug}`, {
      headers,
    }),
    decodeJson(TEventDetails),
  )
}

export type GetEventArtistsInput = {
  eventSlug: string
}

export const getEventArtists = async (input: GetEventArtistsInput) => {
  return pipe(
    await get(`${events}/${input.eventSlug}/performers`, {}),
    decodeJson(TEventArtists),
  )
}

export type GetEventsInput = InfiniteScrollPaginationInput &
  SearchInput & {
    placeId: string
  } & LocaleInput

export const getEventsList = async ({
  pageParam = 0,
  input: { placeId, search, limit, locale },
}: InfiniteScrollInput<GetEventsInput>) => {
  const query = mapSeeAllQueryParams({
    coordinates: null,
    pageParam,
    limit,
  })
  if (placeId) {
    query.set('placeId', placeId)
  }
  if (search.trim() !== '') {
    query.set('search', search.trim())
  }

  const { headers } = setAcceptLanguageHeader(locale)

  return pipe(
    await get(events, {
      query,
      headers,
    }),
    decodeJsonWithTotal(TEvent),
  )
}

export type GetEventListByOrganizationInput = InfiniteScrollPaginationInput & {
  organizationSlug: string
  month?: IMonth
} & LocaleInput

export const getEventListByOrganization = async ({
  pageParam = 0,
  input: { organizationSlug, limit, locale, month },
}: InfiniteScrollInput<GetEventListByOrganizationInput>) => {
  const { headers } = setAcceptLanguageHeader(locale)

  const query = mapInfinitePaginationToUrlParams({
    pageParam,
    limit,
  })

  if (month !== undefined) {
    query.set('month', month)
  }

  return pipe(
    await get(`${organizations}/${organizationSlug}/events`, {
      query,
      headers,
    }),
    decodeJsonWithTotal(TEvent),
  )
}

export type GetOrganizationMonthsInput = {
  organizationSlug: string
}

export const getOrganizationMonths = async (
  input: GetOrganizationMonthsInput,
) => {
  return pipe(
    await get(`${organizations}/${input.organizationSlug}/events/months`, {}),
    decodeJson(TMonths),
  )
}
