import { createListQueryParams } from 'src/lib/get-list'
import { UserCoordinates } from 'src/lib/use-user-geolocation'

export const mapInfinitePaginationToUrlParams = ({
  limit,
  pageParam,
}: {
  limit: number
  pageParam: number
}) => {
  return new URLSearchParams({
    limit: limit.toString(),
    skip: String(pageParam * limit),
  })
}

export const mapSeeAllQueryParams = ({
  coordinates,
  limit,
  pageParam,
}: {
  limit: number
  pageParam: number
  coordinates: UserCoordinates
}) => {
  const query = mapInfinitePaginationToUrlParams({ limit, pageParam })

  if (coordinates !== null) {
    query.set('longitude', String(coordinates.longitude))
    query.set('latitude', String(coordinates.latitude))
  }

  return query
}

export const eventRowPreviewQuery = createListQueryParams({
  direction: 'asc',
  sort: 'DATE',
  limit: 100,
  skip: 0,
  search: '',
})

export const mapCoordinatesToQueryParam = (coordinates: UserCoordinates) =>
  coordinates !== null
    ? new URLSearchParams({
        longitude: String(coordinates.longitude),
        latitude: String(coordinates.latitude),
      })
    : new URLSearchParams()
