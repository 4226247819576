import { InputAdornment } from '@mui/material'
import { SearchIcon } from 'src/assets/icons'

export const searchTextFieldProps = {
  InputProps: {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon sx={{ color: 'primary2.main', mr: '-16px' }} />
      </InputAdornment>
    ),
  },
}
