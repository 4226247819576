import { Stack } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useSession } from 'next-auth/react'
import { ProfileButton } from './components/profile-button'
import { SignInButton } from './components/sign-in-button'
import { NavigationButton } from './components/navigation-button'
import { MenuIcon } from './icons/menu'

type Props = {
  onMenuClick: () => void
}

export const DesktopNavigation = ({ onMenuClick }: Props) => {
  const { t } = useTranslation()
  const session = useSession()

  return (
    <Stack direction="row" spacing={2}>
      {/* Temporary search button */}
      {/*
      <NavigationButton
        icon={<Search sx={{ '&&': { fontSize: 24 } }} />}
        label={t('search')}
      /> */}
      {session.status === 'authenticated' && <ProfileButton />}
      {session.status === 'unauthenticated' && <SignInButton />}
      <NavigationButton
        onClick={onMenuClick}
        icon={
          <MenuIcon
            sx={{
              '&&': {
                fontSize: 36,
              },
            }}
          />
        }
        label={t('menu')}
      />
    </Stack>
  )
}
