import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const RedCurveLine = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 99 84" {...props}>
    <path
      d="M11.0377 63.9741C11.0377 63.9741 39.4294 83.593 43.6529 62.9958C47.8766 42.3993 29.2448 23.863 48.5256 19.5278C67.806 15.1932 101.41 60.0972 101.18 11.0182"
      stroke="#FC605B"
      strokeWidth="21.819"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)
