import { keyof } from 'io-ts'

export type EnumType<T extends string> = `${T}`

export const enumToKeyof = <T extends Record<string, string>>(
  enumObject: T,
) => {
  const keys = Object.fromEntries(
    Object.values(enumObject).map((key) => [key, null]),
  ) as { [keyofKey in EnumType<T[keyof T]>]: null }
  return keyof(keys)
}
