import { isLeft } from 'fp-ts/lib/Either'
import { Mixed, TypeOf } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'

import { requestError } from './error'
import { getValidationMessage } from './get-validation-message'

export const decodeHeaders = <TCodec extends Mixed>(codec: TCodec) => {
  return (response: Response): TypeOf<TCodec> => {
    const headers = Object.fromEntries(response.headers)
    const decodedJson = codec.decode(headers)
    if (isLeft(decodedJson)) {
      const errors = formatValidationErrors(decodedJson.left)
      throw requestError({
        headers,
        message: getValidationMessage(errors),
        type: 'decode_headers',
      })
    }
    return decodedJson.right
  }
}
