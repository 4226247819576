export const ArrowPreviousIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path
        d="M32.4363 11.4336C32.9979 10.7784 32.9221 9.79201 32.2669 9.23041C31.6117 8.66881 30.6253 8.74469 30.0637 9.39989L32.4363 11.4336ZM30.0637 40.6003C30.6253 41.2555 31.6117 41.3314 32.2669 40.7698C32.9221 40.2082 32.9979 39.2218 32.4363 38.5666L30.0637 40.6003ZM18.75 25.0001L17.5637 23.9832L16.6921 25.0001L17.5637 26.0169L18.75 25.0001ZM30.0637 9.39989L17.5637 23.9832L19.9363 26.0169L32.4363 11.4336L30.0637 9.39989ZM17.5637 26.0169L30.0637 40.6003L32.4363 38.5666L19.9363 23.9832L17.5637 26.0169Z"
        fill="white"
      />
    </svg>
  )
}
