import { globalAccessToken } from '../use-auth'
import { createRequest, createRequestMethods } from './create-request'

const apiPaths = [
  {
    source: 'auth',
    destination: process.env['API_AUTH'],
  },
  {
    source: 'cms',
    destination: process.env['API_CMS'],
  },
  {
    source: 'files',
    destination: process.env['API_FILES'],
  },
  {
    source: 'finance',
    destination: process.env['API_FINANCE'],
  },
  {
    source: 'trades',
    destination: process.env['API_TRADES'],
  },
  {
    source: 'pos',
    destination: process.env['API_POS'],
  },
  {
    source: 'purchases',
    destination: process.env['API_PURCHASES'],
  },
  {
    source: 'contents',
    destination: process.env['API_CONTENTS'],
  },
  {
    source: 'dictionaries',
    destination: process.env['API_DICTIONARIES'],
  },
]

export const getApiOriginUrl = (apiPath: string) => {
  return (
    apiPaths.find((path) => apiPath.startsWith(path.source))?.destination ?? ''
  )
}

export const { get, post, put, del, patch, options } = createRequestMethods(
  createRequest(({ requestInit, httpUrl }) => {
    // TODO: Consider replacing with an injectable service.
    const headers = new Headers(requestInit.headers)

    if (globalAccessToken !== null) {
      headers.set('Authorization', `Bearer ${globalAccessToken}`)
    }

    return {
      requestInit: {
        ...requestInit,
        headers,
      },
      originUrl: getApiOriginUrl(httpUrl),
    }
  }),
)
