import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FabniteLogoBlueBackgroundIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6275 24 12 24C5.37262 24 0 18.6274 0 12C0 5.37256 5.37262 0 12 0C18.6275 0 24 5.37256 24 12Z"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7764 7.966C13.3081 7.86254 13.6998 7.5982 13.9095 7.20123C14.1256 6.79191 14.1393 6.23397 13.9444 5.7784C13.5437 5.07766 12.9432 4.66819 11.9981 4.45096C11.661 4.3735 11.2936 4.3125 10.9105 4.3125C10.0421 4.3125 9.093 4.62651 8.232 5.77584C7.34662 6.95776 7.14833 7.99006 6.93855 9.08304C6.69478 10.352 6.44278 11.6643 5.13761 13.3698C5.07436 13.4469 5.06031 13.4936 5.06691 13.5109C5.07428 13.5302 5.1179 13.5673 5.32652 13.5992C5.53435 13.6311 7.50363 13.3472 7.94158 12.3171C8.1096 11.922 8.23013 11.5486 8.3467 11.1875C8.73599 9.98145 9.04341 9.02887 10.7448 8.70128C10.8572 8.6797 10.9462 8.64586 11.0167 8.59782C11.1487 8.50803 11.1464 8.42716 11.1306 8.28024C11.1005 8.00387 10.9425 7.98214 10.6165 7.9625C10.2602 7.94101 9.77216 7.91151 9.52855 7.24182C9.35105 6.7538 9.47437 6.24065 9.87562 5.79695C10.3757 5.2439 11.1956 4.91949 11.7427 5.0581C12.0112 5.12632 12.1865 5.29947 12.2496 5.559C12.3784 6.08884 12.0676 6.35799 11.818 6.57429C11.6724 6.70033 11.5351 6.81931 11.5008 6.96801C11.4414 7.22684 11.5245 7.5119 11.718 7.71198C11.9593 7.96165 12.3353 8.05191 12.7764 7.966Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1062 12.7251C10.878 12.7251 10.7248 12.5068 10.7248 12.1821C10.7248 11.3883 11.1463 10.1719 11.6657 10.1719C11.8578 10.1719 11.9725 10.2913 11.9725 10.4912C11.9725 11.2582 11.6351 12.7251 11.1062 12.7251ZM11.9325 12.7226L11.979 12.8392C11.9814 12.8451 12.2354 13.4334 12.9215 13.4334C13.5392 13.4334 14.0217 13.2158 14.3556 12.7865C14.4779 12.6273 14.537 12.5459 14.537 12.4397C14.537 12.4089 14.5095 12.3486 14.4459 12.3486C14.407 12.3486 14.3782 12.3636 14.2932 12.4486L14.2788 12.4601C13.9466 12.6702 13.6839 12.636 13.5223 12.5699C13.2565 12.4616 13.039 12.1735 12.9546 11.8181C12.8206 11.2546 13.1927 10.5298 13.4645 10.0005C13.5812 9.77314 13.6915 9.55832 13.6915 9.47845C13.6915 9.40015 13.6663 9.375 13.5881 9.375C13.4686 9.375 13.3015 9.46317 13.1398 9.54846C12.9494 9.64889 12.7525 9.75281 12.5734 9.75281C12.4873 9.75281 12.4034 9.72029 12.2969 9.67915C12.1195 9.61055 11.8766 9.51656 11.4668 9.51656C10.2519 9.51656 9.02588 10.3035 9.02588 12.0617C9.02588 13.0092 9.71482 13.4334 10.3975 13.4334C11.2121 13.4334 11.6715 13.0076 11.8312 12.8246L11.8434 12.8111L11.9325 12.7226Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0839 17.8846C9.62189 17.8846 9.87211 17.7425 10.1015 17.5132C10.372 17.2427 10.4048 16.8791 10.2189 16.973C10.137 17.0143 10.0586 17.051 9.95732 17.009C9.80412 16.9455 9.63889 16.7533 9.63889 16.5536C9.63889 16.2913 9.74258 16.0053 9.85247 15.7025C9.96182 15.401 10.0749 15.0893 10.0749 14.8097C10.0749 14.3909 9.64168 14.1094 8.99682 14.1094C8.27823 14.1094 7.62414 14.625 7.42002 14.8746L7.17338 15.1762L7.27567 14.8003C7.27637 14.7976 7.3473 14.5349 7.3473 14.3496C7.3473 14.2902 7.3473 14.1094 7.00753 14.1094C6.22196 14.1094 5.54885 14.3167 5.54885 14.4864C5.54885 14.5452 5.56266 14.5784 5.58168 14.6242C5.60582 14.6825 5.63593 14.755 5.63593 14.8843C5.63593 15.2613 5.47652 15.9433 5.33589 16.5451C5.22165 17.0338 5.11377 17.4953 5.11377 17.6941C5.11377 17.8561 5.1712 17.8846 5.29181 17.8846C5.36631 17.8846 5.5265 17.874 5.71199 17.8616C5.97462 17.8442 6.30143 17.8225 6.5227 17.8225C6.68118 17.8225 6.71323 17.7925 6.71323 17.6444C6.71323 17.2389 7.21467 15.889 7.75049 15.4003C7.88235 15.2799 8.01017 15.2191 8.13093 15.2191C8.1418 15.2191 8.15266 15.2196 8.16337 15.2206C8.28025 15.2313 8.36353 15.275 8.41071 15.3502C8.51386 15.5145 8.4163 15.7754 8.29259 16.1059C8.18588 16.3913 8.06473 16.7147 8.06473 17.0103C8.06473 17.5414 8.46481 17.8846 9.0839 17.8846Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5447 15.6732C11.7597 15.6829 11.9884 15.5983 12.1662 15.4411C12.3601 15.2697 12.4734 15.0297 12.4853 14.7653C12.4921 14.6124 12.4237 14.4655 12.2926 14.3519C12.1579 14.235 11.9738 14.1659 11.7742 14.157C11.5235 14.147 11.2862 14.2402 11.1089 14.4229C10.9446 14.5922 10.8444 14.8216 10.8341 15.0524C10.8187 15.3985 11.1109 15.6538 11.5447 15.6732Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4381 13.9959C17.4406 13.4299 15.483 14.4809 15.6332 12.7954C15.6427 12.7111 15.6109 12.6683 15.562 12.641C15.4795 12.595 15.3745 12.5998 15.2952 12.6512C15.2421 12.6857 15.1917 12.7374 15.1569 12.8069C14.6144 13.8922 13.7917 13.8841 13.2908 13.9279C13.1901 13.9367 12.8546 13.9284 12.8218 14.1928C12.7928 14.4258 13.4568 14.4258 13.6228 14.4258C14.6162 14.4258 13.3577 15.5721 13.033 16.2937C12.7326 16.9616 12.5582 17.7187 12.9127 18.6138C13.2767 19.5327 14.1754 19.7777 14.6788 19.8419C15.5366 19.9514 16.7952 19.6011 16.7952 19.3521C16.7893 19.1716 16.5708 19.2216 16.4199 19.2184C16.1412 19.2127 15.7596 19.2048 15.4116 19.0656C14.9271 18.8717 14.3359 18.4 14.1294 17.6574C13.9991 17.1888 13.9869 16.441 14.6683 15.5404C15.5526 14.3717 16.3443 14.3359 16.8677 14.3048C17.1272 14.2893 17.4406 14.2599 17.4381 13.9959Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7339 16.6924C16.7339 16.0863 17.1271 15.4688 17.5131 15.4688C17.6975 15.4688 17.8075 15.5814 17.8075 15.7702C17.8075 16.0552 17.5215 16.6421 16.8325 16.774L16.7339 16.7929V16.6924ZM18.9348 17.6962C18.9348 17.6702 18.9104 17.6336 18.8563 17.6336C18.809 17.6336 18.724 17.6916 18.6256 17.7588C18.3881 17.9186 18.0704 18.0948 17.6377 18.0948C17.1358 18.0948 16.7506 17.7507 16.7219 17.2765L16.7166 17.1885H16.8048C17.8697 17.1885 18.7361 16.5941 18.7361 15.8636C18.7361 15.3309 18.2723 15 17.5258 15C16.2642 15 14.9233 15.7535 14.9233 17.1501C14.9233 18.1618 15.6409 18.7419 16.8919 18.7419C17.6931 18.7419 18.3593 18.3689 18.6864 18.0495C18.879 17.8614 18.9348 17.7822 18.9348 17.6962Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5312 9.09528C16.7677 9.07805 16.8969 9.20657 16.9167 9.47751C16.9461 9.87984 16.6733 11.4937 16.1732 11.5304C16.1634 11.5311 16.1536 11.5315 16.1442 11.5315C16.0545 11.5315 15.9766 11.4999 15.9175 11.4392C15.8584 11.3786 15.8234 11.2941 15.8162 11.1945C15.7628 10.4647 16.1028 9.12655 16.5312 9.09528ZM14.2969 11.6422C14.3766 11.6214 14.4629 11.599 14.548 11.599C14.6038 11.599 14.6591 11.6085 14.7121 11.6345C14.7671 11.6616 14.8258 11.701 14.8938 11.7466C15.1359 11.9092 15.5021 12.1547 16.1395 12.1081C17.9041 11.9791 18.5906 10.4536 18.5145 9.41156C18.4452 8.46363 17.7351 8.31555 17.1494 8.35816C16.6578 8.39417 16.2893 8.64236 16.1231 8.84392L15.9409 9.06495L15.9767 8.78074C16.0501 8.19921 16.316 5.50335 16.2731 4.91545C16.2332 4.37031 15.8514 4.08448 15.2267 4.12965C14.5317 4.18048 14.0283 4.48518 14.0408 4.65809C14.0487 4.6775 14.1215 4.72507 14.1697 4.75658C14.237 4.80051 14.3133 4.85033 14.379 4.91553C14.5538 5.08891 14.9644 5.54696 15.0016 6.05624C15.0801 7.13005 14.6588 9.0544 14.5507 9.38005L14.5548 9.38067C14.4827 9.81552 14.2983 10.2026 14.1357 10.5442C13.9159 11.0054 13.7425 11.3696 13.9565 11.6201C14.03 11.7063 14.1336 11.6848 14.2969 11.6422Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6462 19.3721C12.0688 19.4092 12.4334 19.2629 12.5263 19.1714C12.5198 19.1609 12.5122 19.1487 12.5037 19.1352C12.4506 19.05 12.3702 18.921 12.3341 18.8334C12.0039 18.0316 12.0066 17.2228 12.3428 16.2882C12.3563 16.2503 12.3476 16.2 12.3091 16.1811C12.2324 16.1437 12.1085 16.1338 12.009 16.1293C11.9429 16.1265 11.879 16.125 11.8177 16.125C11.1813 16.125 10.8007 16.2767 10.7934 16.4398C10.7911 16.4907 10.8303 16.5579 10.8717 16.629C10.9309 16.7303 10.9978 16.8451 10.9917 16.9822C10.9844 17.1452 10.922 17.3179 10.8558 17.5009C10.7449 17.8076 10.6193 18.1551 10.7096 18.5616C10.8461 19.1764 11.2943 19.3416 11.6462 19.3721Z"
        fill="#FFCFCF"
      />
    </SvgIcon>
  )
}
