import { Stack, IconButton, useTheme, useMediaQuery } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useSession } from 'next-auth/react'
import { CloseIcon } from '../icons/close'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'
import { FabniteLogoCosmosBackgroundIcon } from 'src/assets/icons'
import { ProfileButton } from '../components/profile-button'
import { SignInButton } from '../components/sign-in-button'
import { NavigationButton } from '../components/navigation-button'

type Props = {
  onClose: () => void
}

export const MenuNavigationBar = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const session = useSession()
  const theme = useTheme()
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        py: { xs: '10px', lg: '26px' },
        pl: { xs: 2, sm: 3.5, md: 6, lg: 7 },
        pr: { xs: 1.75, sm: 3.5, md: 6, lg: 3 },
        justifyContent: 'space-between',
      }}
    >
      <NextLink href={pages['/']} onClick={onClose}>
        <FabniteLogoCosmosBackgroundIcon
          sx={{
            fontSize: { xs: 40, sm: 48, lg: 60 },
            display: 'block',
          }}
        />
      </NextLink>
      <Stack direction="row" alignItems="center" spacing={{ xs: 2 }}>
        {session.status === 'authenticated' && (
          <ProfileButton onClick={onClose} />
        )}
        {session.status === 'unauthenticated' && (
          <SignInButton onClick={onClose} />
        )}

        {isSmallDesktop ? (
          <NavigationButton
            onClick={onClose}
            icon={
              <CloseIcon
                sx={{
                  '&&': {
                    fontSize: 24,
                  },
                }}
              />
            }
            label={t('close')}
          />
        ) : (
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon sx={{ fontSize: { xs: 24, sm: 40 } }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}
