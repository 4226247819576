import { boolean, string, type, TypeOf } from 'io-ts'
import { UUID } from 'io-ts-types'
import { enumToKeyof } from 'src/lib/types'

export const TCreateSourceResponse = type({
  clientSecret: string,
  payload: string,
})
export type ICreateSourceResponse = TypeOf<typeof TCreateSourceResponse>

export enum CardBrand {
  visa = 'visa',
  mastercard = 'mastercard',
  amex = 'amex',
}

const TUserSourceDetails = type({
  lastFour: string,
  brand: enumToKeyof(CardBrand),
})

export const TUserSource = type({
  userSourceId: UUID,
  default: boolean,
  details: TUserSourceDetails,
})
export type IUserSource = TypeOf<typeof TUserSource>
