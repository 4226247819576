import { SvgIcon, SvgIconProps } from '@mui/material'

export const CompletedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.1621 9.9602C20.5918 9.40063 20.2021 8.96997 20.002 8.76001C19.9717 8.42016 19.9219 7.70044 19.9219 6.9602C19.9219 5.38013 18.6221 4.08032 17.042 4.08032C16.2422 4.08032 15.6621 4.05981 15.3721 4.05005C15.1016 3.83032 14.5615 3.3606 14.042 2.84009C12.9521 1.76001 11.0518 1.76001 9.96191 2.84009C9.40186 3.4104 8.97217 3.80005 8.76172 4.00024C8.42188 4.03052 7.70215 4.08032 6.96191 4.08032C5.38184 4.08032 4.08203 5.38013 4.08203 6.9602C4.08203 7.76001 4.07178 8.34009 4.05176 8.63013C3.83203 8.90063 3.36182 9.44067 2.8418 9.9602C2.30176 10.5002 2.00195 11.2307 2.00195 12.0002C2.00195 12.7698 2.30176 13.5002 2.8418 14.0403C3.41211 14.5999 3.80176 15.0305 3.99219 15.2405C4.03174 15.5803 4.08203 16.3 4.08203 17.0403C4.08203 18.6204 5.38184 19.9202 6.96191 19.9202C7.76172 19.9202 8.3418 19.9299 8.63184 19.9504C8.90186 20.1702 9.44189 20.6399 9.96191 21.1604C10.502 21.7004 11.2319 22.0002 12.002 22.0002C12.772 22.0002 13.502 21.7004 14.042 21.1604C14.6016 20.5901 15.0322 20.2004 15.2422 20.0002C15.582 19.97 16.3018 19.9202 17.042 19.9202C18.6221 19.9202 19.9219 18.6204 19.9219 17.0403C19.9219 16.2405 19.9424 15.6604 19.9521 15.3704C20.1719 15.0999 20.6416 14.5598 21.1621 14.0403C21.7021 13.5002 22.002 12.7698 22.002 12.0002C22.002 11.2307 21.7021 10.5002 21.1621 9.9602V9.9602ZM16.2119 10.2102L11.2119 15.2102C11.0117 15.4006 10.7617 15.5002 10.502 15.5002C10.2422 15.5002 9.99219 15.4006 9.79199 15.2102L7.79199 13.2102C7.40186 12.8206 7.40186 12.1799 7.79199 11.7903C8.18213 11.4006 8.82178 11.4006 9.21191 11.7903L10.502 13.0901L14.792 8.79028C15.1816 8.40063 15.8223 8.40063 16.2119 8.79028C16.6016 9.17993 16.6016 9.82056 16.2119 10.2102V10.2102Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
