import { array, boolean, intersection, string, type, TypeOf } from 'io-ts'
import { UUID } from 'io-ts-types'
import { TImage } from 'src/api/file'
import { TAvailableLanguages } from 'src/app/locale'

export const TArtistTag = type({
  name: string,
  tagId: UUID,
})
export type IArtistTag = TypeOf<typeof TArtistTag>

export const TArtist = type({
  coverImage: TImage,
  name: string,
  performerId: UUID,
  performerSlug: string,
  countryCode: string,
})
export type IArtist = TypeOf<typeof TArtist>

export const TArtistRow = intersection([
  TArtist,
  type({
    countryCode: string,
    favourite: boolean,
  }),
])
export type IArtistRow = TypeOf<typeof TArtistRow>

export const TArtistDetails = intersection([
  TArtist,
  type({
    country: string,
    countryCode: string,
    tags: array(TArtistTag),
    description: string,
    favourite: boolean,
    excerpt: string,
    availableLanguages: TAvailableLanguages,
  }),
])
export type IArtistDetails = TypeOf<typeof TArtistDetails>
