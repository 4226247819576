import { useJsApiLoader } from '@react-google-maps/api'
import constate from 'constate'
import { env } from 'src/app/env/env'

const googleMapsLibraries: Array<'places'> = ['places']

const useLoadGoogleMaps = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env.googleApiKey,
    libraries: googleMapsLibraries,
  })

  return {
    isGoogleMapsLoaded: isLoaded,
  }
}

export const [GoogleMapsLoadingStatusProvider, useGoogleMapsLoadingStatus] =
  constate(useLoadGoogleMaps)
