export const LikeBlurredBackground = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path
      d="M9.29626 2.29411L9.97304 2.91641L10.6499 2.2942C11.555 1.46218 12.7394 1.00031 13.9688 1C14.6194 1.00056 15.2635 1.12968 15.864 1.37994C16.4647 1.63026 17.01 1.99684 17.4685 2.45861L17.4694 2.45946C19.4382 4.43745 19.4354 7.50054 17.473 9.46289L10.058 16.8779L10.0019 16.934L9.95925 16.9929C9.95915 16.9929 9.95905 16.9928 9.95896 16.9928L9.90071 16.8904L9.80523 16.7949L2.47323 9.46289L2.47308 9.46274C0.510385 7.50088 0.507678 4.43701 2.47273 2.46361L2.47419 2.46214C2.93301 1.99946 3.47881 1.63208 4.08016 1.38114C4.68135 1.13028 5.32621 1.00075 5.97763 1C7.20694 1.00017 8.39135 1.46203 9.29626 2.29411Z"
      fill="black"
      fillOpacity="0.5"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
