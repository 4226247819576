import { Stack, Box, Button, Typography, useTheme } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'
import { ProfileIcon } from '../icons/profile'
import { TicketsIcon } from '../icons/tickets'
import { MenuItemLink } from './menu-item-link'
import { MenuItemLinkWithIcon } from './menu-item-link-with-icon'
import { MenuSearchForm } from './menu-search-form'
import { useSession } from 'next-auth/react'
import { SelectLanguage } from './select-language'
import { MenuElements } from './menu-elements'
import { MenuNavigationBar } from './menu-navigation-bar'
import { DashedLine } from './dashed-line'
import { useRouter } from 'next/router'
import { pages } from 'src/app/pages'
import { BellIcon, LikeIcon } from 'src/assets/icons'
import { useLocale } from 'src/lib/use-locale'
import { getRelativePathWithLocale } from 'src/lib/get-path-with-locale'
import { NextLink } from 'src/components/next-link'

const dashedLineSpacing = { xs: 3, sm: 4, md: 2.5, lg: 3.5 }

type Props = {
  onClose: () => void
}

export const Menu = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const session = useSession()

  const mainMenuLinks = useMemo(
    () => [
      {
        label: t('events'),
        href: pages['/events'],
        isDisabled: false,
      },
      {
        label: t('places'),
        href: pages['/places'],
        isDisabled: false,
      },
      {
        label: t('venues'),
        href: pages['/venues'],
        isDisabled: false,
      },
    ],
    [t],
  )

  const subMenuLinks = useMemo(
    () => [
      {
        label: t('organizers'),
        href: pages['/organizations'],
        isDisabled: false,
      },
      {
        label: t('artists'),
        href: pages['/artists'],
        isDisabled: false,
      },
      {
        label: t('articles'),
        href: pages['/'],
        isDisabled: true,
      },
    ],
    [t],
  )

  const authMenuLinks = useMemo(
    () => [
      {
        label: t('my_profile'),
        icon: <ProfileIcon sx={{ fontSize: { xs: 20, sm: 24, xl: 28 } }} />,
        href: pages['/my-profile'],
        isDisabled: false,
      },
      {
        label: t('my_tickets'),
        icon: <TicketsIcon sx={{ fontSize: { xs: 20, sm: 24, xl: 28 } }} />,
        href: pages['/tickets'],
        isDisabled: false,
      },
      {
        label: t('favorites'),
        icon: <LikeIcon sx={{ fontSize: { xs: 20, sm: 24, xl: 28 } }} />,
        href: pages['/favorites'],
        isDisabled: false,
      },
      {
        label: t('notifications'),
        icon: <BellIcon sx={{ fontSize: { xs: 20, sm: 24, xl: 28 } }} />,
        href: pages['/'],
        isDisabled: true,
      },
    ],
    [t],
  )

  return (
    <>
      <MenuNavigationBar onClose={onClose} />
      <MenuElements />
      <Stack alignItems="center" sx={{ zIndex: 1, pb: 6, overflowY: 'auto' }}>
        <Stack
          spacing={{ xs: 3, sm: 4, md: 6, xl: 7 }}
          alignItems="center"
          sx={{
            width: { xs: '100%', md: '628px', lg: '684px', xl: '764px' },
            pt: { xs: 2, sm: 6, md: 6.5, lg: 0.5, xl: 3 },
            px: { xs: 2, sm: 3.5, md: 0 },
            '@media screen and (max-height: 775px)': {
              pt: { xs: 1, sm: 6, md: 6.5, lg: 0.5, xl: 2.75 },
            },
          }}
        >
          <MenuSearchForm onSubmit={onClose} />
          <Box width="100%">
            <nav>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Stack
                  width={{ xs: '100%', md: 'auto' }}
                  spacing={{ xs: 2.5, sm: 3, lg: 3.5 }}
                >
                  <Stack spacing={{ xs: 1.5, lg: 2 }}>
                    {mainMenuLinks.map((link) => (
                      <MenuItemLink
                        key={link.label}
                        {...link}
                        onClose={onClose}
                        menuItemType="main"
                      />
                    ))}
                  </Stack>
                  <Stack spacing={{ xs: 1.5, lg: 2 }}>
                    {subMenuLinks.map((link) => (
                      <MenuItemLink
                        key={link.label}
                        {...link}
                        onClose={onClose}
                        menuItemType="sub"
                      />
                    ))}
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    width: '100%',
                    my: { xs: 3, sm: 4, lg: 5 },
                    display: { md: 'none' },
                  }}
                >
                  <DashedLine
                    color={theme.palette.accent1.main}
                    opacity="0.6"
                  />
                </Box>
                <Stack
                  sx={{
                    minWidth: {
                      xs: '100%',
                      md: '205px',
                      lg: '225px',
                      xl: '255px',
                    },
                  }}
                >
                  {session.status === 'unauthenticated' ? (
                    <MenuUnauthenticatedLinks />
                  ) : (
                    <Stack spacing={{ xs: 2.25, sm: 2.5, lg: 3, xl: 3.25 }}>
                      {authMenuLinks.map((link) => (
                        <MenuItemLinkWithIcon
                          {...link}
                          onClose={onClose}
                          key={link.label}
                        />
                      ))}
                    </Stack>
                  )}
                  <Box
                    sx={{
                      my: dashedLineSpacing,
                    }}
                  >
                    <DashedLine
                      color={theme.palette.accent1.main}
                      opacity="0.6"
                    />
                  </Box>
                  <Stack spacing={0.5}>
                    <Typography variant="h4">
                      {t('want_to_become_an_organizer')}
                    </Typography>
                    <NextLink href="https://start.fabnite.com" target="_blank">
                      <Typography
                        color="accent1.main"
                        variant="bodyRegular"
                        sx={{ ...linkHoverLayoutProps }}
                      >
                        {t('sell_tickets_with_fabnite')}
                      </Typography>
                    </NextLink>
                  </Stack>
                  <Box
                    sx={{
                      my: dashedLineSpacing,
                    }}
                  >
                    <DashedLine
                      color={theme.palette.accent1.main}
                      opacity="0.6"
                    />
                  </Box>
                  <SelectLanguage />
                </Stack>
              </Stack>
            </nav>
          </Box>
        </Stack>
      </Stack>
    </>
  )
}

export const linkHoverLayoutProps = {
  textDecoration: 'underline',
  textUnderlineOffset: '4px',
  textDecorationColor: 'transparent',
  transition: '0.4s',
  '&:hover': {
    textDecorationColor: 'currentColor',
  },
} as const

const MenuUnauthenticatedLinks = () => {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const router = useRouter()

  return (
    <Stack spacing={{ xs: 2.25, sm: 3 }}>
      <Stack spacing={0.5} alignItems="flex-start">
        <Typography variant="h4">{t('already_have_account')}</Typography>
        <Button
          onClick={() =>
            router.push({
              query: {
                callbackUrl: getRelativePathWithLocale({
                  locale,
                  path: router.asPath,
                }),
              },
              pathname: pages['/sign-in'],
            })
          }
          sx={{ minWidth: 0 }}
          variant="linkStyled"
        >
          <Typography
            color="accent1.main"
            variant="bodyRegular"
            sx={{ ...linkHoverLayoutProps }}
          >
            {t('sign_in')}
          </Typography>
        </Button>
      </Stack>
      <Stack spacing={0.5}>
        <Typography variant="h4">{t('dont_have_account')}</Typography>
        <NextLink href={pages['/sign-in']}>
          <Typography
            color="accent1.main"
            variant="bodyRegular"
            sx={{ ...linkHoverLayoutProps }}
          >
            {t('sign_up')}
          </Typography>
        </NextLink>
      </Stack>
    </Stack>
  )
}
