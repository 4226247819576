import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get } from 'src/lib/request'
import {
  TOrganization,
  TOrganizationsCountries,
} from './public-organization.codecs'
import {
  decodeJsonWithTotal,
  SearchInput,
  InfiniteScrollPaginationInput,
  InfiniteScrollInput,
} from 'src/lib/get-list'

const organizations = 'auth/public/organizations' as const

export type GetOrganizationInput = {
  organizationSlug: string
}

export const getOrganization = async (input: GetOrganizationInput) => {
  return pipe(
    await get(`${organizations}/${input.organizationSlug}`, {}),
    decodeJson(TOrganization),
  )
}

export type GetOrganizationsInput = InfiniteScrollPaginationInput &
  SearchInput & {
    country: string
  } & LocaleInput

export const getOrganizations = async ({
  pageParam = 0,
  input: { country, search, limit, locale },
}: InfiniteScrollInput<GetOrganizationsInput>) => {
  const query = new URLSearchParams({
    limit: limit.toString(),
    skip: String(pageParam * limit),
  })

  if (country) {
    query.set('country', country)
  }

  if (search.trim() !== '') {
    query.set('search', search.trim())
  }

  const { headers } = setAcceptLanguageHeader(locale)

  return pipe(
    await get(organizations, {
      query,
      headers,
    }),
    decodeJsonWithTotal(TOrganization),
  )
}

export const getOrganizationsCountries = async () => {
  return pipe(
    await get(`${organizations}/countries`, {}),
    decodeJson(TOrganizationsCountries),
  )
}
