import { buildModuleCacheKey } from 'src/lib/react-query-key'
import {
  GetConfirmationInput,
  GetOrganizationInput,
  GetOrganizationsInput,
} from './auth'
import {
  getArtistEventsInput,
  GetArtistInput,
  GetArtistRecommendationsInput,
  GetEventArtistsInput,
  GetEventInput,
  GetEventsNearbyInput,
  GetEventsInput,
  GetExploreBannerInput,
  GetExplorePlacesInput,
  GetFavoriteArtistsInput,
  GetFavoriteEventsInput,
  GetMediaInput,
  GetPlacesCountriesInput,
  GetPlacesListInput,
  GetPlacesSelectListInput,
  GetEventListByOrganizationInput,
  GetPlaceEventsInput,
  GetPlaceInput,
  GetPlaceMonthsInput,
  GetUserTicketsEventsInput,
  GetVenueInput,
  GetVenuesInput,
  GetVenuesCountriesInput,
  GetArtistsTagsInput,
  GetArtistsListInput,
  GetOrganizationMonthsInput,
  GetVenueMonthsInput,
  GetEventListByVenueInput,
  GetEventSeatMapInput,
  GetEventSeatMapLiveInput,
} from './cms'
import { GetFeaturedArticlesInput, GetPublicArticleInput } from './content'
import { GetBucketInput, GetPurchasedTicketsInput } from './purchases'
import {
  GetMinimumTicketPriceInput,
  CheckPossibleTicketsNotificationInput,
  GetPublicTicketsInput,
} from './trade'
import { GetSeatMapFileInput } from './file'

/**
 * `qk` stands for "query keys"
 */
export const qk = buildModuleCacheKey({
  auth: {
    user: {
      profile: null,
      contacts: null,
      signInMethods: null,
    },
    confirmation: {
      validateRequest: (input: GetConfirmationInput) => [input],
    },
    userClc: {
      memberships: null,
    },
    public: {
      organization: {
        details: (input: GetOrganizationInput) => [input],
        list: (input: GetOrganizationsInput) => [input],
        countries: null,
      },
    },
  },
  cms: {
    event: {
      seatMap: {
        details: (input: GetEventSeatMapInput) => [input],
        file: (input: GetSeatMapFileInput) => [input],
        live: (input: GetEventSeatMapLiveInput) => [input],
      },
      userTicketsEvents: (input: GetUserTicketsEventsInput) => [input],
    },
    public: {
      explore: {
        banner: (input: GetExploreBannerInput) => [input],
        places: (input: GetExplorePlacesInput) => [input],
        events: {
          nearby: (input: GetEventsNearbyInput) => [input],
          recommended: null,
        },
      },
      artist: {
        details: (input: GetArtistInput) => [input],
        events: (input: getArtistEventsInput) => [input],
        recommendations: (input: GetArtistRecommendationsInput) => [input],
        countries: null,
        tags: (input: GetArtistsTagsInput) => [input],
        list: (input: GetArtistsListInput) => [input],
      },
      event: {
        list: (input: GetEventsInput) => [input],
        details: (input: GetEventInput) => [input],
        artists: (input: GetEventArtistsInput) => [input],
        listByOrganization: (input: GetEventListByOrganizationInput) => [input],
        organizationMonths: (input: GetOrganizationMonthsInput) => [input],
      },
      place: {
        list: (input: GetPlacesListInput) => [input],
        details: (input: GetPlaceInput) => [input],
        months: (input: GetPlaceMonthsInput) => [input],
        events: (input: GetPlaceEventsInput) => [input],
        selectList: (input: GetPlacesSelectListInput) => [input],
        countries: (input: GetPlacesCountriesInput) => [input],
      },
      venue: {
        list: (input: GetVenuesInput) => [input],
        details: (input: GetVenueInput) => [input],
        events: (input: GetEventListByVenueInput) => [input],
        countries: (input: GetVenuesCountriesInput) => [input],
        months: (input: GetVenueMonthsInput) => [input],
      },
      media: (input: GetMediaInput) => [input],
    },
    favorite: {
      category: null,
      artists: (input: GetFavoriteArtistsInput) => [input],
      events: (input: GetFavoriteEventsInput) => [input],
    },
  },
  content: {
    public: {
      article: {
        details: (input: GetPublicArticleInput) => [input],
      },
      explore: {
        article: {
          featured: (input: GetFeaturedArticlesInput) => [input],
          popular: null,
        },
      },
    },
  },
  trade: {
    ticket: {
      notifyMe: (input: CheckPossibleTicketsNotificationInput) => [input],
      publicTickets: (input: GetPublicTicketsInput) => [input],
    },
    public: {
      event: {
        ticketMinPrice: (input: GetMinimumTicketPriceInput) => [input],
      },
    },
  },
  finance: {
    source: {
      list: null,
    },
  },
  purchase: {
    user: {
      purchasedTickets: (input: GetPurchasedTicketsInput) => [input],
    },
    bucket: {
      details: {
        distributed: (input: GetBucketInput) => [input],
        combined: (input: Partial<GetBucketInput>) => [input],
      },
    },
  },
})
