import { SvgIcon, SvgIconProps } from '@mui/material'

export const DeerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 88 88">
      <path
        d="M6.87507 6.30561V2.75003H4.12507V6.87503C4.12506 7.0556 4.16062 7.2344 4.22972 7.40123C4.29881 7.56806 4.40009 7.71964 4.52777 7.84733L7.68047 11H4.69449L2.34736 8.65273L0.402771 10.5973L3.15277 13.3473C3.28046 13.475 3.43204 13.5763 3.59887 13.6454C3.76569 13.7145 3.9445 13.75 4.12507 13.75H10.4305L12.3751 15.6945V23.375H15.1251V15.125C15.1251 14.9445 15.0895 14.7657 15.0204 14.5988C14.9513 14.432 14.85 14.2804 14.7224 14.1527L6.87507 6.30561Z"
        fill="#774B01"
      />
      <path
        d="M30.6525 8.65273L28.3054 11H25.3194L28.4721 7.84733C28.5998 7.71964 28.7011 7.56806 28.7702 7.40123C28.8393 7.2344 28.8748 7.0556 28.8748 6.87503V2.75003H26.1248V6.30561L18.2775 14.1527C18.1498 14.2804 18.0486 14.432 17.9795 14.5988C17.9104 14.7657 17.8748 14.9445 17.8748 15.125V23.375H20.6248V15.6945L22.5694 13.75H28.8748C29.0554 13.75 29.2342 13.7145 29.401 13.6454C29.5678 13.5763 29.7194 13.475 29.8471 13.3473L32.5971 10.5973L30.6525 8.65273Z"
        fill="#774B01"
      />
      <path
        d="M50.8749 57.75V70.125L54.9999 74.25L53.6249 83.875H57.7499L60.4999 72.875V71.5L57.7499 68.75V57.75H50.8749Z"
        fill="#C37C02"
      />
      <path
        d="M37.1248 59.125V61.875L38.4998 83.875H42.6248V61.875V59.125H37.1248Z"
        fill="#C37C02"
      />
      <path
        d="M72.8749 45.375C72.8749 46.8337 73.4543 48.2327 74.4858 49.2641C75.5172 50.2956 76.9162 50.875 78.3749 50.875H79.7499V52.9375C79.7499 53.3889 79.661 53.8359 79.4882 54.253C79.3155 54.6701 79.0623 55.049 78.7431 55.3682C78.4239 55.6874 78.0449 55.9406 77.6279 56.1134C77.2108 56.2861 76.7638 56.375 76.3124 56.375C75.861 56.375 75.414 56.2861 74.9969 56.1134C74.5798 55.9406 74.2009 55.6874 73.8817 55.3682C73.5625 55.049 73.3093 54.6701 73.1365 54.253C72.9638 53.8359 72.8749 53.3889 72.8749 52.9375V45.375Z"
        fill="#FBD79A"
      />
      <path
        d="M24.7498 31.625V35.75C24.7498 36.844 25.1844 37.8933 25.958 38.6668C26.7316 39.4404 27.7808 39.875 28.8748 39.875H64.6248C67.1775 39.875 69.6257 40.8891 71.4307 42.6941C73.2358 44.4992 74.2498 46.9473 74.2498 49.5V52.25C74.2498 54.8027 73.2358 57.2509 71.4307 59.0559C69.6257 60.861 67.1775 61.875 64.6248 61.875V67.375L70.1248 72.875L67.3748 83.875H63.2498L64.6248 75.625L57.7498 68.75V61.875H32.9998V83.875H28.8748L26.1248 61.875C23.5721 61.875 21.124 60.861 19.3189 59.0559C17.5139 57.2509 16.4998 54.8027 16.4998 52.25V31.625H24.7498Z"
        fill="#F69C03"
      />
      <path
        d="M14.9243 39.875H18.0755C18.8415 39.875 19.5924 39.6617 20.2441 39.2589C20.8957 38.8562 21.4223 38.2799 21.7649 37.5948L24.7499 31.625V23.375C24.7499 22.6457 24.4601 21.9462 23.9444 21.4305C23.4287 20.9148 22.7292 20.625 21.9999 20.625H10.9999C10.2705 20.625 9.57106 20.9148 9.05533 21.4305C8.53961 21.9462 8.24988 22.6457 8.24988 23.375V31.625L11.2348 37.5948C11.5774 38.2799 12.104 38.8562 12.7557 39.2589C13.4073 39.6617 14.1582 39.875 14.9243 39.875Z"
        fill="#F8B035"
      />
      <path
        d="M8.24994 23.375H5.49994C4.04125 23.375 2.6423 22.7955 1.61085 21.7641C0.579401 20.7326 -6.10352e-05 19.3337 -6.10352e-05 17.875H2.74994C4.20863 17.875 5.60758 18.4545 6.63903 19.4859C7.67048 20.5174 8.24994 21.9163 8.24994 23.375Z"
        fill="#F8B035"
      />
      <path
        d="M24.7499 23.375H27.4999C28.9586 23.375 30.3576 22.7955 31.389 21.7641C32.4205 20.7326 32.9999 19.3337 32.9999 17.875H30.2499C28.7912 17.875 27.3923 18.4545 26.3609 19.4859C25.3294 20.5174 24.7499 21.9163 24.7499 23.375Z"
        fill="#F8B035"
      />
      <path
        d="M15.1249 26.125C15.1249 25.3656 14.5093 24.75 13.7499 24.75C12.9905 24.75 12.3749 25.3656 12.3749 26.125C12.3749 26.8844 12.9905 27.5 13.7499 27.5C14.5093 27.5 15.1249 26.8844 15.1249 26.125Z"
        fill="#774B01"
      />
      <path
        d="M20.6248 26.125C20.6248 25.3656 20.0092 24.75 19.2498 24.75C18.4904 24.75 17.8748 25.3656 17.8748 26.125C17.8748 26.8844 18.4904 27.5 19.2498 27.5C20.0092 27.5 20.6248 26.8844 20.6248 26.125Z"
        fill="#774B01"
      />
      <path
        d="M12.3749 37.125C12.3749 36.031 12.8095 34.9818 13.5831 34.2082C14.3567 33.4346 15.4059 33 16.4999 33C17.594 33 18.6432 33.4346 19.4168 34.2082C20.1903 34.9818 20.6249 36.031 20.6249 37.125C20.6249 37.8544 20.3352 38.5538 19.8195 39.0696C19.3038 39.5853 18.6043 39.875 17.8749 39.875H15.1249C14.3956 39.875 13.6961 39.5853 13.1804 39.0696C12.6647 38.5538 12.3749 37.8544 12.3749 37.125Z"
        fill="#774B01"
      />
      <path d="M28.8749 44H31.6249V46.75H28.8749V44Z" fill="#FBD79A" />
      <path
        d="M35.7499 46.7501H38.4999V49.5001H35.7499V46.7501Z"
        fill="#FBD79A"
      />
      <path d="M42.6249 42.625H45.3749V45.375H42.6249V42.625Z" fill="#FBD79A" />
      <path d="M49.4999 45.375H52.2499V48.125H49.4999V45.375Z" fill="#FBD79A" />
      <path d="M56.375 42.625H59.125V45.375H56.375V42.625Z" fill="#FBD79A" />
      <path d="M63.25 45.375H66V48.125H63.25V45.375Z" fill="#FBD79A" />
      <path d="M59.1249 50.875H61.8749V53.625H59.1249V50.875Z" fill="#FBD79A" />
    </SvgIcon>
  )
}
