import { SvgIcon, SvgIconProps } from '@mui/material'

export const CrossIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74287 6.257C5.39149 6.60838 5.39149 7.17808 5.74287 7.52946L10.7432 12.5298L6.27274 17.0003C5.92136 17.3517 5.92136 17.9214 6.27274 18.2727C6.62412 18.6241 7.19382 18.6241 7.5452 18.2727L12.0157 13.8023L16.4862 18.2728C16.8376 18.6242 17.4073 18.6242 17.7587 18.2728C18.11 17.9214 18.11 17.3517 17.7587 17.0003L13.2881 12.5298L18.2885 7.52941C18.6399 7.17803 18.6399 6.60833 18.2885 6.25695C17.9372 5.90557 17.3675 5.90557 17.0161 6.25695L12.0157 11.2573L7.01533 6.257C6.66395 5.90562 6.09425 5.90562 5.74287 6.257Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
