import { ElementType } from 'react'
import { UrlObject } from 'node:url'
import Link from 'next/link'
import { Link as MuiLink, LinkProps } from '@mui/material'

type Props<D extends ElementType, P> = Omit<LinkProps<D, P>, 'href'> & {
  href: string | UrlObject
}

export const NextLink = <D extends ElementType, P>({
  href,
  children,
  variant,
  color,
  underline,
  sx,
  ...props
}: Props<D, P>) => {
  return (
    <Link
      href={href}
      passHref
      legacyBehavior
      style={{ textDecoration: 'none' }}
    >
      <MuiLink
        underline={underline ?? 'hover'}
        variant={variant ?? 'smallTextSemiBold'}
        color={color ?? 'strongBlue.main'}
        sx={{
          textUnderlineOffset: '4px',
          textDecorationColor: 'currentcolor',
          ...sx,
        }}
        {...props}
      >
        {children}
      </MuiLink>
    </Link>
  )
}
