import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const TicketsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <rect
      x="3"
      y="5"
      width="18"
      height="14.0011"
      rx="1"
      stroke="white"
      strokeWidth="2"
      fill="none"
    />
    <rect x="6.04614" y="8.05469" width="2" height="8" fill="white" />
    <rect x="9.04419" y="8.05469" width="1" height="8" fill="white" />
    <rect x="11.0422" y="8.05469" width="1" height="8" fill="white" />
    <rect x="13.0403" y="8.05469" width="3" height="8" fill="white" />
    <rect x="17.0383" y="8.05469" width="1" height="8" fill="white" />
  </SvgIcon>
)
