import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get } from 'src/lib/request'
import { TEventSeatMap, TSeatMapItem } from './event-seat-map.codecs'
import { UUID } from 'io-ts-types'
import { array } from 'io-ts'

const events = 'cms/events'

export type GetEventSeatMapInput = {
  eventSlug: string
}

export const getEventSeatMap = async (input: GetEventSeatMapInput) => {
  return pipe(
    await get(`${events}/${input.eventSlug}/live/maps`, {}),
    decodeJson(TEventSeatMap),
  )
}

export type GetEventSeatMapLiveInput = {
  eventId: UUID
}

export const getEventSeatMapLive = async (input: GetEventSeatMapLiveInput) => {
  return pipe(
    await get(`${events}/:eventId/live/maps/seats`, {
      params: { eventId: input.eventId },
    }),
    decodeJson(array(TSeatMapItem)),
  )
}
