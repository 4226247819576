import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post } from 'src/lib/request'
import { TCreateSourceResponse, TUserSource } from './source.codecs'

const sources = 'finance/me/sources'
const source = `${sources}/:sourceId`

export const createUserSource = async () => {
  return pipe(await post(sources, {}), decodeJson(TCreateSourceResponse))
}

type ChallengeUserSourceInput = {
  payload: string
  paymentMethodId: string
}

export const challengeUserSource = async (input: ChallengeUserSourceInput) => {
  return await post(`${sources}/challenge`, {
    body: {
      payload: input.payload,
      paymentMethodId: input.paymentMethodId,
    },
  })
}

export const getUserSources = async () => {
  return pipe(await get(sources, {}), decodeJson(array(TUserSource)))
}

type DeleteUserSourceInput = {
  sourceId: UUID
}

export const deleteUserSource = (input: DeleteUserSourceInput) => {
  return del(source, {
    params: {
      sourceId: input.sourceId,
    },
  })
}

type ChangeDefaultUserSourceInput = {
  sourceId: UUID
}

export const changeDefaultUserSource = (
  input: ChangeDefaultUserSourceInput,
) => {
  return patch(source, {
    params: {
      sourceId: input.sourceId,
    },
    body: {
      default: true,
    },
  })
}
