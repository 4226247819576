export const PlayIcon = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none">
      <g filter="url(#filter0_bd_1847_35835)">
        <circle
          cx="35"
          cy="31"
          r="19"
          fill="white"
          fillOpacity="0.2"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M31.9782 38.9272C31.2254 38.9272 30.507 38.739 29.874 38.3798C28.4027 37.5244 27.5815 35.8478 27.5815 33.658V27.927C27.5815 25.7372 28.3856 24.0606 29.8569 23.2052C31.3281 22.3499 33.1928 22.4867 35.0918 23.5816L40.053 26.4386C41.9519 27.5335 42.9955 29.0732 42.9955 30.7839C42.9955 32.4776 41.9519 34.0344 40.053 35.1293L35.0918 37.9863C34.0311 38.6193 32.9533 38.9272 31.9782 38.9272Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_bd_1847_35835"
          x="0"
          y="0"
          width="70"
          height="70"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="3" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1847_35835"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_1847_35835"
            result="effect2_dropShadow_1847_35835"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1847_35835"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
