import { TypeOf, intersection, record, string, type } from 'io-ts'
import { UUID } from 'io-ts-types'
import { TOptionalString, enumToKeyof } from 'src/lib/types'

export const TUploadFileResponse = type({
  fileKey: UUID,
})

enum FileType {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  SEAT_MAP = 'SEAT_MAP',
}

// codecs for dates is string because Next.js accept only json as props
export const TFile = type({
  fileKey: UUID,
  url: string,
  type: enumToKeyof(FileType),
  originalFileName: TOptionalString,
})
export type IFile = TypeOf<typeof TFile>

export enum ImageSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export const TImageResizedUrls = record(enumToKeyof(ImageSize), string)
export type IImageResizedUrls = TypeOf<typeof TImageResizedUrls>

export const TImage = intersection([
  TFile,
  type({
    resizedUrls: TImageResizedUrls,
  }),
])

export const TImageWithDescription = type({
  description: string,
  file: TImage,
})
export type IImageWithDescription = TypeOf<typeof TImageWithDescription>
