import { array, literal, string, type, TypeOf, union } from 'io-ts'
import { nonEmptyArray } from 'io-ts-types'
import { TEvent, TArtist } from '../../cms'
import { TAvailableLanguages } from 'src/app/locale'
import { TFile, TImage, TImageWithDescription } from 'src/api/file'

export enum ContentBlockName {
  heading = 'HEADING',
  events = 'EVENTS',
  photos = 'PHOTOS',
  video = 'VIDEO',
  performers = 'PERFORMERS',
  richText = 'RICH_TEXT',
  quotation = 'QUOTATION',
  list = 'LIST',
}

const TArticleAuthor = type({
  id: string,
  fullName: string,
  description: string,
  image: TFile,
})

const TArticleCategory = type({
  id: string,
  name: string,
})

const TArticleAlignmentType = union([
  literal('LEFT'),
  literal('CENTER'),
  literal('RIGHT'),
])

const TArticleHeadingType = union([literal('H3'), literal('H4')])

const TArticleListType = union([literal('BULLET'), literal('NUMBERED')])
export type IArticleListType = TypeOf<typeof TArticleListType>

const THeadingBlockPublic = type({
  blockType: literal('HEADING'),
  alignmentType: TArticleAlignmentType,
  headingType: TArticleHeadingType,
  heading: string,
})

const TRichTextBlockPublic = type({
  blockType: literal('RICH_TEXT'),
  richText: string,
})

const TListBlockPublic = type({
  blockType: literal('LIST'),
  listType: TArticleListType,
  listLines: array(string),
})

const TQuotationBlockPublic = type({
  blockType: literal('QUOTATION'),
  author: string,
  quotation: string,
})

const TArtistsBlockPublic = type({
  blockType: literal('PERFORMERS'),
  performers: array(TArtist),
})

const TEventsBlockPublic = type({
  blockType: literal('EVENTS'),
  events: array(TEvent),
})

const TVideoBlock = type({
  blockType: literal('VIDEO'),
  file: TFile,
})

const TPhotosBlock = type({
  blockType: literal('PHOTOS'),
  photos: array(TImageWithDescription),
})

const TPublicOptionalBlocks = union([
  THeadingBlockPublic,
  TRichTextBlockPublic,
  TListBlockPublic,
  TQuotationBlockPublic,
  TVideoBlock,
  TPhotosBlock,
  TArtistsBlockPublic,
  TEventsBlockPublic,
])

export const TPublicArticleDetails = type({
  id: string,
  excerptText: string,
  title: string,
  image: TImage,
  author: TArticleAuthor,
  categories: array(TArticleCategory),
  blocks: array(TPublicOptionalBlocks),
  updatedAt: string,
  publishedAt: string,
  articleSlug: string,
  availableLanguages: TAvailableLanguages,
})

export type IPublicArticleDetails = TypeOf<typeof TPublicArticleDetails>
export type TPublicOptionalBlocks = TypeOf<typeof TPublicOptionalBlocks>

export const TPublicArticlePreview = type({
  title: string,
  categories: nonEmptyArray(TArticleCategory),
  image: TImage,
  id: string,
  articleSlug: string,
})
export type IPublicArticlePreview = TypeOf<typeof TPublicArticlePreview>
