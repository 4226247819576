import { Button, ButtonProps, Typography } from '@mui/material'
import {
  OverridableComponent,
  OverridableTypeMap,
} from '@mui/material/OverridableComponent'

type Props = ButtonProps & {
  label: string
  component?: OverridableComponent<OverridableTypeMap>
}

export const ExploreButton = ({ label, sx, ...props }: Props) => {
  return (
    <Button
      variant="secondary"
      {...props}
      sx={{
        borderRadius: '40px',
        border: 'none',
        py: { xs: 1, sm: 1.25 },
        px: { xs: 3, sm: 4 },
        ...sx,
      }}
    >
      <Typography
        variant="mediumButton"
        component="div"
        sx={{
          background:
            'linear-gradient(75.47deg, #00247A 7.34%, #3A3F9B 29.65%, #9D6CD3 58.28%, #B175DE 69.97%, #BA73CE 76.53%, #C66FB9 83.33%, #CE6DAB 89.94%, #DF688D 97.69%, #FC605B 105.92%)',
          WebkitTextFillColor: 'transparent',
          WebkitBackgroundClip: 'text',
        }}
      >
        {label}
      </Typography>
    </Button>
  )
}
