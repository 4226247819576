export const AppStoreEnIcon = () => {
  return (
    <svg width="135" height="40" viewBox="0 0 135 40" fill="none">
      <g clipPath="url(#clip0_12347_514)">
        <path
          d="M124.249 -0.000724492H10.7566C10.3429 -0.000724492 9.9342 -0.000724492 9.5216 0.00127551C9.17621 0.00327551 8.83358 0.00908551 8.48488 0.0139755C7.72733 0.0218773 6.97157 0.0809518 6.22416 0.190686C5.4778 0.302808 4.75482 0.514191 4.07967 0.817685C3.40534 1.12375 2.7892 1.52144 2.25358 1.99635C1.71514 2.46991 1.26632 3.01729 0.924356 3.61744C0.581453 4.21638 0.343683 4.85821 0.219258 5.52076C0.0936495 6.18246 0.0260586 6.8518 0.0170916 7.52276C0.00662229 7.82936 0.00551669 8.13698 0 8.44361V31.5579C0.00551669 31.8684 0.00662229 32.1692 0.0170916 32.4798C0.0260614 33.1507 0.0936522 33.82 0.219258 34.4817C0.343339 35.1446 0.581124 35.7868 0.924356 36.386C1.26616 36.9842 1.71505 37.5293 2.25358 38.0003C2.78717 38.4773 3.40371 38.8753 4.07967 39.179C4.75481 39.4833 5.47772 39.6959 6.22416 39.8098C6.9717 39.9186 7.72738 39.9777 8.48488 39.9866C8.83358 39.9934 9.17621 39.9973 9.5216 39.9973C9.93419 39.9993 10.343 39.9993 10.7566 39.9993H124.249C124.655 39.9993 125.067 39.9993 125.472 39.9973C125.816 39.9973 126.169 39.9934 126.512 39.9866C127.268 39.9782 128.023 39.9191 128.769 39.8098C129.518 39.6951 130.243 39.4825 130.921 39.179C131.597 38.8751 132.213 38.4771 132.746 38.0003C133.283 37.5275 133.733 36.9827 134.079 36.386C134.42 35.7864 134.655 35.1442 134.777 34.4817C134.903 33.8199 134.973 33.1508 134.987 32.4798C134.991 32.1692 134.991 31.8684 134.991 31.5579C135 31.1946 135 30.8333 135 30.4641V9.53541C135 9.1692 135 8.80591 134.991 8.44361C134.991 8.13698 134.991 7.82936 134.987 7.52272C134.973 6.8517 134.903 6.18251 134.777 5.52072C134.655 4.85856 134.419 4.21678 134.079 3.6174C133.383 2.41447 132.278 1.4353 130.921 0.817595C130.243 0.514843 129.518 0.303516 128.769 0.190596C128.023 0.0803783 127.269 0.0212833 126.512 0.0138355C126.169 0.00895551 125.816 0.00309551 125.472 0.00114551C125.067 -0.000854492 124.655 -0.000724492 124.249 -0.000724492Z"
          fill="#A6A6A6"
        />
        <path
          d="M9.52707 39.1241C9.18335 39.1241 8.84792 39.1202 8.50689 39.1134C7.80042 39.1053 7.0956 39.0508 6.39821 38.9503C5.74792 38.8511 5.11798 38.6664 4.52914 38.4024C3.94571 38.1407 3.41356 37.7974 2.9531 37.3858C2.48598 36.9791 2.09708 36.5074 1.80181 35.9893C1.50328 35.4679 1.29669 34.909 1.18922 34.3321C1.07316 33.7123 1.01036 33.0855 1.00138 32.4571C0.994227 32.2462 0.984863 31.544 0.984863 31.544V8.44349C0.984863 8.44349 0.994836 7.75208 1.00144 7.54895C1.01004 6.92163 1.07247 6.2958 1.18818 5.67688C1.29585 5.0984 1.50261 4.53789 1.80129 4.01478C2.09547 3.49709 2.48222 3.02501 2.94653 2.61683C3.41032 2.20477 3.94416 1.85975 4.52859 1.59436C5.11607 1.33124 5.74483 1.14788 6.3938 1.05042C7.09348 0.948982 7.80075 0.894142 8.50967 0.886356L9.52763 0.874146H125.467L126.497 0.886846C127.199 0.894245 127.9 0.948596 128.593 1.04944C129.249 1.14812 129.884 1.33276 130.479 1.59729C131.649 2.13213 132.602 2.97831 133.203 4.01722C133.497 4.53672 133.701 5.09265 133.807 5.66614C133.924 6.29014 133.989 6.92088 134.003 7.55335C134.006 7.83655 134.006 8.14075 134.006 8.44349C134.015 8.81849 134.015 9.17542 134.015 9.53528V30.4639C134.015 30.8272 134.015 31.1817 134.006 31.5391C134.006 31.8643 134.006 32.1622 134.002 32.4688C133.989 33.09 133.924 33.7095 133.809 34.3223C133.704 34.9034 133.498 35.4667 133.2 35.9923C132.902 36.5048 132.516 36.9724 132.054 37.378C131.593 37.7918 131.06 38.1371 130.475 38.4005C129.883 38.6665 129.248 38.8519 128.593 38.9503C127.896 39.0513 127.191 39.1058 126.485 39.1134C126.154 39.1202 125.808 39.1241 125.472 39.1241L124.249 39.1261L9.52707 39.1241Z"
          fill="black"
        />
        <path
          d="M24.769 20.2997C24.7797 19.4651 25.0014 18.6467 25.4135 17.9207C25.8255 17.1948 26.4145 16.5848 27.1256 16.1477C26.6738 15.5025 26.0779 14.9716 25.385 14.597C24.6922 14.2225 23.9216 14.0146 23.1344 13.99C21.4552 13.8137 19.8272 14.9948 18.9715 14.9948C18.0992 14.9948 16.7817 14.0075 15.363 14.0367C14.4453 14.0663 13.551 14.3332 12.7671 14.8112C11.9832 15.2893 11.3365 15.9622 10.8901 16.7645C8.95607 20.113 10.3987 25.034 12.2513 27.7406C13.1782 29.066 14.2615 30.5464 15.6789 30.4939C17.066 30.4364 17.584 29.6094 19.2583 29.6094C20.9171 29.6094 21.4031 30.4939 22.8493 30.4605C24.3377 30.4364 25.2754 29.1293 26.1698 27.7914C26.8358 26.847 27.3483 25.8033 27.6882 24.6989C26.8235 24.3332 26.0856 23.721 25.5665 22.9387C25.0474 22.1564 24.77 21.2386 24.769 20.2997Z"
          fill="white"
        />
        <path
          d="M22.0376 12.21C22.8491 11.2358 23.2489 9.98359 23.1521 8.71936C21.9123 8.84958 20.767 9.44214 19.9445 10.379C19.5424 10.8366 19.2344 11.3691 19.0381 11.9458C18.8419 12.5226 18.7612 13.1324 18.8008 13.7404C19.4209 13.7467 20.0344 13.6123 20.5951 13.3472C21.1558 13.0822 21.649 12.6933 22.0376 12.21Z"
          fill="white"
        />
        <path
          d="M42.3022 27.1387H37.5688L36.4321 30.4951H34.4272L38.9106 18.0771H40.9936L45.477 30.4951H43.438L42.3022 27.1387ZM38.0591 25.5899H41.8111L39.9615 20.1426H39.9097L38.0591 25.5899Z"
          fill="white"
        />
        <path
          d="M55.1597 25.9688C55.1597 28.7823 53.6538 30.5899 51.3814 30.5899C50.8057 30.62 50.2332 30.4874 49.7294 30.2073C49.2256 29.9272 48.8109 29.5108 48.5327 29.0059H48.4897V33.4903H46.6313V21.4415H48.4302V22.9474H48.4644C48.7553 22.4449 49.1771 22.0307 49.6847 21.7489C50.1923 21.467 50.7669 21.328 51.3472 21.3468C53.645 21.3468 55.1597 23.1632 55.1597 25.9688ZM53.2495 25.9688C53.2495 24.1358 52.3023 22.9308 50.857 22.9308C49.437 22.9308 48.482 24.1612 48.482 25.9688C48.482 27.7931 49.437 29.0147 50.857 29.0147C52.3023 29.0147 53.2495 27.8185 53.2495 25.9688Z"
          fill="white"
        />
        <path
          d="M65.1245 25.9688C65.1245 28.7823 63.6187 30.5899 61.3462 30.5899C60.7706 30.62 60.1981 30.4874 59.6943 30.2073C59.1905 29.9272 58.7758 29.5108 58.4976 29.0059H58.4546V33.4903H56.5962V21.4415H58.395V22.9473H58.4292C58.7201 22.4449 59.1419 22.0307 59.6495 21.7488C60.1571 21.467 60.7317 21.328 61.312 21.3468C63.6099 21.3468 65.1245 23.1632 65.1245 25.9688ZM63.2144 25.9688C63.2144 24.1358 62.2671 22.9307 60.8218 22.9307C59.4019 22.9307 58.4468 24.1612 58.4468 25.9688C58.4468 27.7931 59.4019 29.0147 60.8218 29.0147C62.2671 29.0147 63.2144 27.8184 63.2144 25.9688Z"
          fill="white"
        />
        <path
          d="M71.7105 27.0352C71.8482 28.2667 73.0445 29.0752 74.6792 29.0752C76.2456 29.0752 77.3726 28.2666 77.3726 27.1563C77.3726 26.1924 76.6929 25.6153 75.0835 25.2197L73.4742 24.832C71.1939 24.2813 70.1353 23.2148 70.1353 21.4844C70.1353 19.3418 72.0025 17.8701 74.6538 17.8701C77.2778 17.8701 79.0767 19.3418 79.1372 21.4844H77.2612C77.1489 20.2451 76.1245 19.4971 74.6274 19.4971C73.1304 19.4971 72.106 20.2539 72.106 21.3555C72.106 22.2334 72.7603 22.75 74.3608 23.1455L75.729 23.4814C78.2769 24.084 79.3355 25.1074 79.3355 26.9238C79.3355 29.247 77.4849 30.7021 74.5415 30.7021C71.7876 30.7021 69.9282 29.2812 69.8081 27.0351L71.7105 27.0352Z"
          fill="white"
        />
        <path
          d="M83.3462 19.2988V21.4414H85.0679V22.9131H83.3462V27.9043C83.3462 28.6797 83.6909 29.041 84.4477 29.041C84.6521 29.0375 84.8562 29.0231 85.0591 28.998V30.4609C84.7188 30.5245 84.3729 30.5533 84.0268 30.5468C82.1938 30.5468 81.479 29.8584 81.479 28.1025V22.9131H80.1626V21.4414H81.479V19.2988H83.3462Z"
          fill="white"
        />
        <path
          d="M86.0649 25.9687C86.0649 23.1201 87.7427 21.3301 90.3589 21.3301C92.9839 21.3301 94.6538 23.1201 94.6538 25.9687C94.6538 28.8252 92.9927 30.6074 90.3589 30.6074C87.726 30.6074 86.0649 28.8252 86.0649 25.9687ZM92.7602 25.9687C92.7602 24.0146 91.8647 22.8613 90.3589 22.8613C88.853 22.8613 87.9585 24.0234 87.9585 25.9687C87.9585 27.9307 88.853 29.0752 90.3589 29.0752C91.8647 29.0752 92.7602 27.9307 92.7602 25.9687Z"
          fill="white"
        />
        <path
          d="M96.186 21.4414H97.9585V22.9824H98.0015C98.1214 22.5011 98.4034 22.0758 98.8 21.7779C99.1966 21.48 99.6836 21.3277 100.179 21.3467C100.393 21.3459 100.607 21.3692 100.816 21.416V23.1543C100.546 23.0717 100.264 23.0337 99.981 23.042C99.711 23.031 99.4418 23.0786 99.192 23.1815C98.9421 23.2844 98.7175 23.4401 98.5335 23.638C98.3496 23.8359 98.2106 24.0713 98.1262 24.328C98.0417 24.5846 98.0139 24.8565 98.0444 25.125V30.4951H96.186L96.186 21.4414Z"
          fill="white"
        />
        <path
          d="M109.384 27.8359C109.134 29.4795 107.534 30.6074 105.486 30.6074C102.852 30.6074 101.217 28.8428 101.217 26.0117C101.217 23.1719 102.861 21.3301 105.408 21.3301C107.913 21.3301 109.488 23.0508 109.488 25.7959V26.4326H103.093V26.5449C103.064 26.8781 103.105 27.2138 103.216 27.5296C103.326 27.8455 103.502 28.1342 103.732 28.3768C103.963 28.6193 104.242 28.8101 104.552 28.9364C104.861 29.0627 105.195 29.1217 105.529 29.1094C105.968 29.1505 106.409 29.0488 106.785 28.8194C107.162 28.5899 107.455 28.245 107.62 27.8359L109.384 27.8359ZM103.102 25.1338H107.628C107.645 24.8342 107.6 24.5344 107.495 24.2532C107.39 23.972 107.229 23.7154 107.02 23.4997C106.812 23.2839 106.561 23.1136 106.283 22.9993C106.006 22.8851 105.708 22.8295 105.408 22.8359C105.105 22.8341 104.805 22.8923 104.525 23.007C104.245 23.1218 103.99 23.2908 103.776 23.5045C103.562 23.7181 103.392 23.9721 103.276 24.2517C103.16 24.5314 103.101 24.8312 103.102 25.1338Z"
          fill="white"
        />
        <path
          d="M37.8262 8.73016C38.2158 8.7022 38.6067 8.76106 38.9709 8.90249C39.335 9.04393 39.6632 9.26441 39.9317 9.54803C40.2003 9.83166 40.4026 10.1714 40.524 10.5426C40.6454 10.9139 40.6829 11.3075 40.6338 11.695C40.6338 13.6012 39.6035 14.697 37.8262 14.697H35.6709V8.73016H37.8262ZM36.5976 13.8532H37.7226C38.0011 13.8698 38.2797 13.8242 38.5382 13.7195C38.7967 13.6149 39.0287 13.454 39.2172 13.2484C39.4057 13.0428 39.546 12.7978 39.6278 12.5312C39.7097 12.2646 39.7311 11.9831 39.6904 11.7072C39.7281 11.4323 39.7046 11.1526 39.6215 10.8879C39.5383 10.6232 39.3977 10.3802 39.2096 10.1763C39.0216 9.97236 38.7907 9.81255 38.5337 9.70832C38.2766 9.60408 37.9996 9.558 37.7226 9.57337H36.5976V13.8532Z"
          fill="white"
        />
        <path
          d="M41.6805 12.4436C41.6521 12.1476 41.686 11.8491 41.7798 11.567C41.8737 11.2849 42.0254 11.0256 42.2254 10.8056C42.4253 10.5856 42.669 10.4099 42.9409 10.2896C43.2127 10.1693 43.5067 10.1072 43.804 10.1072C44.1013 10.1072 44.3952 10.1693 44.6671 10.2896C44.939 10.4099 45.1827 10.5856 45.3826 10.8056C45.5825 11.0256 45.7343 11.2849 45.8281 11.567C45.922 11.8491 45.9558 12.1476 45.9275 12.4436C45.9564 12.7398 45.9229 13.0388 45.8293 13.3213C45.7357 13.6038 45.5841 13.8636 45.3841 14.084C45.1841 14.3045 44.9402 14.4806 44.6681 14.6011C44.3959 14.7217 44.1016 14.7839 43.804 14.7839C43.5064 14.7839 43.212 14.7217 42.9399 14.6011C42.6678 14.4806 42.4239 14.3045 42.2239 14.084C42.0239 13.8636 41.8722 13.6038 41.7786 13.3213C41.6851 13.0388 41.6516 12.7398 41.6805 12.4436ZM45.0135 12.4436C45.0135 11.4675 44.575 10.8967 43.8055 10.8967C43.033 10.8967 42.5985 11.4675 42.5985 12.4436C42.5985 13.4275 43.033 13.9939 43.8055 13.9939C44.575 13.9938 45.0135 13.4235 45.0135 12.4436Z"
          fill="white"
        />
        <path
          d="M51.5732 14.6969H50.6514L49.7207 11.3805H49.6504L48.7236 14.6969H47.8105L46.5693 10.194H47.4707L48.2773 13.63H48.3438L49.2695 10.194H50.1221L51.0479 13.63H51.1182L51.9209 10.194H52.8096L51.5732 14.6969Z"
          fill="white"
        />
        <path
          d="M53.8535 10.194H54.709V10.9093H54.7754C54.888 10.6524 55.0781 10.4371 55.3189 10.2932C55.5598 10.1494 55.8395 10.0844 56.1191 10.1071C56.3382 10.0906 56.5582 10.1237 56.7628 10.2038C56.9674 10.2838 57.1514 10.409 57.3011 10.5698C57.4508 10.7306 57.5623 10.9231 57.6275 11.1329C57.6927 11.3427 57.7099 11.5645 57.6777 11.7819V14.6969H56.7891V12.0051C56.7891 11.2814 56.4746 10.9216 55.8174 10.9216C55.6686 10.9146 55.5201 10.9399 55.3821 10.9958C55.244 11.0516 55.1197 11.1366 55.0175 11.245C54.9154 11.3534 54.8379 11.4826 54.7904 11.6237C54.7429 11.7648 54.7264 11.9146 54.7422 12.0627V14.6969H53.8535L53.8535 10.194Z"
          fill="white"
        />
        <path
          d="M59.0938 8.43616H59.9824V14.6969H59.0938V8.43616Z"
          fill="white"
        />
        <path
          d="M61.2175 12.4435C61.1893 12.1476 61.2231 11.849 61.317 11.5669C61.4109 11.2848 61.5626 11.0255 61.7626 10.8055C61.9626 10.5855 62.2063 10.4097 62.4782 10.2895C62.75 10.1692 63.044 10.1071 63.3413 10.1071C63.6386 10.1071 63.9326 10.1692 64.2045 10.2895C64.4764 10.4097 64.7201 10.5855 64.92 10.8055C65.12 11.0255 65.2718 11.2848 65.3656 11.5669C65.4595 11.849 65.4934 12.1476 65.4651 12.4435C65.4939 12.7398 65.4604 13.0387 65.3668 13.3213C65.2732 13.6038 65.1215 13.8636 64.9215 14.084C64.7215 14.3044 64.4776 14.4805 64.2054 14.6011C63.9333 14.7216 63.639 14.7839 63.3413 14.7839C63.0437 14.7839 62.7493 14.7216 62.4772 14.6011C62.2051 14.4805 61.9612 14.3044 61.7612 14.084C61.5611 13.8636 61.4095 13.6038 61.3158 13.3213C61.2222 13.0387 61.1887 12.7398 61.2175 12.4435ZM64.5505 12.4435C64.5505 11.4674 64.1121 10.8966 63.3425 10.8966C62.5701 10.8966 62.1355 11.4674 62.1355 12.4435C62.1355 13.4274 62.5701 13.9938 63.3425 13.9938C64.1121 13.9938 64.5505 13.4235 64.5505 12.4435Z"
          fill="white"
        />
        <path
          d="M66.4009 13.4235C66.4009 12.6129 67.0044 12.1456 68.0757 12.0792L69.2954 12.0089V11.6202C69.2954 11.1446 68.981 10.8761 68.3735 10.8761C67.8774 10.8761 67.5337 11.0582 67.4351 11.3766H66.5747C66.6655 10.6031 67.3931 10.1071 68.4146 10.1071C69.5435 10.1071 70.1802 10.6691 70.1802 11.6202V14.6969H69.3247V14.0641H69.2544C69.1117 14.2911 68.9113 14.4761 68.6737 14.6003C68.436 14.7245 68.1697 14.7835 67.9019 14.7711C67.7128 14.7907 67.5218 14.7706 67.341 14.7119C67.1602 14.6532 66.9938 14.5573 66.8524 14.4303C66.711 14.3033 66.5977 14.1481 66.52 13.9747C66.4422 13.8013 66.4016 13.6135 66.4009 13.4235ZM69.2954 13.0387V12.6622L68.1958 12.7325C67.5757 12.774 67.2944 12.985 67.2944 13.3819C67.2944 13.7872 67.646 14.0231 68.1294 14.0231C68.2711 14.0374 68.4142 14.0231 68.5502 13.981C68.6862 13.9389 68.8123 13.8699 68.9211 13.7781C69.0299 13.6862 69.1191 13.5734 69.1834 13.4464C69.2477 13.3194 69.2858 13.1807 69.2954 13.0387Z"
          fill="white"
        />
        <path
          d="M71.3481 12.4435C71.3481 11.0206 72.0796 10.1193 73.2173 10.1193C73.4987 10.1063 73.778 10.1737 74.0225 10.3136C74.267 10.4535 74.4667 10.6601 74.5981 10.9093H74.6646V8.43616H75.5532V14.6969H74.7017V13.9855H74.6314C74.4897 14.233 74.2831 14.437 74.0339 14.5755C73.7846 14.714 73.5022 14.7816 73.2173 14.7711C72.0718 14.7712 71.3481 13.8698 71.3481 12.4435ZM72.2661 12.4435C72.2661 13.3986 72.7163 13.9733 73.4693 13.9733C74.2183 13.9733 74.6812 13.3903 74.6812 12.4474C74.6812 11.5089 74.2134 10.9176 73.4693 10.9176C72.7212 10.9176 72.2661 11.4962 72.2661 12.4435Z"
          fill="white"
        />
        <path
          d="M79.2298 12.4436C79.2015 12.1476 79.2353 11.8491 79.3292 11.567C79.423 11.2849 79.5748 11.0256 79.7747 10.8056C79.9746 10.5856 80.2183 10.4099 80.4902 10.2896C80.762 10.1693 81.056 10.1072 81.3533 10.1072C81.6506 10.1072 81.9446 10.1693 82.2164 10.2896C82.4883 10.4099 82.732 10.5856 82.9319 10.8056C83.1319 11.0256 83.2836 11.2849 83.3775 11.567C83.4713 11.8491 83.5052 12.1476 83.4768 12.4436C83.5057 12.7398 83.4722 13.0388 83.3786 13.3213C83.2851 13.6038 83.1334 13.8636 82.9334 14.084C82.7334 14.3045 82.4895 14.4806 82.2174 14.6011C81.9453 14.7217 81.6509 14.7839 81.3533 14.7839C81.0557 14.7839 80.7613 14.7217 80.4892 14.6011C80.2171 14.4806 79.9732 14.3045 79.7732 14.084C79.5732 13.8636 79.4216 13.6038 79.328 13.3213C79.2344 13.0388 79.2009 12.7398 79.2298 12.4436ZM82.5628 12.4436C82.5628 11.4675 82.1243 10.8967 81.3548 10.8967C80.5823 10.8967 80.1478 11.4675 80.1478 12.4436C80.1478 13.4275 80.5823 13.9939 81.3548 13.9939C82.1243 13.9938 82.5628 13.4235 82.5628 12.4436Z"
          fill="white"
        />
        <path
          d="M84.6694 10.194H85.5249V10.9093H85.5913C85.704 10.6524 85.894 10.4371 86.1348 10.2932C86.3757 10.1494 86.6555 10.0844 86.9351 10.1071C87.1542 10.0906 87.3742 10.1237 87.5788 10.2038C87.7834 10.2838 87.9673 10.409 88.117 10.5698C88.2667 10.7306 88.3783 10.9231 88.4435 11.1329C88.5087 11.3427 88.5258 11.5645 88.4937 11.7819V14.6969H87.605V12.0051C87.605 11.2814 87.2905 10.9216 86.6333 10.9216C86.4846 10.9146 86.336 10.9399 86.198 10.9958C86.0599 11.0516 85.9356 11.1366 85.8335 11.245C85.7313 11.3534 85.6539 11.4826 85.6063 11.6237C85.5588 11.7648 85.5423 11.9146 85.5581 12.0627V14.6969H84.6694V10.194Z"
          fill="white"
        />
        <path
          d="M93.5151 9.07288V10.2145H94.4907V10.963H93.5151V13.2784C93.5151 13.7501 93.7095 13.9567 94.1519 13.9567C94.2651 13.9563 94.3783 13.9495 94.4907 13.9362V14.6764C94.3311 14.7049 94.1694 14.7201 94.0073 14.7218C93.019 14.7218 92.6255 14.3741 92.6255 13.506V10.963H91.9106V10.2144H92.6255V9.07288H93.5151Z"
          fill="white"
        />
        <path
          d="M95.7046 8.43616H96.5854V10.9176H96.6558C96.7739 10.6583 96.9691 10.4417 97.2148 10.2973C97.4605 10.153 97.7447 10.0879 98.0288 10.111C98.2467 10.0991 98.4646 10.1355 98.6669 10.2175C98.8692 10.2996 99.0508 10.4253 99.199 10.5856C99.3471 10.7459 99.458 10.937 99.5238 11.1451C99.5896 11.3532 99.6086 11.5733 99.5795 11.7897V14.6969H98.69V12.0089C98.69 11.2897 98.355 10.9254 97.7271 10.9254C97.5743 10.9129 97.4207 10.9339 97.2769 10.9869C97.1332 11.04 97.0027 11.1238 96.8947 11.2326C96.7867 11.3413 96.7038 11.4723 96.6517 11.6164C96.5997 11.7606 96.5797 11.9144 96.5933 12.067V14.6969H95.7046L95.7046 8.43616Z"
          fill="white"
        />
        <path
          d="M104.761 13.481C104.641 13.8926 104.379 14.2486 104.022 14.4867C103.666 14.7249 103.237 14.83 102.81 14.7838C102.514 14.7916 102.219 14.7348 101.946 14.6172C101.674 14.4997 101.43 14.3243 101.232 14.1032C101.034 13.882 100.887 13.6205 100.8 13.3366C100.713 13.0528 100.69 12.7535 100.73 12.4595C100.691 12.1647 100.715 11.8647 100.801 11.5801C100.888 11.2954 101.035 11.0326 101.231 10.8095C101.428 10.5865 101.671 10.4083 101.943 10.2871C102.214 10.1659 102.509 10.1045 102.806 10.107C104.059 10.107 104.815 10.963 104.815 12.377V12.6871H101.635V12.7369C101.621 12.9021 101.642 13.0685 101.696 13.2252C101.751 13.382 101.837 13.5257 101.95 13.6471C102.063 13.7686 102.2 13.8651 102.352 13.9305C102.504 13.9959 102.669 14.0287 102.835 14.0269C103.047 14.0524 103.263 14.0141 103.453 13.9169C103.644 13.8197 103.802 13.668 103.906 13.481L104.761 13.481ZM101.635 12.0299H103.91C103.921 11.8787 103.9 11.7269 103.85 11.5842C103.799 11.4415 103.718 11.311 103.614 11.2012C103.51 11.0913 103.383 11.0045 103.243 10.9463C103.103 10.8881 102.953 10.8599 102.801 10.8634C102.648 10.8614 102.495 10.8903 102.353 10.9482C102.21 11.0062 102.081 11.092 101.972 11.2007C101.864 11.3095 101.778 11.4388 101.72 11.5812C101.662 11.7236 101.633 11.8762 101.635 12.0299Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12347_514">
          <rect
            width="135"
            height="40"
            fill="white"
            transform="translate(0 -0.000854492)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
