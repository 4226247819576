import { SvgIcon, SvgIconProps } from '@mui/material'

export const LikeCheckedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M11.7929 4.558C10.7034 3.5561 9.27716 3.00005 7.79695 3C7.01349 3.00082 6.23792 3.15656 5.51489 3.45827C4.79185 3.75998 4.13562 4.20169 3.58395 4.758C1.23095 7.121 1.23195 10.817 3.58595 13.17L10.9179 20.502C11.0879 20.801 11.4159 20.994 11.7929 20.994C11.9477 20.9925 12.1 20.9547 12.2376 20.8837C12.3751 20.8126 12.4941 20.7103 12.5849 20.585L20 13.17C22.354 10.816 22.354 7.121 19.9979 4.754C19.4466 4.19872 18.7909 3.7579 18.0685 3.45688C17.3462 3.15587 16.5715 3.0006 15.7889 3C14.3088 3.00024 12.8827 3.55627 11.7929 4.558Z"
      fill="#FC605B"
    />
  </SvgIcon>
)
