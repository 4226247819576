import { SvgIcon, SvgIconProps } from '@mui/material'

export const SentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_9984_122041)">
        <path
          d="M10.1214 13.8332C10.3023 13.6523 10.442 13.4408 10.5365 13.2044L14.0721 4.36554C14.3511 3.66794 14.1882 2.87395 13.6569 2.34271C13.1257 1.81147 12.3317 1.64853 11.6341 1.92757L2.79518 5.46313C2.55888 5.55766 2.34734 5.69733 2.16643 5.87824C1.43537 6.6093 1.43537 7.79883 2.16643 8.52989C2.41253 8.77599 2.72103 8.94837 3.05852 9.02838L5.84737 9.68959C6.07536 9.74364 6.25596 9.92415 6.31004 10.1523L6.97125 12.9411C7.05126 13.2786 7.22364 13.5871 7.46973 13.8332C8.20079 14.5643 9.39033 14.5643 10.1214 13.8332ZM7.52628 9.86392C7.36325 9.1763 6.82386 8.63651 6.13571 8.47335L3.34686 7.81214C3.235 7.78558 3.13245 7.72815 3.05032 7.64601C2.80663 7.40232 2.80663 7.00581 3.05032 6.76213C3.11086 6.70158 3.18126 6.655 3.25949 6.62371L12.0983 3.08818C12.331 2.99508 12.5959 3.04942 12.773 3.22659C12.9502 3.40377 13.0045 3.6686 12.9115 3.90131L9.37594 12.7401C9.34461 12.8184 9.29805 12.8888 9.2375 12.9493C8.99381 13.193 8.59731 13.193 8.35362 12.9493C8.27148 12.8672 8.21405 12.7646 8.18751 12.6527L7.52628 9.86392Z"
          fill="#1D1D1D"
        />
        <path
          d="M4.11104 11.0044L2.34327 12.7722C2.09919 13.0163 2.09919 13.412 2.34327 13.6561C2.58735 13.9002 2.98307 13.9002 3.22715 13.6561L4.99492 11.8883C5.239 11.6442 5.239 11.2485 4.99492 11.0044C4.75084 10.7604 4.35512 10.7604 4.11104 11.0044Z"
          fill="#1D1D1D"
        />
        <path
          d="M4.99483 13.6568L4.11094 14.5407C3.86686 14.7848 3.86686 15.1805 4.11094 15.4246C4.35503 15.6686 4.75074 15.6686 4.99483 15.4246L5.87871 14.5407C6.12279 14.2966 6.12279 13.9009 5.87871 13.6568C5.63462 13.4127 5.23891 13.4127 4.99483 13.6568Z"
          fill="#1D1D1D"
        />
        <path
          d="M1.45918 10.1207L0.575298 11.0045C0.331213 11.2486 0.331213 11.6443 0.575298 11.8884C0.819382 12.1325 1.2151 12.1325 1.45918 11.8884L2.34306 11.0045C2.58715 10.7605 2.58715 10.3647 2.34306 10.1207C2.09898 9.87657 1.70327 9.87657 1.45918 10.1207Z"
          fill="#1D1D1D"
        />
        <path
          d="M7.91159 8.08826C8.15568 8.33234 8.55139 8.33234 8.79548 8.08826L10.5296 6.35415C10.7737 6.11006 10.7737 5.71435 10.5296 5.47026C10.2855 5.22618 9.88979 5.22618 9.6457 5.47026L7.91159 7.20438C7.66751 7.44846 7.66751 7.84417 7.91159 8.08826Z"
          fill="#1D1D1D"
        />
      </g>
      <defs>
        <clipPath id="clip0_9984_122041">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
