import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, get, post } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import {
  CheckoutProvider,
  TCheckoutStatus,
  TOrderPaymentStatus,
  TVippsCheckoutStatus,
} from './checkout.codecs'

const checkouts = 'purchases/checkouts' as const

type ProcessCheckoutInput = {
  userSourceId: UUID | null
  eventId: UUID
} & LocaleInput

export const processCheckout = async (input: ProcessCheckoutInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await post(checkouts, {
      body: {
        provider: CheckoutProvider.STRIPE,
        userSourceId: input.userSourceId,
        eventId: input.eventId,
      },
      headers,
    }),
    decodeJson(TCheckoutStatus),
  )
}

export const processVippsCheckout = async (
  input: Omit<ProcessCheckoutInput, 'userSourceId'>,
) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await post(checkouts, {
      body: {
        provider: CheckoutProvider.VIPPS,
        userSourceId: null,
        eventId: input.eventId,
      },
      headers,
    }),
    decodeJson(TVippsCheckoutStatus),
  )
}

type ChallengeCheckoutInput = {
  payload: string
  saveSource?: boolean
}

export const challengeCheckout = async (input: ChallengeCheckoutInput) => {
  return pipe(
    await post(`${checkouts}/challenge`, {
      body: {
        payload: input.payload,
        saveSource: input.saveSource,
      },
    }),
    decodeJson(TCheckoutStatus),
  )
}

type CheckOrderSoldInput = {
  orderId: UUID
  userId: UUID
}

export const checkOrderPaymentStatus = async (input: CheckOrderSoldInput) => {
  return pipe(
    await get('purchases/public/users/:userId/orders/:orderId/status', {
      params: {
        orderId: input.orderId,
        userId: input.userId,
      },
    }),
    decodeJson(TOrderPaymentStatus),
  )
}
