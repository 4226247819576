import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const VisaWhiteLogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 81 26">
      <path
        d="M42.1327 8.60122C42.0868 11.9992 45.3597 13.8956 47.8254 15.0229C50.3587 16.1798 51.2095 16.9216 51.1999 17.9559C51.1805 19.5392 49.179 20.2379 47.3056 20.2651C44.0375 20.3128 42.1375 19.4372 40.6267 18.7748L39.4495 23.9444C40.9651 24.6 43.7716 25.1716 46.682 25.1965C53.5132 25.1965 57.9827 22.0322 58.0069 17.1257C58.0335 10.8991 48.8285 10.5543 48.8914 7.771C48.9131 6.92717 49.7713 6.02663 51.6519 5.79752C52.5826 5.68184 55.1521 5.59337 58.0649 6.85231L59.2083 1.85058C57.6419 1.31524 55.6283 0.802595 53.1216 0.802595C46.6917 0.802595 42.1689 4.01006 42.1327 8.60122ZM70.1948 1.23359C68.9475 1.23359 67.896 1.91636 67.427 2.96434L57.6685 24.8291H64.4949L65.8534 21.3063H74.1954L74.9834 24.8291H81L75.7497 1.23359L70.1948 1.23359ZM71.1496 7.60767L73.1197 16.4679H67.7243L71.1496 7.60767ZM33.8559 1.23359L28.4751 24.8291H34.98L40.3584 1.23359H33.8559ZM24.2328 1.23359L17.462 17.2936L14.7232 3.63804C14.4017 2.11371 13.1327 1.23359 11.7234 1.23359H0.654705L0.5 1.91863C2.77224 2.38137 5.35388 3.12766 6.91786 3.92613C7.8751 4.41382 8.14825 4.84027 8.46249 5.99941L13.65 24.8291L20.5247 24.8291L31.064 1.23359H24.2328Z"
        fill="white"
      />
    </SvgIcon>
  )
}
