import { AppBar, Box, Drawer, Stack } from '@mui/material'
import { useRouter } from 'next/router'
import { Menu } from './menu/menu'
import { MobileNavigation } from './mobile-navigation'
import { DesktopNavigation } from './desktop-navigation'
import { useWindowScroll } from 'react-use'
import { useBoolean } from 'src/lib/use-boolean'
import { pagesGradient } from 'src/assets/gradients'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'
import { FabniteLogoCosmosBackgroundIcon } from 'src/assets/icons'
import { GlobalLoading } from '../global-loading'

type Props = {
  isPageLoading: boolean
  isDisableGradient?: boolean
}

export const NavigationBar = ({ isPageLoading, isDisableGradient }: Props) => {
  const router = useRouter()

  const menuDrawer = useBoolean(false)

  const background = isDisableGradient
    ? undefined
    : pagesGradient[router.pathname]

  const { y } = useWindowScroll()

  // Don't show animation on scroll on pages where gradient is absent
  const isSmallNavigation = y > 0 || background === undefined

  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          background,
          // Fixes issue with white line between header and other gradient in some browsers
          ...(!isPageLoading && {
            mb: '-1px',
            borderBottom: '1px solid transparent',
          }),
          ...(background === undefined && { backgroundColor: 'primary3.main' }),
          backgroundOrigin: 'border-box',
          width: '100%',
          ...(y > 0 &&
            background !== undefined && {
              boxShadow: '0px 4px 69px rgba(82, 82, 82, 0.2);',
            }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            pt: { xs: '10px', lg: isSmallNavigation ? '12px' : '26px' },
            pb: { xs: '9px', lg: isSmallNavigation ? '11px' : '25px' },
            pl: { xs: 2, sm: 3.5, md: 6, lg: 7, xl: 14 },
            pr: { xs: '10px', sm: 3.5, md: 6, lg: 3, xl: 10 },
            justifyContent: 'space-between',
            transition: '0.4s',
          }}
        >
          <NextLink href={pages['/']}>
            <FabniteLogoCosmosBackgroundIcon
              sx={{
                fontSize: {
                  xs: 40,
                  sm: 48,
                  lg: isSmallNavigation ? 48 : 80,
                  xl: isSmallNavigation ? 60 : 80,
                },
                transition: '0.4s',
                display: 'block',
              }}
            />
          </NextLink>
          <Box display={{ xs: 'block', lg: 'none' }}>
            <MobileNavigation onMenuClick={menuDrawer.setTrue} />
          </Box>
          <Box display={{ xs: 'none', lg: 'block' }}>
            <DesktopNavigation onMenuClick={menuDrawer.setTrue} />
          </Box>
        </Stack>
        <GlobalLoading
          isPageLoading={isPageLoading}
          isWithDefaultBackground={background === undefined}
          sx={{ mt: '-2px', mb: '-1px' }}
        />
      </AppBar>
      <Drawer
        open={menuDrawer.isTrue}
        onClose={menuDrawer.setFalse}
        ModalProps={{
          keepMounted: true,
          disableRestoreFocus: true,
        }}
        PaperProps={{
          sx: {
            position: 'relative',
            width: '100%',
            backgroundColor: 'primary3.main',
            color: 'common.white',
            maxHeight: '100vh',
            overflow: 'hidden',
            borderRadius: 0,
          },
        }}
        variant="temporary"
      >
        <Menu onClose={menuDrawer.setFalse} />
      </Drawer>
    </>
  )
}
