import { ReactNode } from 'react'
import { Typography, Stack, Chip } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { NextLink } from 'src/components/next-link'

type Props = {
  label: string
  href: string
  onClose: () => void
  icon: ReactNode
  countIndicator?: number
  isDisabled: boolean
}

export const MenuItemLinkWithIcon = ({
  label,
  href,
  onClose,
  icon,
  countIndicator,
  isDisabled,
}: Props) => {
  const { t } = useTranslation()

  return (
    <NextLink
      href={href}
      color="common.white"
      onClick={onClose}
      disabled={isDisabled}
      sx={{
        '&&': {
          textDecorationThickness: { xs: '1.5px', xl: '3px' },
        },
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.25}
          height={{ xs: '20px', sm: '24px', xl: '28px' }}
        >
          {icon}
          <Typography variant="h4">{label}</Typography>
          {countIndicator && <Chip variant="countTag" label={countIndicator} />}
          {isDisabled && <Chip variant="comingSoon" label={t('coming')} />}
        </Stack>
      </Stack>
    </NextLink>
  )
}
