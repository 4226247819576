import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import {
  decodeJsonWithTotal,
  InfiniteScrollInput,
  InfiniteScrollPaginationInput,
} from 'src/lib/get-list'
import { decodeJson, get } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { TEvent } from '../event/event.codecs'
import {
  mapSeeAllQueryParams,
  mapCoordinatesToQueryParam,
  mapInfinitePaginationToUrlParams,
} from '../public.utils'
import {
  TVenueDetails,
  TVenuesCountries,
  TVenueWithPayload,
} from './venue.codecs'
import { UserCoordinates } from 'src/lib/use-user-geolocation'
import { IMonth, TMonths } from '../public.codecs'

const venues = `cms/public/venues` as const

export type GetVenueInput = {
  venueSlug: string
} & LocaleInput

export const getVenue = async (input: GetVenueInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${venues}/${input.venueSlug}`, {
      headers,
    }),
    decodeJson(TVenueDetails),
  )
}

export type GetVenuesInput = {
  country: string
  coordinates: UserCoordinates
  sort: string
  placeId: string
} & LocaleInput &
  InfiniteScrollPaginationInput

export const getVenues = async ({
  pageParam = 0,
  input: { limit, locale, country, sort, coordinates, placeId },
}: InfiniteScrollInput<GetVenuesInput>) => {
  const { headers } = setAcceptLanguageHeader(locale)

  const query = mapSeeAllQueryParams({
    coordinates,
    limit,
    pageParam,
  })

  query.set('sort', sort)

  if (country) {
    query.set('country', country)
  }

  if (placeId) {
    query.set('placeId', placeId)
  }

  return pipe(
    await get(venues, {
      query,
      headers,
    }),
    decodeJsonWithTotal(TVenueWithPayload),
  )
}

export type GetVenuesCountriesInput = {
  coordinates: UserCoordinates
  ipCountry: string
}
export const getVenuesCountries = async (input: GetVenuesCountriesInput) => {
  const query = mapCoordinatesToQueryParam(input.coordinates)

  if (input.coordinates === null && input.ipCountry) {
    query.set('ipCountry', input.ipCountry)
  }

  return pipe(
    await get(`${venues}/countries`, {
      query,
    }),
    decodeJson(array(TVenuesCountries)),
  )
}

export type GetEventListByVenueInput = InfiniteScrollPaginationInput & {
  venueSlug: string
  month: IMonth
} & LocaleInput

export const getEventListByVenue = async ({
  pageParam = 0,
  input: { venueSlug, limit, locale, month },
}: InfiniteScrollInput<GetEventListByVenueInput>) => {
  const { headers } = setAcceptLanguageHeader(locale)

  const query = mapInfinitePaginationToUrlParams({
    pageParam,
    limit,
  })

  if (month !== undefined) {
    query.set('month', month)
  }

  return pipe(
    await get(`${venues}/${venueSlug}/events`, {
      query,
      headers,
    }),
    decodeJsonWithTotal(TEvent),
  )
}

export type GetVenueMonthsInput = {
  venueSlug: string
}

export const getVenueMonths = async (input: GetVenueMonthsInput) => {
  return pipe(
    await get(`${venues}/${input.venueSlug}/events/months`, {}),
    decodeJson(TMonths),
  )
}
