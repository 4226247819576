import { SvgIcon, SvgIconProps } from '@mui/material'

export const TrashIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect
        x="8"
        y="11"
        width="1.25"
        height="7"
        rx="0.625"
        fill="currentColor"
      />
      <rect
        x="11"
        y="11"
        width="1.25"
        height="7"
        rx="0.625"
        fill="currentColor"
      />
      <rect
        x="14"
        y="11"
        width="1.25"
        height="7"
        rx="0.625"
        fill="currentColor"
      />
      <path
        d="M8 4.5C8 3.67157 8.67157 3 9.5 3H13.5C14.3284 3 15 3.67157 15 4.5V5H8V4.5Z"
        stroke="currentColor"
        strokeWidth="1.4"
        fill="transparent"
      />
      <path
        d="M4 6C4 5.44772 4.44772 5 5 5H18C18.5523 5 19 5.44772 19 6V7.5C19 7.77614 18.7761 8 18.5 8H4.5C4.22386 8 4 7.77614 4 7.5V6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="transparent"
      />
      <path
        d="M5 8V7.25H4.19009L4.25221 8.05752L5 8ZM18 8L18.7478 8.05752L18.8099 7.25H18V8ZM4.25221 8.05752L5.11016 19.2109L6.60574 19.0959L5.74779 7.94248L4.25221 8.05752ZM7.85206 21.75H15.1479V20.25H7.85206V21.75ZM17.8898 19.2109L18.7478 8.05752L17.2522 7.94248L16.3943 19.0959L17.8898 19.2109ZM18 7.25H5V8.75H18V7.25ZM15.1479 21.75C16.5849 21.75 17.7796 20.6437 17.8898 19.2109L16.3943 19.0959C16.3442 19.7471 15.8011 20.25 15.1479 20.25V21.75ZM5.11016 19.2109C5.22037 20.6437 6.41509 21.75 7.85206 21.75V20.25C7.19889 20.25 6.65584 19.7471 6.60574 19.0959L5.11016 19.2109Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
