import { Stack, Button, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { FabniteLogoBlueBackgroundIcon, CrossIcon } from 'src/assets/icons'

type Props = {
  onClose: () => void
}

export const DialogHeader = ({ onClose }: Props) => {
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        py: { xs: 2 },
        px: { xs: 3, sm: 4 },
        backgroundColor: 'secondary5.main',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FabniteLogoBlueBackgroundIcon sx={{ fontSize: { xs: 32, sm: 40 } }} />
        <Button
          onClick={onClose}
          disableRipple
          endIcon={
            <CrossIcon
              sx={{
                color: 'primary3.main',
                '&&': { fontSize: { xs: 16 } },
              }}
            />
          }
          sx={{
            p: '4px 6px',
            borderRadius: '6px',
            '& .MuiButton-endIcon': {
              marginLeft: { xs: 0.5 },
            },
            '&:hover': {
              textDecoration: 'underline',
              textUnderlineOffset: '3px',
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'common.white',
              textDecoration: 'none',
            },
          }}
        >
          <Typography variant="smallTextSemiBold" color="primary3.main">
            {t('close')}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}
