import { ThemeOptions, Box } from '@mui/material'
import { createTheme, lighten } from '@mui/material/styles'
import { typography } from './typography'
import { breakpointsTheme } from './breakpoints'
import { palette } from './palette'
import { CheckboxSelectedIcon } from './icons/checkbox/checkbox-selected'
import { AlertErrorIcon } from 'src/assets/icons/alert/error'
import { ChevronIcon } from 'src/assets/icons/chevron'
import { common } from '@mui/material/colors'

const guestThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: palette.white,
    },
    common: {
      black: palette.black,
      white: palette.white,
    },
    primary: {
      main: palette.black,
    },
    primary2: {
      main: palette.carnation,
    },
    primary3: {
      main: palette.resolutionBlue,
    },
    secondary1: {
      main: palette.supernova,
    },
    secondary2: {
      main: palette.puertoRico,
    },
    secondary3: {
      main: palette.heliotrope,
    },
    secondary4: {
      main: palette.orange,
    },
    accent1: {
      main: palette.mintTulip,
    },
    accent2: {
      main: palette.cosmos,
    },
    /* gray colors*/
    secondary5: {
      main: palette.gallery,
    },
    secondary6: {
      main: palette.lightGray,
    },
    secondary7: {
      main: palette.gray,
    },
    secondary8: {
      main: palette.darkGray,
    },
    secondary9: {
      main: palette.wetAsphalt,
    },
    text: {
      primary: palette.black,
    },
    strongBlue: {
      main: palette.strongBlue,
    },
    backgroundColor: {
      main: palette.background,
    },
    middleBlue: {
      main: palette.middleBlue,
    },
    middleGreen: {
      main: palette.middleGreen,
    },
    middleGrey: {
      main: palette.middleGrey,
    },
    greenCards: {
      main: palette.greenCards,
    },
    lightBlue: {
      main: palette.lightBlue,
    },
    lightRed: {
      main: palette.lightRed,
    },
    lightGreen: {
      main: palette.lightGreen,
    },
    lightOrange: {
      main: palette.lightOrange,
    },
    paleRed: {
      main: palette.paleRed,
    },
    buttonPrimaryHover: {
      main: palette.buttonPrimaryHover,
    },
    buttonPrimaryPressed: {
      main: palette.buttonPrimaryPressed,
    },
    buttonPrimaryDisabled: {
      main: palette.buttonPrimaryDisabled,
    },
    buttonSecondaryHover: {
      main: palette.buttonSecondaryHover,
    },
    buttonSecondaryPressed: {
      main: palette.buttonSecondaryPressed,
    },
    buttonCancelHover: {
      main: palette.buttonCancelHover,
    },
    buttonDestructiveHover: {
      main: palette.buttonDestructiveHover,
    },
    buttonDestructivePressed: {
      main: palette.buttonDestructivePressed,
    },
    fieldsDisabled: {
      main: palette.fieldsDisabled,
    },
  },
  breakpoints: breakpointsTheme.breakpoints,
  typography,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: `linear-gradient(90deg, rgba(243, 243, 243, 0.3) 100%, rgba(243, 243, 243, 0.3) 100%), linear-gradient(90deg, ${common.white} 100%, ${common.white} 100%)`,
          },
          '& input': {
            height: 'auto',
            padding: '8px 16px',
            [breakpointsTheme.breakpoints.up('xl')]: {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          },
          '& fieldset': {
            borderColor: palette.gallery,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          },
          backgroundColor: palette.white,
          '&.MuiOutlinedInput-root': {
            borderRadius: '6px',
          },
          '&.Mui-disabled': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: palette.gallery,
              backgroundColor: palette.fieldsDisabled,
            },
            '& input': {
              borderRadius: 'inherit',
              backgroundColor: 'inherit',
            },
            '& input:disabled': {
              color: palette.darkGray,
              zIndex: 1,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.gallery,
            borderWidth: '1px',
          },
          color: palette.black,
          '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.gallery,
          },
          '&:hover input': {
            borderRadius: 'inherit',
          },
          '&.Mui-disabled:hover input': {
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
          },
          '&&&.Mui-error': {
            color: palette.carnation,
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: palette.carnation,
            },
          },
          ...typography.fieldsEntered,
          '& input::placeholder': {
            ...typography.fieldsDefault,
            color: palette.darkGray,
            opacity: 1,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'nope',
        fullWidth: true,
        size: 'small',
      },
      styleOverrides: {
        root: {
          caretColor: palette.carnation,
          '& fieldset': {
            borderColor: palette.gallery,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginBottom: '6px',
          color: palette.wetAsphalt,
          ...typography.fieldsTitle,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: '4px',
          marginLeft: 0,
          color: palette.darkGray,
          ...typography.tinyTextRegular,
          '&.Mui-error': {
            color: palette.carnation,
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'tag' },
          style: {
            color: palette.resolutionBlue,
            background: palette.mintTulip,
            ...typography.smallTextSemiBold,
            paddingTop: '4px',
            paddingBottom: '4px',
          },
        },
        {
          props: { variant: 'status' },
          style: {
            color: palette.white,
            background: palette.puertoRico,
            ...typography.smallTextSemiBold,
            padding: '2px 10px',
            borderRadius: '12px',
            '& .MuiChip-label': {
              padding: 0,
            },
          },
        },
        {
          props: { variant: 'selectTag' },
          style: {
            color: palette.black,
            background: palette.gallery,
            border: `1px solid rgba(0, 0, 0, 0.16)`,
            ...typography.tinyTextRegular,
            padding: '3px 7px 3px 11px',
            alignSelf: 'center',
            '& .MuiChip-label': {
              padding: 0,
            },
            '& .MuiChip-deleteIcon': {
              margin: 0,
              marginLeft: '4px',
            },
            height: '26px !important',
          },
        },
        {
          props: { variant: 'countTag' },
          style: {
            color: palette.resolutionBlue,
            background: palette.gallery,
            borderRadius: '6px',
            ...typography.smallTextSemiBold,
            padding: '1px 10px',
            [breakpointsTheme.breakpoints.up('sm')]: {
              padding: '2px 10px',
            },
            alignSelf: 'center',
            cursor: 'pointer',
            '& .MuiChip-label': {
              padding: 0,
            },
            height: 'auto !important',
          },
        },
        {
          props: { variant: 'comingSoon' },
          style: {
            color: palette.resolutionBlue,
            background: palette.cosmos,
            borderRadius: '4px',
            ...typography.tinyTextSemiBold,
            padding: '1px 4px',
            [breakpointsTheme.breakpoints.up('xl')]: {
              padding: '2px 4px',
            },
            alignSelf: 'center',
            cursor: 'pointer',
            '& .MuiChip-label': {
              padding: 0,
            },
            height: 'auto !important',
          },
        },
      ],
      styleOverrides: {
        root: {
          height: 'auto',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&[disabled]': {
            color: palette.gray,
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '11px 24px',
          textTransform: 'none',
          borderRadius: 4,
          color: palette.black,
          height: 'auto',
          letterSpacing: 0,
          fontSize: 'inherit',
          minWidth: 'auto',
        },
        endIcon: {
          marginRight: 0,
        },
      },
      variants: [
        {
          props: { variant: 'linkStyled' },
          style: {
            ...typography.smallTextSemiBold,
            padding: 0,
            color: palette.strongBlue,
            textDecoration: 'underline',
            textUnderlineOffset: '4px',
            textDecorationColor: 'transparent',
            transition: '0.4s',
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
              textDecorationColor: 'currentColor',
            },
            '&:active': {
              background: 'none',
              textDecoration: 'underline',
              textDecorationColor: 'currentColor',
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            ...typography.mediumButton,
            backgroundColor: palette.supernova,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: palette.buttonPrimaryHover,
            },
            '&:active': {
              backgroundColor: palette.buttonPrimaryPressed,
            },
            '&.Mui-disabled': {
              backgroundColor: palette.buttonPrimaryDisabled,
              color: palette.darkGray,
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: {
            variant: 'primary',
            size: 'big',
          },
          style: {
            ...typography.bigButton,
            padding: '12px 24px',
          },
        },
        {
          props: {
            variant: 'primary',
            size: 'medium',
          },
          style: {
            padding: '10px 24px',
          },
        },
        {
          props: { variant: 'primary', size: 'small' },
          style: {
            ...typography.smallButton,
            padding: '8px 20px',
            [breakpointsTheme.breakpoints.up('lg')]: {
              ...typography.smallButton,
              padding: '10px 24px',
            },
          },
        },
        {
          props: { variant: 'primary', size: 'extraSmall' },
          style: {
            padding: '4px 24px',
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            ...typography.mediumButton,
            borderRadius: '8px',
            border: `1px solid ${palette.black}`,
            backgroundColor: palette.white,
            '&:hover': {
              backgroundColor: palette.buttonSecondaryHover,
            },
            '&:active': {
              backgroundColor: palette.buttonSecondaryPressed,
            },
            '&.Mui-disabled': {
              backgroundColor: palette.gallery,
              color: palette.darkGray,
              borderColor: palette.darkGray,
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: {
            variant: 'secondary',
            size: 'big',
          },
          style: {
            ...typography.bigButton,
            padding: '11px 24px',
          },
        },
        {
          props: { variant: 'secondary', size: 'medium' },
          style: {
            padding: '9px 24px',
          },
        },
        {
          props: { variant: 'secondary', size: 'small' },
          style: {
            ...typography.smallButton,
            padding: '7px 20px',
            [breakpointsTheme.breakpoints.up('lg')]: {
              ...typography.smallButton,
              padding: '9px 23px',
            },
          },
        },
        {
          props: { variant: 'destructive' },
          style: {
            ...typography.mediumButton,
            borderRadius: '8px',
            backgroundColor: palette.carnation,
            color: palette.white,
            '&:hover': {
              backgroundColor: palette.buttonDestructiveHover,
            },
            '&:active': {
              backgroundColor: palette.buttonDestructivePressed,
            },
            '&.Mui-disabled': {
              backgroundColor: palette.paleRed,
              color: palette.lightGray,
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: { variant: 'destructive', size: 'medium' },
          style: {
            padding: '10px 24px',
          },
        },
        {
          props: { variant: 'destructive', size: 'small' },
          style: {
            ...typography.smallButton,
            padding: '8px 20px',
            [breakpointsTheme.breakpoints.up('lg')]: {
              ...typography.smallButton,
              padding: '10px 24px',
            },
          },
        },
        {
          props: { variant: 'crossButton' },
          style: {
            padding: 0,
            borderRadius: '50%',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            backgroundColor: palette.white,
            '&:hover': {
              backgroundColor: palette.buttonSecondaryHover,
            },
            '&:active': {
              backgroundColor: palette.buttonSecondaryPressed,
            },
            '&.Mui-disabled': {
              backgroundColor: palette.gallery,
              color: palette.darkGray,
              borderColor: '1px solid rgba(29, 29, 29, 0.16)',
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: { variant: 'cancel' },
          style: {
            ...typography.mediumButton,
            color: palette.carnation,
            border: '1px solid',
            borderColor: 'transparent',
            letterSpacing: 'normal',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: palette.buttonCancelHover,
            },
            '&:active': {
              backgroundColor: palette.lightRed,
              borderColor: palette.gallery,
            },
            '&.Mui-disabled': {
              backgroundColor: palette.lightRed,
              color: palette.paleRed,
              borderColor: palette.gallery,
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: { variant: 'cancel', size: 'big' },
          style: {
            ...typography.bigButton,
            padding: '9px 24px',
          },
        },
        {
          props: { variant: 'cancel', size: 'medium' },
          style: {
            ...typography.mediumButton,
            padding: '9px 24px',
          },
        },
        {
          props: { variant: 'cancel', size: 'small' },
          style: {
            ...typography.smallButton,
            padding: '7px 20px',
            [breakpointsTheme.breakpoints.up('lg')]: {
              ...typography.smallButton,
              padding: '9px 24px',
            },
          },
        },
        {
          props: { variant: 'underline' },
          style: {
            ...typography.tinyTextSemiBold,
            padding: '0',
            minWidth: 'auto',
            letterSpacing: 'normal',
            textDecorationLine: 'underline',
            '&:hover': {
              background: 'none',
              textDecorationLine: 'underline',
            },
          },
        },
      ],
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <AlertErrorIcon />,
        },
      },
      styleOverrides: {
        root: {
          padding: '7px 12px',
          borderRadius: '6px',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          [breakpointsTheme.breakpoints.up('xl')]: {
            paddingTop: '11px',
            paddingBottom: '11px',
          },
        },
        icon: {
          marginRight: '12px',
          padding: 0,
          alignSelf: 'center',
        },
        message: {
          ...typography.smallTextSemiBold,
          padding: 0,
          alignSelf: 'center',
        },
        outlinedError: {
          borderColor: palette.carnation,
          backgroundColor: lighten(palette.carnation, 0.95),
          color: palette.carnation,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: 'normal',
        },
      },
      defaultProps: {
        variantMapping: {
          h2ExtraBold: 'h2',
          h2Black: 'h2',
          h3Bold: 'h3',
          h3ExtraBold: 'h3',
          h3Black: 'h3',
          excerpt: 'p',
          smallTextRegular: 'p',
          smallTextMedium: 'p',
          smallTextSemiBold: 'p',
          tinyTextRegular: 'p',
          tinyTextMedium: 'p',
          tinyTextSemiBold: 'p',
          tinyTextBold: 'p',
          tinyTextExtraBold: 'p',
          quotationRegular: 'p',
          quotationBold: 'p',
          extraSmallRegular: 'p',
          extraSmallSemiBold: 'p',
          hintsMedium: 'p',
          bodyRegular: 'p',
          bodyMedium: 'p',
          bodySemiBold: 'p',
          bodyBold: 'p',
          mediumButton: 'p',
          bigButton: 'p',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        wrapper: {
          ...typography.bodyRegular,
          color: palette.gray,
        },
        root: {
          '&::before': {
            borderColor: 'inherit',
          },
          '&::after': {
            borderColor: 'inherit',
          },
          justifyContent: 'center',
          color: palette.lightGray,
          borderColor: palette.lightGray,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...typography.smallTextSemiBold,
          marginLeft: '8px',
        },
        root: {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
        autoHighlight: true,
        disableClearable: true,
        popupIcon: <ChevronIcon sx={{ color: 'common.black' }} />,
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.MuiInputBase-root': {
            padding: 0,
            '& input.MuiAutocomplete-input': {
              padding: '8px 16px',
              paddingRight: '32px',
              [breakpointsTheme.breakpoints.up('xl')]: {
                paddingTop: '10px',
                paddingBottom: '10px',
              },
            },
          },
        },
        paper: {
          marginTop: '4px',
        },
        listbox: {
          padding: 0,
          border: '1px solid',
          borderColor: palette.lightGray,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '6px',
          '& .MuiAutocomplete-option': {
            ...typography.fieldsDefault,
            minHeight: 'auto',
            padding: '8px 16px',
            [breakpointsTheme.breakpoints.up('xl')]: {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
            borderColor: palette.lightGray,
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: 'rgba(250, 202, 8, 0.3)',
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: 'rgba(253, 232, 149, 1)',
            ...typography.fieldsEntered,
          },
          '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
            backgroundColor: 'rgba(253, 232, 149, 1)',
          },
          '& .MuiAutocomplete-option.Mui-focused[aria-selected="false"]': {
            backgroundColor: 'rgba(250, 202, 8, 0.3)',
          },
          '& .MuiAutocomplete-option.Mui-focused[aria-selected="true"]': {
            backgroundColor: 'rgba(253, 232, 149, 1)',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [breakpointsTheme.breakpoints.up('xs')]: {
            ...typography.h4,
            padding: '24px',
            paddingBottom: 0,
          },
          [breakpointsTheme.breakpoints.up('sm')]: {
            padding: '32px',
            paddingBottom: 0,
          },
          [breakpointsTheme.breakpoints.up('lg')]: {
            ...typography.h4,
            padding: '40px',
            paddingBottom: 0,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '24px',
          '.MuiDialogTitle-root+&': {
            paddingTop: '24px',
          },
          [breakpointsTheme.breakpoints.up('xs')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '32px',
          },
          [breakpointsTheme.breakpoints.up('sm')]: {
            paddingLeft: '32px',
            paddingRight: '32px',
          },
          [breakpointsTheme.breakpoints.up('lg')]: {
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingBottom: '40px',
          },
          [breakpointsTheme.breakpoints.up('xl')]: {
            paddingBottom: '48px',
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          ...typography.tinyTextMedium,
          fontStyle: 'italic',
          color: palette.wetAsphalt,
          padding: '4px 24px 0px',
          [breakpointsTheme.breakpoints.up('sm')]: {
            padding: '4px 32px 0px',
          },
          [breakpointsTheme.breakpoints.up('lg')]: {
            padding: '8px 40px 0px',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          borderColor: 'transparent transparent #EAE9E9 transparent',
          borderWidth: '0 0 10px 472px',
          borderStyle: 'solid',
          borderRadius: '0px',
          color: palette.white,
          height: 0,
          width: 0,
        },
        track: {
          background: 'none',
          border: 'none',
        },
        thumb: {
          borderRadius: '12px',
          height: '24px',
          width: '8px',
          color: palette.supernova,
          '&.Mui-active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
          '&::before': {
            boxShadow: 'none',
          },
        },
        root: {
          width: '472px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          paddingTop: 0,
          [breakpointsTheme.breakpoints.up('xs')]: {
            paddingBottom: '32px',
            paddingLeft: '24px',
            paddingRight: '24px',
          },
          [breakpointsTheme.breakpoints.up('sm')]: {
            paddingLeft: '32px',
            paddingRight: '32px',
          },
          [breakpointsTheme.breakpoints.up('lg')]: {
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingBottom: '40px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          [breakpointsTheme.breakpoints.up('xs')]: {
            width: '324px',
          },
          [breakpointsTheme.breakpoints.up('sm')]: {
            width: '403px',
          },
          [breakpointsTheme.breakpoints.up('lg')]: {
            width: '552px',
          },
          borderRadius: '12px',
          margin: 0,
        },
        paperFullScreen: {
          borderRadius: 0,
          '&&': {
            width: 'auto',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&': {
            minWidth: 'auto',
            ...typography.h4,
            textTransform: 'none',
            color: palette.darkGray,

            padding: '0 4px 12px',
            [breakpointsTheme.breakpoints.up('lg')]: {
              paddingLeft: '8px',
              paddingRight: '8px',
            },
            '&:not(:first-of-type)': {
              marginLeft: '22px',
            },
            minHeight: 'auto',
            '&::after': {
              position: 'absolute',
              content: '""',
              bottom: 0,
              left: 0,
              right: 0,
              height: 2,
              backgroundColor: 'transparent',
            },
            '&.Mui-selected': {
              color: palette.black,
              '&::after': {
                background: palette.resolutionBlue,
              },
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
        root: {
          minHeight: 'auto',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <Box />,
        checkedIcon: <CheckboxSelectedIcon />,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [breakpointsTheme.breakpoints.up('xs')]: {
            paddingBottom: 0,
          },
          [breakpointsTheme.breakpoints.up('sm')]: {
            paddingLeft: '28px',
            paddingRight: '28px',
          },
          [breakpointsTheme.breakpoints.up('md')]: {
            paddingLeft: '48px',
            paddingRight: '48px',
          },
        },
      },
      variants: [
        {
          props: {
            maxWidth: 'md',
          },
          style: {
            [breakpointsTheme.breakpoints.up('md')]: {
              maxWidth: 'initial',
            },
            [breakpointsTheme.breakpoints.up('lg')]: {
              maxWidth: '980px',
              paddingRight: 0,
              paddingLeft: 0,
            },
            [breakpointsTheme.breakpoints.up('xl')]: {
              maxWidth: '1091px',
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.white,
          color: palette.darkGray,
          ...typography.smallTextMedium,
          border: `1px solid ${palette.gallery}`,
          boxShadow: '5px 0px 16px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          padding: '10px 20px',
        },
        arrow: {
          '&:before': {
            border: `1px solid ${palette.gallery}`,
            backgroundColor: palette.white,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8px 16px',
          '&&': {
            paddingRight: '36px',
          },
        },
        icon: {
          color: palette.black,
          right: 8,
          top: 10,
        },
      },
      defaultProps: {
        IconComponent: ChevronIcon,
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: `1px solid ${palette.lightGray}`,
          marginTop: '4px',
          filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',

          '&&': {
            boxShadow: 'none',
            borderRadius: '6px',
            maxHeight: '180px',
          },
        },
        list: {
          padding: 0,
          '& :last-of-type': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.fieldsDefault,
          whiteSpace: 'pre-wrap',
          padding: '8px 16px',
          [breakpointsTheme.breakpoints.up('xl')]: {
            padding: '10px 16px',
          },
          borderColor: palette.lightGray,
          '&:last-of-type': {
            border: 'none',
          },
          '&.Mui-selected': {
            '&&': {
              backgroundColor: 'rgba(250, 202, 8, 0.5)',
              ...typography.fieldsEntered,
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(250, 202, 8, 0.3)',
          },
        },
      },
      defaultProps: {
        divider: true,
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
        },
        grouped: {
          ...typography.h4,
          '&.Mui-selected': {
            ...typography.h4,
            color: palette.black,
            backgroundColor: 'rgba(74, 195, 152, 0.12)',
            '&:hover': {
              backgroundColor: 'rgba(74, 195, 152, 0.12)',
            },
            '&:not(:first-of-type)': {
              borderColor: palette.puertoRico,
            },
            '&:not(:last-of-type)': {
              borderColor: palette.puertoRico,
            },
          },
          color: palette.darkGray,
          letterSpacing: 0,
          textTransform: 'none',
          flexGrow: 1,
          flexBasis: '176px',
          width: 'auto',
          padding: '7px',
          paddingLeft: '12px',
          paddingRight: '12px',
          [breakpointsTheme.breakpoints.up('lg')]: {
            padding: '9px',
          },
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          '&:not(:first-of-type)': {
            marginLeft: '8px',
            borderRadius: '6px',
            border: `1px solid ${palette.lightGray}`,
          },
          '&:not(:last-of-type)': {
            borderRadius: '6px',
            border: `1px solid ${palette.lightGray}`,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          boxShadow: 'none',
          borderTop: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
          boxShadow: 'none',
          background: 'rgba(24, 119, 242, 0.07);',
          borderRadius: '6px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 20px',
          borderRadius: '6px',
          margin: 0,
          '.MuiAccordionSummary-content': {
            margin: '16px 0',
          },
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '24px 0',
          },
          minHeight: '56px',
          height: 'auto',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 20px',
          paddingBottom: '16px',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          ...typography.bodyRegular,
          '& .MuiPaginationItem-root': {
            minWidth: '29px',
            height: '28px',
            p: 0,
            borderRadius: '3px',
            ...typography.bodyRegular,
            [breakpointsTheme.breakpoints.up('xl')]: {
              minWidth: '39px',
              height: '36px',
            },
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: palette.black,
            color: palette.white,
          },
          '& .MuiPagination-ul li:first-of-type': {
            marginRight: '12px',
          },
          '& .MuiPagination-ul li:last-of-type': {
            marginLeft: '12px',
          },
          '& .MuiPagination-ul li:last-of-type .MuiButtonBase-root.Mui-disabled':
            {
              backgroundColor: 'inherit',
              color: 'inherit',
            },
          '& .MuiPagination-ul li:last-of-type .MuiButtonBase-root:not(disabled)':
            {
              backgroundColor: palette.black,
              color: palette.white,
            },
          '& .MuiPaginationItem-previousNext': {
            display: 'flex',
            alignItems: 'center',
            height: '36px',
            borderRadius: '6px',
            [breakpointsTheme.breakpoints.up('xl')]: {
              height: '44px',
            },
          },
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 80,
        thickness: 2,
      },
      styleOverrides: {
        root: {
          color: palette.supernova,
          alignSelf: 'center',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transform: 'translate3d(0,0,0)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '37px',
          height: '20px',
          padding: 0,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '30px',
          border: '1px solid',
          borderColor: palette.lightGray,
          '& .MuiSwitch-track': {
            backgroundColor: palette.white,
          },
          '& .Mui-checked + .MuiSwitch-track': {
            backgroundColor: `${palette.white} !important`,
          },
          '& .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: palette.supernova,
          },
        },
        thumb: {
          width: '17px',
          height: '17px',
          backgroundColor: palette.gray,
          border: '0.71px solid rgba(0, 0, 0, 0.16)',
          boxShadow: 'none',
        },
        switchBase: {
          padding: '0.5px',
          '&.Mui-checked': {
            transform: 'translateX(17px)',
          },
        },
      },
    },
  },
}

const theme = createTheme(guestThemeOptions)

export { theme }
