import { UUID } from 'io-ts-types'
import { defaultApplicationLocale } from 'src/app/locale'
import { get, patch, post } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'

const confirmations = 'auth/confirmations' as const

export type SendResetPasswordLinkInput = {
  email: string
} & Partial<LocaleInput>

export const sendResetPasswordLink = async (
  input: SendResetPasswordLinkInput,
) => {
  const { headers } = setAcceptLanguageHeader(
    input.locale ?? defaultApplicationLocale,
  )

  return post(`${confirmations}/passwords`, {
    query: new URLSearchParams({
      email: input.email.trim(),
    }),
    headers,
  })
}

export type GetConfirmationInput = {
  requestId: UUID
  hash: string
}

export const getConfirmation = async (input: GetConfirmationInput) => {
  return get(`${confirmations}/:requestId`, {
    params: {
      requestId: input.requestId,
    },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}

export type ConfirmInvitationInput = {
  requestId: UUID
  hash: string
  password: string
}

export const confirmInvitation = async (input: ConfirmInvitationInput) => {
  return patch(`${confirmations}/:requestId/invite`, {
    body: {
      newPassword: input.password,
    },
    params: {
      requestId: input.requestId,
    },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}

export type ResetPasswordInput = {
  requestId: UUID
  hash: string
  password: string
}

export const resetPassword = async (input: ResetPasswordInput) => {
  return patch(`${confirmations}/:requestId/passwords`, {
    body: {
      newPassword: input.password,
    },
    params: {
      requestId: input.requestId,
    },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}

export type ConfirmRegistrationInput = {
  requestId: UUID
  hash: string
}

export const confirmRegistration = async (input: ConfirmRegistrationInput) => {
  return patch(`${confirmations}/:requestId/emails`, {
    params: {
      requestId: input.requestId,
    },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}

export type ConfirmPhoneNumberInput = {
  phoneNumber: string
  verificationCode: string
  clientId: string
}

export const confirmPhoneNumber = (input: ConfirmPhoneNumberInput) => {
  return patch(`${confirmations}/sms`, {
    body: {
      phoneNumber: input.phoneNumber,
      verificationCode: input.verificationCode,
      clientId: input.clientId,
    },
  })
}

export type ResendSmsConfirmationInput = {
  phoneNumber: string
} & LocaleInput

export const resendSmsConfirmation = (input: ResendSmsConfirmationInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return post(`${confirmations}/sms/registration`, {
    body: {
      phoneNumber: input.phoneNumber,
    },
    headers,
  })
}

export type ResendEmailConfirmationInput = {
  email: string
} & LocaleInput

export const resendEmailConfirmation = (
  input: ResendEmailConfirmationInput,
) => {
  const query = new URLSearchParams()
  query.set('email', input.email)

  const { headers } = setAcceptLanguageHeader(input.locale)

  return post(`${confirmations}/emails/registration`, {
    query,
    headers,
  })
}
