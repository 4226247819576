import { useEffect, useState } from 'react'
import constate from 'constate'
import { EnumType } from '../types'

export type UserCoordinates = null | {
  longitude: number
  latitude: number
}

export enum UserCoordinatesState {
  initHook = 'initHook',
  loading = 'loading',
  finished = 'finished',
}

const useCoordinates = () => {
  const [userCoordinates, setUserCoordinates] = useState<UserCoordinates>(null)
  const [userCoordinatesStatus, setUserCoordinatesStatus] =
    useState<EnumType<UserCoordinatesState>>('initHook')

  useEffect(() => {
    if (navigator.geolocation) {
      setUserCoordinatesStatus('loading')
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setUserCoordinates({
            latitude: coords.latitude,
            longitude: coords.longitude,
          })
          setUserCoordinatesStatus('finished')
        },
        () => setUserCoordinatesStatus('finished'),
      )
    }
  }, [])

  return {
    userCoordinatesStatus,
    userCoordinates,
  }
}

export const [UserGeolocationProvider, useUserGeolocation] =
  constate(useCoordinates)
