import { Box } from '@mui/material'

type Props = {
  height?: string
  color: string
  dashWidth?: number
  dashSpace?: number
  opacity?: string
  orientation?: 'vertical' | 'horizontal'
}

export const DashedLine = ({
  color,
  dashWidth = 4,
  dashSpace = 4,
  height,
  opacity,
  orientation = 'horizontal',
}: Props) => {
  return (
    <Box
      sx={{
        height: height ?? orientation === 'horizontal' ? '1px' : '100%',
        width: orientation === 'horizontal' ? '100%' : '1px',
        backgroundImage: `repeating-linear-gradient(${
          orientation === 'horizontal' ? 'to right' : 'to bottom'
        }, transparent 0, transparent ${dashSpace}px, ${color} ${dashSpace}px, ${color} ${
          dashSpace + dashWidth
        }px)`,
        opacity,
      }}
    />
  )
}
