import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const GooglePayLogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 76 40">
      <path
        d="M20.0013 0.81H55.2018C65.7546 0.81 74.3921 9.44736 74.3921 20C74.3921 30.5526 65.7546 39.19 55.2018 39.19H20.0013C9.44845 39.19 0.810977 30.5526 0.810977 20C0.810977 9.44736 9.44845 0.81 20.0013 0.81Z"
        fill="white"
        stroke="#3C4043"
        stroke-width="1.62"
      />
      <path
        d="M35.8619 21.4193V27.4693H33.9419V12.5293H39.032C40.322 12.5293 41.422 12.9593 42.322 13.8193C43.242 14.6793 43.702 15.7293 43.702 16.9693C43.702 18.2393 43.242 19.2893 42.322 20.1393C41.432 20.9893 40.332 21.4093 39.032 21.4093H35.8619V21.4193ZM35.8619 14.3693V19.5793H39.072C39.832 19.5793 40.472 19.3193 40.972 18.8093C41.482 18.2993 41.742 17.6793 41.742 16.9793C41.742 16.2893 41.482 15.6793 40.972 15.1693C40.472 14.6393 39.842 14.3793 39.072 14.3793H35.8619V14.3693Z"
        fill="#3C4043"
      />
      <path
        d="M48.7203 16.9092C50.1403 16.9092 51.2604 17.2892 52.0804 18.0492C52.9004 18.8092 53.3104 19.8492 53.3104 21.1692V27.4692H51.4804V26.0492H51.4004C50.6103 27.2192 49.5503 27.7992 48.2303 27.7992C47.1003 27.7992 46.1603 27.4692 45.4003 26.7992C44.6403 26.1292 44.2603 25.2992 44.2603 24.2992C44.2603 23.2392 44.6603 22.3992 45.4603 21.7792C46.2603 21.1492 47.3303 20.8392 48.6603 20.8392C49.8003 20.8392 50.7404 21.0492 51.4704 21.4692V21.0292C51.4704 20.3592 51.2104 19.7992 50.6803 19.3292C50.1503 18.8592 49.5303 18.6292 48.8203 18.6292C47.7503 18.6292 46.9003 19.0792 46.2803 19.9892L44.5903 18.9292C45.5203 17.5792 46.9003 16.9092 48.7203 16.9092ZM46.2403 24.3292C46.2403 24.8292 46.4503 25.2492 46.8803 25.5792C47.3003 25.9092 47.8003 26.0792 48.3703 26.0792C49.1803 26.0792 49.9003 25.7792 50.5303 25.1792C51.1604 24.5792 51.4804 23.8792 51.4804 23.0692C50.8804 22.5992 50.0503 22.3592 48.9803 22.3592C48.2003 22.3592 47.5503 22.5492 47.0303 22.9192C46.5003 23.3092 46.2403 23.7792 46.2403 24.3292Z"
        fill="#3C4043"
      />
      <path
        d="M63.7519 17.2383L57.3518 31.9583H55.3718L57.7518 26.8083L53.5317 17.2383H55.6218L58.6618 24.5783H58.7018L61.6619 17.2383H63.7519Z"
        fill="#3C4043"
      />
      <path
        d="M28.2267 20.1985C28.2267 19.5725 28.1707 18.9735 28.0667 18.3975H20.0186V21.6975L24.6536 21.6985C24.4656 22.7965 23.8606 23.7325 22.9336 24.3565V26.4975H25.6926C27.3037 25.0065 28.2267 22.8025 28.2267 20.1985Z"
        fill="#4285F4"
      />
      <path
        d="M22.9334 24.3546C22.1654 24.8726 21.1764 25.1757 20.0194 25.1757C17.7844 25.1757 15.8883 23.6696 15.2093 21.6396H12.3633V23.8477C13.7733 26.6457 16.6713 28.5656 20.0194 28.5656C22.3334 28.5656 24.2775 27.8047 25.6925 26.4947L22.9334 24.3546Z"
        fill="#34A853"
      />
      <path
        d="M14.9407 20.0042C14.9407 19.4342 15.0357 18.8832 15.2087 18.3652V16.1572H12.3627C11.7797 17.3142 11.4517 18.6202 11.4517 20.0042C11.4517 21.3882 11.7807 22.6942 12.3627 23.8512L15.2087 21.6432C15.0357 21.1252 14.9407 20.5742 14.9407 20.0042Z"
        fill="#FABB05"
      />
      <path
        d="M20.0196 14.8285C21.2827 14.8285 22.4137 15.2635 23.3067 16.1135L25.7517 13.6705C24.2667 12.2875 22.3307 11.4385 20.0196 11.4385C16.6726 11.4385 13.7735 13.3585 12.3635 16.1565L15.2096 18.3645C15.8886 16.3345 17.7846 14.8285 20.0196 14.8285Z"
        fill="#E94235"
      />
    </SvgIcon>
  )
}
