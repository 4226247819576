import { SvgIcon, SvgIconProps } from '@mui/material'

export const PaymentMethodsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M22.6001 4.9217C22.3086 4.57708 21.9004 4.36658 21.4505 4.32901L5.60576 3.006C5.15585 2.96842 4.71842 3.1083 4.37376 3.39979C4.03051 3.69014 3.82032 4.09632 3.78156 4.54399L3.46278 7.53472H2.18991C1.2581 7.53472 0.5 8.29282 0.5 9.22463V19.1431C0.5 20.0749 1.2581 20.833 2.18991 20.833H18.0898C19.0217 20.833 19.7798 20.0749 19.7798 19.1431V17.5342L20.344 17.5813C20.3916 17.5852 20.4389 17.5872 20.4858 17.5872C21.3546 17.5872 22.0951 16.9188 22.1687 16.0378L22.994 6.15374C23.0315 5.70388 22.8917 5.26636 22.6001 4.9217ZM2.18991 8.41362H18.0898C18.537 8.41362 18.9009 8.77744 18.9009 9.22463V10.1284H1.3789V9.22463C1.3789 8.77744 1.74272 8.41362 2.18991 8.41362ZM1.3789 11.0073H18.9009V12.8305H1.3789V11.0073ZM18.0898 19.9541H2.18991C1.74272 19.9541 1.3789 19.5903 1.3789 19.1431V13.7094H18.9009V19.1431C18.9009 19.5903 18.537 19.9541 18.0898 19.9541ZM22.1181 6.08057L21.2928 15.9647C21.2556 16.4103 20.8627 16.7428 20.4171 16.7054L19.7798 16.6522V9.22463C19.7798 8.29282 19.0217 7.53472 18.0898 7.53472H4.34669L4.65602 4.63258C4.65637 4.62924 4.65668 4.62595 4.65695 4.62256C4.69417 4.17692 5.08686 3.84451 5.53264 3.88187L21.3774 5.20488C21.5933 5.2229 21.7893 5.32393 21.9291 5.48934C22.069 5.65475 22.1362 5.86467 22.1181 6.08057Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.6"
    />
  </SvgIcon>
)
