import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { array } from 'io-ts'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { decodeJson, get, patch } from 'src/lib/request'
import { TClcMembership } from './user-clc.codecs'

const clcRoute = 'auth/customer-loyalty-club' as const
const clc = `${clcRoute}/:clcId` as const

type JoinClcInput = {
  clcId: UUID
  email: string
  saveEmail: boolean
} & LocaleInput

export const joinClc = async (input: JoinClcInput) => {
  const query = new URLSearchParams({
    email: input.email,
    saveEmail: String(input.saveEmail),
  })
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await patch(`${clc}/join`, {
      params: {
        clcId: input.clcId,
      },
      query,
      headers,
    }),
  )
}

type LeaveClcInput = {
  clcId: UUID
} & LocaleInput
export const leaveClc = async (input: LeaveClcInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await patch(`${clc}/leave`, {
      params: {
        clcId: input.clcId,
      },
      headers,
    }),
  )
}

export const getMyMemberships = async () => {
  return pipe(
    await get(`${clcRoute}/me`, {}),
    decodeJson(array(TClcMembership)),
  )
}
