import {
  boolean,
  intersection,
  literal,
  number,
  string,
  type,
  TypeOf,
  union,
} from 'io-ts'
import { enumToKeyof, TOptionalString } from 'src/lib/types'
import { TAvailableLanguages } from 'src/app/locale'
import { TImage } from 'src/api/file'

// Place launch status

export enum PlaceLaunchStatus {
  LAUNCHED = 'LAUNCHED',
  NOT_LAUNCHED = 'NOT_LAUNCHED',
  SOON_LAUNCHING = 'SOON_LAUNCHING',
}
const TPlaceLaunchStatus = enumToKeyof(PlaceLaunchStatus)

// Place payload

export enum PlacePayloadType {
  WITH_DISTANCE = 'WITH_DISTANCE',
  WITH_EVENT_COUNT = 'WITH_EVENT_COUNT',
  WITH_EVENT_COUNT_AND_DISTANCE = 'WITH_EVENT_COUNT_AND_DISTANCE',
}

const TPlaceDistance = type({
  payloadType: literal(PlacePayloadType.WITH_DISTANCE),
  distance: number,
})
export type IPlaceDistance = TypeOf<typeof TPlaceDistance>

const TPlaceEventsCount = type({
  payloadType: literal(PlacePayloadType.WITH_EVENT_COUNT),
  eventCount: number,
})
export type IPlaceEventsCount = TypeOf<typeof TPlaceEventsCount>

const TPlaceEventAndDistance = type({
  payloadType: literal(PlacePayloadType.WITH_EVENT_COUNT_AND_DISTANCE),
  distance: number,
  eventCount: number,
})
export type IPlaceEventsCountAndDistance = TypeOf<typeof TPlaceEventAndDistance>

export type IPlaceWithDistance = IPlaceDetails & {
  payload: IPlaceDistance
}
export type IPlaceWithEvents = IPlaceDetails & {
  payload: IPlaceEventsCount
}

// Place schema

export const TPlaceDetails = type({
  id: string,
  name: string,
  description: string,
  city: TOptionalString,
  country: string,
  image: TImage,
  status: TPlaceLaunchStatus,
  placeSlug: string,
  availableLanguages: TAvailableLanguages,
})
export type IPlaceDetails = TypeOf<typeof TPlaceDetails>

export const TPlace = intersection([
  TPlaceDetails,
  type({
    payload: union([TPlaceDistance, TPlaceEventsCount, TPlaceEventAndDistance]),
  }),
])
export type IPlace = TypeOf<typeof TPlace>

// Places select list

export const TPlacesSelectList = type({
  id: string,
  name: string,
  country: string,
})

// Places countries

export const TPlacesCountries = type({
  key: string,
  value: boolean,
})
export type IPlacesCountries = TypeOf<typeof TPlacesCountries>
