import { IconButton, Stack } from '@mui/material'
import { useSession } from 'next-auth/react'
import { ProfileButton } from './components/profile-button'
import { SignInButton } from './components/sign-in-button'
import { MenuIcon } from './icons/menu'

type Props = {
  onMenuClick: () => void
}

export const MobileNavigation = ({ onMenuClick }: Props) => {
  const session = useSession()

  return (
    <Stack direction="row" spacing={{ xs: 1.5, sm: 2 }} alignItems="center">
      {session.status === 'authenticated' && <ProfileButton />}
      {session.status === 'unauthenticated' && <SignInButton />}
      <IconButton sx={{ p: 0 }} onClick={onMenuClick}>
        <MenuIcon sx={{ fontSize: { xs: 32, sm: 40 } }} />
      </IconButton>
    </Stack>
  )
}
