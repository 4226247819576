import { type, string, boolean, TypeOf } from 'io-ts'
import { UUID, optionFromNullable } from 'io-ts-types'
import { TImage } from 'src/api/file'

export const TClcMembership = type({
  clcId: UUID,
  organizationId: UUID,
  organizationName: string,
  colorTheme: optionFromNullable(string),
  logo: TImage,
  name: string,
  description: string,
  joined: boolean,
  organizationSlug: string,
})

export type IClcMembership = TypeOf<typeof TClcMembership>
