import { SvgIcon, SvgIconProps } from '@mui/material'

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <circle
        cx="11"
        cy="11"
        r="6.25"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="transparent"
      />
      <path
        d="M16.5306 15.1858L20.5306 19.1858"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="transparent"
      />
    </SvgIcon>
  )
}
