import { ProfileIcon } from '../icons/profile'
import useTranslation from 'next-translate/useTranslation'
import { NavigationButton } from './navigation-button'
import { Stack } from '@mui/system'
import { useBoolean } from 'src/lib/use-boolean'
import { DialogSignIn } from 'src/modules/sign-in/dialog-sign-in'

type Props = {
  onClick?: () => void
}

export const SignInButton = ({ onClick }: Props) => {
  const { t } = useTranslation()

  const dialogSignIn = useBoolean(false)

  return (
    <>
      {dialogSignIn.isTrue && <DialogSignIn onClose={dialogSignIn.setFalse} />}
      <NavigationButton
        onClick={() => {
          if (onClick) {
            onClick()
          }
          dialogSignIn.setTrue()
        }}
        icon={
          <Stack
            width={{ lg: 32 }}
            height={{ lg: 32 }}
            justifyContent="center"
            alignItems="center"
            pb={{ lg: '5.33px' }}
          >
            <ProfileIcon
              sx={{
                '&&&': { fontSize: { xs: 16, lg: 24 } },
              }}
            />
          </Stack>
        }
        label={t('account')}
      />
    </>
  )
}
