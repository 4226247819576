import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Box,
} from '@mui/material'
import { ApplicationLocale, applicationLocaleToApiLocale } from 'src/app/locale'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useLocale } from 'src/lib/use-locale'
import { CountryFlag } from 'src/components/country-flag'
import { ChevronIcon } from 'src/assets/icons'

const applicationLocales = [ApplicationLocale.en, ApplicationLocale.no]

const countryFlagProportion = 16

export const SelectLanguage = () => {
  const { t } = useTranslation()

  const router = useRouter()
  const { locale } = useLocale()
  const { pathname, asPath, query } = router

  const options = applicationLocales.filter((option) => option !== locale)

  return (
    <FormControl>
      <Select
        value={locale}
        MenuProps={{
          sx: {
            '&&': {
              '.MuiMenu-paper': {
                borderRadius: '0px 0px 6px 6px',
                marginTop: 0,
                backgroundColor: 'primary3.main',
                boxShadow: 'none',
                filter: 'none',
                border: '1px solid',
                borderColor: 'rgba(240, 240, 240, 0.6)',
                borderTop: 'none',
                width: { xs: '203px', lg: '227px', xl: '253px' },
              },
            },
          },
        }}
        sx={{
          backgroundColor: 'transparent',
          width: { xs: '203px', lg: '227px', xl: '253px' },
          '&:hover': {
            background: '#26458E',
          },
          '&& .MuiSelect-select': {
            height: { xs: '34px', lg: '38px', xl: '42px' },
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            px: '10px',
            py: 0,
          },
          '& .MuiSelect-icon': {
            top: 'auto',
            right: '8px',
            color: 'common.white',
          },
          '.MuiSelect-select': {
            border: '1px solid',
            borderColor: 'rgba(240, 240, 240, 0.6);',
            borderRadius: '6px 6px',
          },
          '.MuiSelect-select[aria-expanded="true"]': {
            borderRadius: '6px 6px 0px 0px',
          },
          '& fieldset': {
            border: 'none',
            boxShadow: 'none',
          },
        }}
        onChange={(event) => {
          const selectedLocale = event.target.value
          const date = new Date()
          const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
          date.setTime(date.getTime() + expireMs)
          // eslint-disable-next-line fp/no-mutation, unicorn/no-document-cookie
          document.cookie = `NEXT_LOCALE=${selectedLocale};expires=${date.toUTCString()};path=/`
          router.push({ pathname, query }, asPath, {
            locale: event.target.value,
          })
        }}
        renderValue={() => (
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              sx={{
                '&& div': {
                  width: countryFlagProportion,
                  height: countryFlagProportion,
                },
              }}
            >
              <CountryFlag
                countryCode={applicationLocaleToApiLocale[locale] ?? ''}
                round
                size="sm"
              />
            </Box>
            <Typography variant="h4" color="common.white">
              {t(`languages_${locale}`)}
            </Typography>
          </Stack>
        )}
        IconComponent={(props) => (
          <ChevronIcon sx={{ fontSize: 20 }} {...props} />
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              '&.MuiMenuItem-root': {
                '&&': {
                  px: '10px',
                  py: 0,
                  height: { xs: '33px', lg: '37px', xl: '41px' },
                  minHeight: 'auto',
                },
              },
              '&.Mui-selected': {
                '&&': {
                  backgroundColor: '#26458E',
                },
              },
              '&:hover': {
                backgroundColor: '#26458E',
              },
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                sx={{
                  '&& div': {
                    width: countryFlagProportion,
                    height: countryFlagProportion,
                  },
                }}
              >
                <CountryFlag
                  countryCode={applicationLocaleToApiLocale[option] ?? ''}
                  round
                  size="sm"
                />
              </Box>
              <Typography variant="h4" color="common.white">
                {t(`languages_${option}`)}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
