import { array, literal, string, type, TypeOf, union, boolean } from 'io-ts'
import { DateFromISOString, optionFromNullable, UUID } from 'io-ts-types'
import { enumToKeyof, TOptionalString } from 'src/lib/types'

const TProfileImage = type({ url: string })

export enum UserGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}
const TGender = enumToKeyof(UserGender)
export type IGender = TypeOf<typeof TGender>
export const genderOptions: Array<IGender> = [
  'MALE',
  'FEMALE',
  'PREFER_NOT_TO_SAY',
]

export const TProfile = type({
  userId: UUID,
  fullName: string,
  scopes: array(string),
  createdAt: DateFromISOString,
  profileImage: optionFromNullable(TProfileImage),
  email: optionFromNullable(string),
  phoneNumber: optionFromNullable(string),
  dateOfBirth: optionFromNullable(string),
  gender: optionFromNullable(TGender),
  country: TOptionalString,
  city: TOptionalString,
  address: TOptionalString,
  zipCode: TOptionalString,
  location: optionFromNullable(
    type({
      address: optionFromNullable(string),
      city: string,
      zipCode: string,
      country: string,
    }),
  ),
  placeId: optionFromNullable(string),
})

export type IProfile = TypeOf<typeof TProfile>

export const TContacts = type({
  contactEmail: optionFromNullable(string),
  contactPhone: TOptionalString,
  mailingSubscribed: boolean,
})

const TAuthType = union([
  literal('EMAIL'),
  literal('PHONE'),
  literal('GOOGLE'),
  literal('FACEBOOK'),
  literal('APPLE'),
  literal('VIPPS'),
])
export type IAuthTypes = TypeOf<typeof TAuthType>

const TUserAuth = type({
  authType: TAuthType,
  subject: string,
})

export const TSignInMethod = type({
  userAuths: array(TUserAuth),
  unconfirmedEmail: TOptionalString,
})
