export const ArrowNextIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path
        d="M17.5637 11.4336C17.0021 10.7784 17.0779 9.79201 17.7331 9.23041C18.3883 8.66881 19.3747 8.74469 19.9363 9.39989L17.5637 11.4336ZM19.9363 40.6003C19.3747 41.2555 18.3883 41.3314 17.7331 40.7698C17.0779 40.2082 17.0021 39.2218 17.5637 38.5666L19.9363 40.6003ZM31.25 25.0001L32.4363 23.9832L33.3079 25.0001L32.4363 26.0169L31.25 25.0001ZM19.9363 9.39989L32.4363 23.9832L30.0637 26.0169L17.5637 11.4336L19.9363 9.39989ZM32.4363 26.0169L19.9363 40.6003L17.5637 38.5666L30.0637 23.9832L32.4363 26.0169Z"
        fill="white"
      />
    </svg>
  )
}
