import { string } from 'io-ts'
import { fromNullable } from 'io-ts-types'

export const TOptionalString = fromNullable(string, '')

export const isNonEmptyString = (str: string) => str !== ''

export const mapOptionalStringFieldToPayload = (input?: string) => {
  return input === undefined || input === '' ? null : input.trim()
}

export const mapOptionalFieldToPayload = (input?: unknown) => {
  return input === undefined || input === '' ? null : input
}
