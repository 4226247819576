import { boolean, number, string, type, TypeOf } from 'io-ts'
import { nonEmptyArray, optionFromNullable, UUID } from 'io-ts-types'
import { TSeatMapItemType } from 'src/api/cms'
import { TTicketDay, TVariationType } from 'src/api/trade'
import { TOptionalString, enumToKeyof } from 'src/lib/types'

export enum PurchasedTicketStatus {
  VALID = 'VALID',
  REFUNDED = 'REFUNDED',
  ENTERED = 'ENTERED',
}
const TPurchasedTicketStatus = enumToKeyof(PurchasedTicketStatus)
export type IPurchasedTicketStatus = TypeOf<typeof TPurchasedTicketStatus>

const TCompanionTicketSeat = type({
  section: string,
  row: string,
  number: string,
})
export type ICompanionTicketSeat = TypeOf<typeof TCompanionTicketSeat>

export const TPurchasedTicket = type({
  purchaseId: UUID,
  humanTicketId: string,
  name: string,
  ageLimit: number,
  price: number,
  qrCode: string,
  currency: string,
  status: TPurchasedTicketStatus,
  companionPassIncluded: boolean,
  days: nonEmptyArray(TTicketDay),
  variationType: TVariationType,
  section: TOptionalString,
  row: TOptionalString,
  number: TOptionalString,
  type: optionFromNullable(TSeatMapItemType),
  companionTicketSeat: optionFromNullable(TCompanionTicketSeat),
})
export type IPurchasedTicket = TypeOf<typeof TPurchasedTicket>
