import { array, type, TypeOf } from 'io-ts'
import { enumToKeyof } from 'src/lib/types'
import { TEvent } from '../event/event.codecs'

export enum EventsNearbyType {
  POPULAR = 'POPULAR',
  NEARBY = 'NEARBY',
}

export const TEventsNearby = type({
  type: enumToKeyof(EventsNearbyType),
  content: array(TEvent),
})
export type IEventsNearby = TypeOf<typeof TEventsNearby>
