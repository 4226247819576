import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ArrowMoveToIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78619 19.7291C8.31445 19.3248 8.25982 18.6146 8.66417 18.1428L14.0366 11.875L8.66417 5.60712C8.25982 5.13538 8.31445 4.42517 8.78619 4.02082C9.25793 3.61647 9.96814 3.6711 10.3725 4.14284L17 11.875L10.3725 19.6071C9.96814 20.0789 9.25793 20.1335 8.78619 19.7291Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
