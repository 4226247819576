import { IconButton, useTheme, useMediaQuery } from '@mui/material'
import Link from 'next/link'
import { Profile } from './profile'
import { useQuery } from 'react-query'
import { toNullable } from 'fp-ts/lib/Option'
import { NavigationButton } from './navigation-button'
import { getOwnProfile, qk } from 'src/api'
import { globalAccessToken } from 'src/lib/use-auth'
import { pages } from 'src/app/pages'

type Props = {
  onClick?: () => void
}

export const ProfileButton = ({ onClick }: Props) => {
  const theme = useTheme()
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const $profile = useQuery(qk.auth.user.profile.toKey(), getOwnProfile, {
    enabled: globalAccessToken !== null,
    refetchOnWindowFocus: false,
  })

  if (!$profile.isSuccess) {
    return null
  }

  if (isSmallDesktop) {
    return (
      <Link href={pages['/my-profile/navigation']} legacyBehavior>
        <NavigationButton
          onClick={onClick}
          icon={
            <Profile
              profileImageSrc={toNullable($profile.data.profileImage)?.url}
              name={$profile.data.fullName}
            />
          }
          label={$profile.data.fullName}
        />
      </Link>
    )
  }

  return (
    <Link href={pages['/my-profile/navigation']} legacyBehavior>
      <IconButton sx={{ p: 0 }} onClick={onClick}>
        <Profile
          profileImageSrc={toNullable($profile.data.profileImage)?.url}
          name={$profile.data.fullName}
        />
      </IconButton>
    </Link>
  )
}
