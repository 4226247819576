import { SvgIcon, SvgIconProps } from '@mui/material'

export const SecurityIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_4996_4503)">
      <path
        d="M21.0883 4.8921L12.3602 1.26828C12.1415 1.17752 11.8957 1.17748 11.6771 1.26828L2.94902 4.8921C2.61659 5.03014 2.3999 5.35469 2.3999 5.71463V10.1199C2.3999 16.1856 6.06612 21.6419 11.6821 23.9341C11.8978 24.0222 12.1395 24.0222 12.3552 23.9341C17.9711 21.6419 21.6374 16.1857 21.6374 10.1199V5.71463C21.6374 5.35469 21.4208 5.03014 21.0883 4.8921ZM19.8562 10.1199C19.8562 15.2764 16.828 20.0233 12.0187 22.1422C7.33779 20.0799 4.18115 15.4161 4.18115 10.1199V6.30922L12.0187 3.05514L19.8562 6.30922V10.1199ZM11.0342 13.1219L14.8623 9.29388C15.2101 8.94609 15.774 8.94604 16.1219 9.29388C16.4697 9.64171 16.4696 10.2056 16.1218 10.5534L11.664 15.0112C11.3161 15.3591 10.7522 15.3589 10.4045 15.0112L7.91545 12.5222C7.56762 12.1743 7.56762 11.6104 7.91545 11.2626C8.26329 10.9149 8.82719 10.9148 9.17498 11.2626L11.0342 13.1219Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.285"
      />
    </g>
    <defs>
      <clipPath id="clip0_4996_4503">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
