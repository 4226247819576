import { useCallback, useState } from 'react'
import { useBoolean } from '../use-boolean'
export type UsePreviewOutput<T> = {
  close: () => void
  isOpen: boolean
  open: (preview: T) => void
  value: T | null
}
export const usePreview = <T>(): UsePreviewOutput<T> => {
  const opened = useBoolean(false)
  const openedOpen = opened.setTrue
  const [preview, setPreview] = useState<T | null>(null)
  const open = useCallback(
    (newPreview: T) => {
      openedOpen()
      setPreview(newPreview)
    },
    [openedOpen],
  )
  return {
    close: opened.setFalse,
    isOpen: opened.isTrue,
    open,
    value: preview,
  }
}
