import { Alert, AlertProps } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { forwardRef } from 'react'
import { isClientError } from 'src/lib/request/error'

type ClientErrorProps = AlertProps & {
  error: unknown
  params?: Record<string, Record<string, unknown>>
}

export const ClientError = forwardRef<HTMLDivElement, ClientErrorProps>(
  ({ error, params, ...alertProps }: ClientErrorProps, ref) => {
    const { t } = useTranslation()

    if (isClientError(error)) {
      return (
        <Alert
          variant="outlined"
          severity="error"
          {...alertProps}
          ref={ref}
          sx={{ textAlign: 'left' }}
        >
          {t(`${error.code}`, params ? params[error.code] : undefined)}
        </Alert>
      )
    }

    return null
  },
)
