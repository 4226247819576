import { intersection, literal, string, type, TypeOf, union } from 'io-ts'
import { TImageWithDescription } from 'src/api/file'
import { enumToKeyof } from 'src/lib/types'

export const TMediaImage = intersection([
  TImageWithDescription,
  type({ mediaType: literal('FILE') }),
])
export type IMediaImage = TypeOf<typeof TMediaImage>

export enum ArtistSocialLink {
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  YOUTUBE = 'YOUTUBE',
}
const TSocialLink = type({
  linkType: enumToKeyof(ArtistSocialLink),
  link: string,
  mediaType: literal('LINK'),
})
export type ISocialLink = TypeOf<typeof TSocialLink>

export const TMedia = union([TSocialLink, TMediaImage])
export type IMedia = TypeOf<typeof TMedia>
