import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get } from 'src/lib/request'
import { TSeoResponse } from './seo.codecs'
import { array, string } from 'io-ts'

const cmsSeo = 'cms/seo' as const

export const getSeoEvents = async () => {
  return pipe(
    await get(`${cmsSeo}/events/identifiers`, {}),
    decodeJson(TSeoResponse),
  )
}

export const getSeoPlaces = async () => {
  return pipe(
    await get(`${cmsSeo}/places/identifiers`, {}),
    decodeJson(TSeoResponse),
  )
}

export const getSeoArtists = async () => {
  return pipe(
    await get(`${cmsSeo}/performers/identifiers`, {}),
    decodeJson(TSeoResponse),
  )
}

export const getSeoVenues = async () => {
  return pipe(
    await get(`${cmsSeo}/venues/identifiers`, {}),
    decodeJson(TSeoResponse),
  )
}

export const getSeoOrganizations = async () => {
  return pipe(
    await get('auth/seo/organizations/identifiers', {}),
    decodeJson(array(string)),
  )
}

export const getSeoArticles = async () => {
  return pipe(
    await get('contents/seo/articles/identifiers', {}),
    decodeJson(TSeoResponse),
  )
}
