import { breakpointsTheme } from './breakpoints'

const fallbackFontFamilies = '"Arial", sans-serif'
export const fontFamilyInter = `"Inter", ${fallbackFontFamilies}`

const h1 = {
  fontFamily: fontFamilyInter,
  fontWeight: 900,
  fontSize: '1.625rem',
  lineHeight: '34px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '2rem',
    lineHeight: '38px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '2.375rem',
    lineHeight: '46px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '2.75rem',
    lineHeight: '52px',
  },
} as const

const h2 = {
  fontFamily: fontFamilyInter,
  fontSize: '1.375rem',
  lineHeight: '28px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
    lineHeight: '30px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.75rem',
    lineHeight: '34px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '2rem',
    lineHeight: '38px',
  },
} as const

const h3 = {
  fontFamily: fontFamilyInter,
  fontSize: '1.125rem',
  lineHeight: '24px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1.125rem',
    lineHeight: '24px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.25rem',
    lineHeight: '26px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.375rem',
    lineHeight: '28px',
  },
} as const

const h4 = {
  fontFamily: fontFamilyInter,
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '22px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1rem',
    lineHeight: '22px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
    lineHeight: '24px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
    lineHeight: '26px',
  },
} as const

const tinyText = {
  fontFamily: fontFamilyInter,
  fontWeight: 600,
  fontSize: '0.688rem',
  lineHeight: '15px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '0.688rem',
    lineHeight: '15px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '0.75rem',
    lineHeight: '16px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '0.813rem',
    lineHeight: '16px',
  },
} as const

const smallText = {
  fontFamily: fontFamilyInter,
  fontWeight: 600,
  fontSize: '0.8125rem',
  lineHeight: '18px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '0.813rem',
    lineHeight: '18px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '0.875rem',
    lineHeight: '20px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1rem',
    lineHeight: '22px',
  },
} as const

const quotation = {
  fontFamily: fontFamilyInter,
  fontSize: '1rem',
  lineHeight: '24px',
  fontStyle: 'italic',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1rem',
    lineHeight: '26px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
    lineHeight: '26px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.125rem',
    lineHeight: '26px',
  },
} as const

const extraSmall = {
  fontFamily: fontFamilyInter,
  fontSize: '0.5625rem',
  lineHeight: '13px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '0.5625rem',
    lineHeight: '13px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '0.625rem',
    lineHeight: '14px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '0.688rem',
    lineHeight: '15px',
  },
} as const

//shared fonts
const hints = {
  fontFamily: fontFamilyInter,
  fontSize: '0.688rem',
  lineHeight: 1.5,
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '0.75rem',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '0.875rem',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '0.938rem',
  },
} as const

const body = {
  fontFamily: fontFamilyInter,
  fontSize: '1rem',
  lineHeight: '24px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1rem',
    lineHeight: '24px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
    lineHeight: '26px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.125rem',
    lineHeight: '26px',
  },
} as const

const excerpt = {
  fontFamily: fontFamilyInter,
  fontWeight: 700,
  fontSize: '1.125rem',
  lineHeight: '26px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
    lineHeight: '28px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
    lineHeight: '32px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.75rem',
    lineHeight: '36px',
  },
} as const

const smallButton = {
  fontFamily: fontFamilyInter,
  fontWeight: 700,
  fontSize: '0.75rem',
  lineHeight: '18px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '0.75rem',
    lineHeight: '18px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '0.875rem',
    lineHeight: '20px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '0.875rem',
    lineHeight: '20px',
  },
} as const

const mediumButton = {
  fontFamily: fontFamilyInter,
  fontWeight: 700,
  fontSize: '0.875rem',
  lineHeight: '20px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
    lineHeight: '20px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1rem',
    lineHeight: '22px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.125rem',
    lineHeight: '24px',
  },
} as const

const bigButton = {
  fontFamily: fontFamilyInter,
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '22px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1rem',
    lineHeight: '22px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.25rem',
    lineHeight: '26px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
    lineHeight: '26px',
  },
} as const

const fields = {
  fontFamily: fontFamilyInter,
  fontSize: '1rem',
  lineHeight: '24px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '1rem',
    lineHeight: '24px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
    lineHeight: '26px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '1.125rem',
    lineHeight: '26px',
  },
} as const

const fieldsAdditional = {
  fontFamily: fontFamilyInter,
  fontSize: '0.688rem',
  lineHeight: '15px',
  [breakpointsTheme.breakpoints.up('sm')]: {
    fontSize: '0.688rem',
    lineHeight: '15px',
  },
  [breakpointsTheme.breakpoints.up('lg')]: {
    fontSize: '0.75rem',
    lineHeight: '16px',
  },
  [breakpointsTheme.breakpoints.up('xl')]: {
    fontSize: '0.813rem',
    lineHeight: '16px',
  },
} as const

export const typography = {
  h1,
  h2ExtraBold: {
    ...h2,
    fontWeight: 800,
  },
  h2Black: {
    ...h2,
    fontWeight: 900,
  },
  h3Bold: {
    ...h3,
    fontWeight: 700,
  },
  h3ExtraBold: {
    ...h3,
    fontWeight: 800,
  },
  h3Black: {
    ...h3,
    fontWeight: 900,
  },
  h4,
  excerpt,
  tinyTextRegular: {
    ...tinyText,
    fontWeight: 400,
  },
  tinyTextMedium: {
    ...tinyText,
    fontWeight: 500,
  },
  tinyTextSemiBold: {
    ...tinyText,
    fontWeight: 600,
  },
  tinyTextBold: {
    ...tinyText,
    fontWeight: 700,
  },
  tinyTextExtraBold: {
    ...tinyText,
    fontWeight: 800,
  },
  smallTextRegular: {
    ...smallText,
    fontWeight: 400,
  },
  smallTextMedium: {
    ...smallText,
    fontWeight: 500,
  },
  smallTextSemiBold: {
    ...smallText,
    fontWeight: 600,
  },
  smallTextBold: {
    ...smallText,
    fontWeight: 700,
  },
  quotationRegular: {
    ...quotation,
    fontWeight: 400,
  },
  quotationBold: {
    ...quotation,
    fontWeight: 700,
  },
  extraSmallRegular: {
    ...extraSmall,
    fontWeight: 400,
  },
  extraSmallSemiBold: {
    ...extraSmall,
    fontWeight: 600,
  },
  //shared fonts
  hintsMedium: {
    ...hints,
    fontWeight: 500,
  },
  bodyRegular: {
    ...body,
    fontWeight: 400,
  },
  bodyMedium: {
    ...body,
    fontWeight: 500,
  },
  bodySemiBold: {
    ...body,
    fontWeight: 600,
  },
  bodyBold: {
    ...body,
    fontWeight: 700,
  },
  smallButton,
  mediumButton,
  bigButton,
  fieldsTitle: {
    ...fieldsAdditional,
    fontWeight: 600,
  },
  fieldsDefault: {
    ...fields,
    fontWeight: 400,
  },
  fieldsEntered: {
    ...fields,
    fontWeight: 600,
  },
  fieldsHint: {
    ...fieldsAdditional,
    fontWeight: 400,
  },
} as const
