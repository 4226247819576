import { ReactNode } from 'react'
import { useTheme, Button, Typography, useMediaQuery } from '@mui/material'

type NavigationButtonProps = {
  label: string
  icon: ReactNode
  onClick?: () => void
}

export const NavigationButton = ({
  label,
  icon,
  onClick,
}: NavigationButtonProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Button
      onClick={onClick}
      endIcon={icon}
      sx={{
        borderRadius: { xs: '30px', lg: '60px' },
        color: 'common.white',
        border: '1px solid',
        borderColor: { xs: 'common.white', lg: 'transparent' },
        px: { xs: 2.125, lg: 3.875 },
        '&&': {
          height: { xs: 34, lg: 48 },
        },
        '&:hover': {
          borderColor: 'common.white',
          background: 'none',
        },
      }}
    >
      <Typography variant={isDesktop ? 'h4' : 'smallTextSemiBold'}>
        {label}
      </Typography>
    </Button>
  )
}
