import { pipe } from 'fp-ts/lib/function'
import { decodeText, get, post } from 'src/lib/request'
import { EnumType } from 'src/lib/types'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'

const socials = 'auth/socials' as const
const providers = `${socials}/providers` as const

export enum SocialProviderType {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
  VIPPS = 'VIPPS',
}

type GetSocialProviderLinkInput = {
  providerType: EnumType<SocialProviderType>
}

export const getSocialProviderLink = async (
  input: GetSocialProviderLinkInput,
) => {
  return pipe(await get(`${providers}/${input.providerType}`, {}), decodeText)
}

type SignInSocialInput = {
  client: string
  type: string
  token?: string
  fullName?: string
  code?: string
} & LocaleInput

export const signInSocial = async (input: SignInSocialInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return post(`${socials}/register`, {
    body: {
      client: input.client,
      type: input.type,
      token: input.token,
      fullName: input.fullName,
      code: input.code,
    },
    headers,
  })
}
