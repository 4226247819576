import { string, array, type, boolean, TypeOf } from 'io-ts'
import { UUID } from 'io-ts-types'
import { TImage } from 'src/api/file'
import { enumToKeyof } from 'src/lib/types'

export enum FavoriteCategory {
  EVENT = 'EVENT',
  PERFORMER = 'PERFORMER',
}

const TFavoriteCategory = enumToKeyof(FavoriteCategory)

export const TFavoriteCategories = array(TFavoriteCategory)

export const TFavoriteArtist = type({
  countryCode: string,
  name: string,
  performerId: UUID,
  coverImage: TImage,
  favourite: boolean,
  performerSlug: string,
})

export type IFavoriteCategories = TypeOf<typeof TFavoriteCategories>
export type IFavoriteCategory = TypeOf<typeof TFavoriteCategory>
export type IFavoriteArtist = TypeOf<typeof TFavoriteArtist>
