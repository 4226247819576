import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Dot = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.54222 15.3124C2.89328 20.2769 7.13972 23.9923 12.0868 24C17.5039 24.0084 21.9961 19.5695 22.7808 14.844C24.1091 6.84509 14.8315 -2.06351 7.98165 0.425203C3.41934 2.08284 -0.215787 8.85255 1.54222 15.3124Z"
      fill="currentColor"
    />
  </SvgIcon>
)
