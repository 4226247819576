import { Box } from '@mui/material'
import { RedCurveLine } from './decor/red-curve-line'
import { Dot } from './decor/dot'
import { GreenCloud } from './decor/green-cloud'
import { Clouds } from './decor/clouds'

export const MenuElements = () => {
  const cloudsElementBottomPosition = {
    bottom: -10,
    '@media screen and (max-height: 1010px)': {
      display: { sm: 'none', md: 'block' },
    },
    '@media screen and (max-height: 860px)': {
      display: { xs: 'none', md: 'block' },
    },
    '@media screen and (max-height: 680px)': {
      bottom: { lg: -60, xl: -80 },
    },
  }

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 256, sm: 276, lg: 175, xl: 189 },
          right: { xs: 17, sm: 46 },
          left: { lg: 51, xl: 66 },
          display: { md: 'none', lg: 'block' },
          color: 'secondary1.main',
        }}
      >
        <Dot sx={{ fontSize: { xs: 10, sm: 14 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: { sm: 314, lg: 456, xl: 734 },
          bottom: { sm: 135, lg: 32, xl: 117 },
          display: { xs: 'none', sm: 'block', md: 'none', lg: 'block' },
          color: 'secondary2.main',
        }}
      >
        <Dot sx={{ fontSize: { sm: 12, md: 14 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: { xs: 64, sm: 120, md: 180, lg: '50%' },
          top: { xs: 471, sm: 667, md: 'unset' },
          bottom: { md: 60, lg: '50%' },
          color: 'secondary3.main',
        }}
      >
        <Dot sx={{ fontSize: { xs: 8, sm: 12, lg: 14 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: { lg: 82, xl: 103 },
          bottom: { lg: 207, xl: 228 },
          display: { xs: 'none', lg: 'block' },
          color: 'primary2.main',
        }}
      >
        <Dot sx={{ fontSize: { lg: 14 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: { xs: 26, sm: 53, lg: 70, xl: 133 },
          left: { md: 31, lg: 'unset' },
          top: { xs: 673, sm: 'unset', md: 171, lg: 'unset' },
          bottom: { sm: 44, lg: 71, xl: 119 },
          color: 'accent1.main',
          '@media screen and (max-height: 700px)': {
            display: { xs: 'none', sm: 'block' },
          },
        }}
      >
        <Dot sx={{ fontSize: { xs: 6, sm: 12, md: 14 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: { md: 169 },
          left: { md: 280 },
          display: { xs: 'none', md: 'block', lg: 'none' },
          color: 'accent1.main',
        }}
      >
        <Dot sx={{ fontSize: 12 }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { md: 303, lg: 314, xl: 341 },
          right: { md: '50%', lg: 22, xl: 46 },
          display: { xs: 'none', md: 'block' },
          color: 'accent2.main',
        }}
      >
        <Dot sx={{ fontSize: { md: 10, lg: 14 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          ...cloudsElementBottomPosition,
          left: { xs: -20, lg: -80 },
        }}
      >
        <Clouds
          sx={{
            width: { xs: 325, sm: 395, md: 535, xl: 680 },
            height: 'auto',
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 111, sm: 157, lg: 134, xl: 130 },
          right: { xs: -15, sm: -8, md: -25, lg: -1 },
        }}
      >
        <RedCurveLine
          sx={{ height: { xs: 48, sm: 64, xl: 91 }, width: 'auto' }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          display: { xs: 'none', xl: 'block' },
          bottom: -10,
          right: 0,
        }}
      >
        <GreenCloud />
      </Box>
    </>
  )
}
