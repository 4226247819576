import { useMediaQuery, useTheme } from '@mui/material'
import { palette } from 'src/app/theme/palette'
import { fontFamilyInter, typography } from 'src/app/theme/typography'
import { getTypographyProperty } from './get-typography-property'
import { useCurrentBreakpoint } from 'src/lib/use-current-breakpoint'

export const usePaymentElementsAppearance = () => {
  const theme = useTheme()
  const isBigDesktop = useMediaQuery(theme.breakpoints.up('xl'))

  const inputVerticalPadding = isBigDesktop ? '9px' : '7px'

  const currentBreakpoint = useCurrentBreakpoint()

  return {
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap',
      },
    ],
    appearance: {
      variables: {
        fontFamily: fontFamilyInter,
        borderRadius: '6px',
        colorDanger: palette.carnation,
        spacingGridRow: '16px',
        spacingGridColumn: '10px',
        spacingTab: '8px',
        colorIconTab: palette.black,
        colorIconTabSelected: palette.black,
      },
      rules: {
        '.Label': {
          fontWeight: String(typography.fieldsTitle.fontWeight),
          fontSize: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'fontSize',
            typographyVariant: 'fieldsTitle',
          }),
          lineHeight: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'lineHeight',
            typographyVariant: 'fieldsTitle',
          }),
          color: palette.wetAsphalt,
          marginBottom: '6px',
        },
        '.Input': {
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          border: `1px solid ${palette.gallery}`,
          paddingTop: inputVerticalPadding,
          paddingBottom: inputVerticalPadding,
          paddingLeft: '16px',
          paddingRight: '16px',
          fontWeight: String(typography.fieldsEntered.fontWeight),
          fontSize: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'fontSize',
            typographyVariant: 'fieldsEntered',
          }),
          lineHeight: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'lineHeight',
            typographyVariant: 'fieldsEntered',
          }),
        },
        '.Input:focus': {
          borderColor: palette.gallery,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        },
        '.Input::placeholder': {
          color: palette.darkGray,
          fontWeight: String(typography.fieldsDefault.fontWeight),
        },
        '.Input--invalid': {
          color: palette.black,
          borderColor: palette.gallery,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        },
        '.Error': {
          fontWeight: String(typography.fieldsHint.fontWeight),
          fontSize: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'fontSize',
            typographyVariant: 'fieldsHint',
          }),
          lineHeight: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'lineHeight',
            typographyVariant: 'fieldsHint',
          }),
        },
        '.TabLabel': {
          fontWeight: String(typography.smallTextSemiBold.fontWeight),
          fontSize: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'fontSize',
            typographyVariant: 'smallTextSemiBold',
          }),
          lineHeight: getTypographyProperty({
            currentBreakpoint,
            fontProperty: 'lineHeight',
            typographyVariant: 'smallTextSemiBold',
          }),
          color: palette.black,
        },
        '.Tab': {
          border: `2px solid ${palette.lightGray}`,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)',
        },
        '.Tab--selected': {
          border: `2px solid ${palette.puertoRico}`,
          backgroundColor: 'rgba(74, 195, 152, 0.12)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)',
        },
        '.Tab--selected:focus': {
          border: `2px solid ${palette.puertoRico}`,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)',
        },
      },
    },
  }
}
