import { SvgIcon, SvgIconProps } from '@mui/material'

export const ShareIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9324 4.54845C19.8881 4.44147 19.8231 4.34423 19.7413 4.26222L19.7378 4.25867C19.5714 4.0928 19.346 3.99976 19.1111 4H13.7778C13.542 4 13.3159 4.09365 13.1492 4.26035C12.9825 4.42705 12.8889 4.65314 12.8889 4.88889C12.8889 5.12464 12.9825 5.35073 13.1492 5.51743C13.3159 5.68413 13.542 5.77778 13.7778 5.77778H16.9653L10.4827 12.2604C10.3978 12.3424 10.3301 12.4405 10.2835 12.549C10.2369 12.6574 10.2124 12.7741 10.2113 12.8921C10.2103 13.0101 10.2328 13.1272 10.2775 13.2364C10.3222 13.3456 10.3882 13.4449 10.4716 13.5284C10.5551 13.6118 10.6544 13.6778 10.7636 13.7225C10.8728 13.7672 10.9899 13.7897 11.1079 13.7887C11.2259 13.7876 11.3426 13.7631 11.451 13.7165C11.5595 13.67 11.6576 13.6022 11.7396 13.5173L18.2222 7.03467V10.2222C18.2222 10.458 18.3159 10.6841 18.4826 10.8508C18.6493 11.0175 18.8754 11.1111 19.1111 11.1111C19.3469 11.1111 19.573 11.0175 19.7397 10.8508C19.9064 10.6841 20 10.458 20 10.2222V4.88889V4.88622C20 4.77067 19.9769 4.65689 19.9324 4.54845ZM4 8.44444C4 7.2657 4.46825 6.13524 5.30175 5.30175C6.13524 4.46825 7.2657 4 8.44444 4H9.33333C9.56908 4 9.79517 4.09365 9.96187 4.26035C10.1286 4.42705 10.2222 4.65314 10.2222 4.88889C10.2222 5.12464 10.1286 5.35073 9.96187 5.51743C9.79517 5.68413 9.56908 5.77778 9.33333 5.77778H8.44444C7.7372 5.77778 7.05892 6.05873 6.55883 6.55883C6.05873 7.05892 5.77778 7.7372 5.77778 8.44444V15.5556C5.77778 16.2628 6.05873 16.9411 6.55883 17.4412C7.05892 17.9413 7.7372 18.2222 8.44444 18.2222H15.5556C16.2628 18.2222 16.9411 17.9413 17.4412 17.4412C17.9413 16.9411 18.2222 16.2628 18.2222 15.5556V14.6667C18.2222 14.4309 18.3159 14.2048 18.4826 14.0381C18.6493 13.8714 18.8754 13.7778 19.1111 13.7778C19.3469 13.7778 19.573 13.8714 19.7397 14.0381C19.9064 14.2048 20 14.4309 20 14.6667V15.5556C20 16.7343 19.5317 17.8648 18.6983 18.6983C17.8648 19.5317 16.7343 20 15.5556 20H8.44444C7.2657 20 6.13524 19.5317 5.30175 18.6983C4.46825 17.8648 4 16.7343 4 15.5556V8.44444Z"
      fill="white"
    />
  </SvgIcon>
)
