import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const VippsLogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 53 36">
      <g clipPath="url(#clip0_13965_29635)">
        <rect y="0.499634" width="53" height="35" rx="3.82812" fill="#FF5B24" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.2263 14.4116C31.7324 14.4116 33.0231 13.2682 33.0231 11.6248H33.0235C33.0235 9.9809 31.7324 8.83789 30.2263 8.83789C28.7205 8.83789 27.4302 9.9809 27.4302 11.6248C27.4302 13.2682 28.7205 14.4116 30.2263 14.4116ZM33.8835 18.9145C32.0186 21.3437 30.0467 23.0232 26.5686 23.0232H26.569C23.0205 23.0232 20.2591 20.8793 18.108 17.7351C17.2472 16.4485 15.9206 16.1629 14.9524 16.8418C14.0562 17.4851 13.8416 18.8429 14.6657 20.0223C17.6414 24.5603 21.7644 27.2039 26.5686 27.2039C30.9791 27.2039 34.4213 25.0604 37.1099 21.487C38.1136 20.1651 38.0778 18.8073 37.1099 18.0568C36.2133 17.3415 34.8868 17.5927 33.8835 18.9145Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13965_29635">
          <rect
            width="52.5"
            height="35"
            fill="white"
            transform="translate(0 0.499634)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
