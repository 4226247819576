import { makeRoutes, toPathsObject } from 'src/lib/route-generator'

const paths = [
  '/email-sent/password',
  '/',
  '/favorites',
  '/account-not-confirmed',
  '/sign-in',
  '/sign-in/email',
  '/sign-in/phone/add',
  '/reset-password',
  '/events',
  '/events/[eventSlug]',
  '/events/[eventSlug]/checkout',
  '/events/[eventSlug]/checkout/seat-map',
  '/events/[eventSlug]/checkout/confirm',
  '/venues',
  '/venues/[venueSlug]/events',
  '/venues/[venueSlug]',
  '/artists',
  '/artists/[artistSlug]',
  '/my-profile',
  '/my-profile/navigation',
  '/contacts',
  '/security',
  '/tickets',
  '/tickets/[eventSlug]',
  '/payment-methods',
  '/payment-methods/new/confirm',
  '/articles/[articleSlug]',
  '/places',
  '/places/[placeSlug]',
  '/organizations',
  '/organizations/[organizationSlug]',
  '/organizations/[organizationSlug]/events',
  '/organizations/[organizationSlug]/clc',
  '/memberships',
  '/purchase-policy',
  '/terms-of-use',
  '/help-and-contact',
  '/privacy-policy',
  '/about-fabnite',
  '/mobile-app/purchase-policy',
  '/mobile-app/terms-of-use',
  '/mobile-app/privacy-policy',
  '/notifications/settings',
] as const

export const pathsObject = toPathsObject(paths)

export const pages = makeRoutes<typeof pathsObject, string>(pathsObject)
