import { pipe } from 'fp-ts/lib/function'
import { decodeJson, decodeText, get, post } from 'src/lib/request'
import { TUploadFileResponse } from './file.codecs'
import { UUID } from 'io-ts-types'

export type FileType = 'IMAGE' | 'AUDIO' | 'VIDEO'

export type UploadFileInput = {
  file: File
  type: FileType
}

export const uploadFile = async (input: UploadFileInput) => {
  return pipe(
    await post('files', {
      body: {
        file: input.file,
      },
      type: 'file',
    }),
    decodeJson(TUploadFileResponse),
  )
}

export type GetSeatMapFileInput = {
  fileKey: UUID
}

export const getSeatMapFile = async (input: GetSeatMapFileInput) => {
  return pipe(
    await get(`files/maps/:fileKey/download`, {
      type: 'file',
      params: { fileKey: input.fileKey },
    }),
    decodeText,
  )
}
