import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { decodeJson, get } from 'src/lib/request'
import { EnumType } from 'src/lib/types'
import { TMedia } from './media.codecs'

export enum MediaReferenceType {
  EVENT = 'EVENT',
  VENUE = 'VENUE',
  PERFORMER = 'PERFORMER',
}

export type GetMediaInput = {
  slug: string
  referenceType: EnumType<MediaReferenceType>
}

export const getMedia = async (input: GetMediaInput) => {
  const query = new URLSearchParams({
    referenceType: input.referenceType,
  })

  return pipe(
    await get(`cms/medias/${input.slug}`, {
      query,
    }),
    decodeJson(array(TMedia)),
  )
}
