import { SvgIcon, SvgIconProps } from '@mui/material'

export const SignOutIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M10.8984 9.4374C12.9522 9.4374 14.6171 7.77248 14.6171 5.7187C14.6171 3.66492 12.9522 2 10.8984 2C8.8446 2 7.17969 3.66492 7.17969 5.7187C7.17969 7.77248 8.8446 9.4374 10.8984 9.4374Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M20.9922 17.4019L15.6798 17.4019"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8672 19.5385L20.9922 17.4135L18.8672 15.2886"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1483 13.6919H10.7921C8.41191 13.6919 7.22182 13.6919 6.31271 14.1551C5.51302 14.5625 4.86286 15.2127 4.4554 16.0124C3.99219 16.9215 3.99219 18.1116 3.99219 20.4918V21.1293H15.1483"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)
