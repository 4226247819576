import { isRight } from 'fp-ts/lib/Either'
import jwtDecode from 'jwt-decode'
import { NextApiResponse } from 'next'
import { TAuthResult } from 'src/api'
import { decodeJson } from 'src/lib/request'
import { TAccessToken } from 'src/lib/use-auth'

type DecodeTokenAndSetCookiesInput = {
  requestResponse: Response
  responseObject: NextApiResponse
}

export const decodeTokenAndSetCookies = async ({
  requestResponse,
  responseObject,
}: DecodeTokenAndSetCookiesInput) => {
  const cookies = requestResponse.headers.get('set-cookie')
  if (cookies) {
    responseObject.setHeader('Set-Cookie', cookies)
  }
  return await decodeJson(TAuthResult)(requestResponse)
}

export const getNextAuthToken = async ({
  requestResponse,
  responseObject,
}: DecodeTokenAndSetCookiesInput) => {
  const decodedResponse = await decodeTokenAndSetCookies({
    responseObject,
    requestResponse,
  })
  const decodedToken = TAccessToken.decode(
    jwtDecode(decodedResponse.accessToken),
  )
  if (isRight(decodedToken)) {
    return {
      accessToken: decodedResponse.accessToken,
      accessTokenExpiry: decodedToken.right.exp,
      accessTokenLifeTime: decodedResponse.accessTokenLifeTime,
    }
  }
  return null
}

export const getCookie = ({
  name,
  cookie,
}: {
  name: string
  cookie: string
}): string | null => {
  const value = `; ${cookie}`
  const parts = value.split(`; ${name}=`)
  // eslint-disable-next-line fp/no-mutating-methods
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null
  return null
}
