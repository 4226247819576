export const AppStoreNoIcon = () => {
  return (
    <svg width="135" height="40" viewBox="0 0 135 40" fill="none">
      <g clipPath="url(#clip0_11825_176836)">
        <path
          d="M124.249 -0.000724492H10.7566C10.3429 -0.000724492 9.9342 -0.000724492 9.5216 0.00127551C9.17621 0.00327551 8.83358 0.00908551 8.48488 0.0139755C7.72733 0.0218773 6.97157 0.0809518 6.22416 0.190686C5.4778 0.302808 4.75482 0.514191 4.07967 0.817685C3.40534 1.12375 2.7892 1.52144 2.25358 1.99635C1.71514 2.46991 1.26632 3.01729 0.924356 3.61744C0.581453 4.21638 0.343683 4.85821 0.219258 5.52076C0.0936495 6.18246 0.0260586 6.8518 0.0170916 7.52276C0.00662229 7.82936 0.00551669 8.13698 0 8.44361V31.5579C0.00551669 31.8684 0.00662229 32.1692 0.0170916 32.4798C0.0260614 33.1507 0.0936522 33.82 0.219258 34.4817C0.343339 35.1446 0.581124 35.7868 0.924356 36.386C1.26616 36.9842 1.71505 37.5293 2.25358 38.0003C2.78717 38.4773 3.40371 38.8753 4.07967 39.179C4.75481 39.4833 5.47772 39.6959 6.22416 39.8098C6.9717 39.9186 7.72738 39.9777 8.48488 39.9866C8.83358 39.9934 9.17621 39.9973 9.5216 39.9973C9.93419 39.9993 10.343 39.9993 10.7566 39.9993H124.249C124.655 39.9993 125.067 39.9993 125.472 39.9973C125.816 39.9973 126.169 39.9934 126.512 39.9866C127.268 39.9782 128.023 39.9191 128.769 39.8098C129.518 39.6951 130.243 39.4825 130.921 39.179C131.597 38.8751 132.213 38.4771 132.746 38.0003C133.283 37.5275 133.733 36.9827 134.079 36.386C134.42 35.7864 134.655 35.1442 134.777 34.4817C134.903 33.8199 134.973 33.1508 134.987 32.4798C134.991 32.1692 134.991 31.8684 134.991 31.5579C135 31.1946 135 30.8333 135 30.4641V9.53541C135 9.1692 135 8.80591 134.991 8.44361C134.991 8.13698 134.991 7.82936 134.987 7.52272C134.973 6.8517 134.903 6.18251 134.777 5.52072C134.655 4.85856 134.419 4.21678 134.079 3.6174C133.383 2.41447 132.278 1.4353 130.921 0.817595C130.243 0.514843 129.518 0.303516 128.769 0.190596C128.023 0.0803783 127.269 0.0212833 126.512 0.0138355C126.169 0.00895551 125.816 0.00309551 125.472 0.00114551C125.067 -0.000854492 124.655 -0.000724492 124.249 -0.000724492Z"
          fill="#A6A6A6"
        />
        <path
          d="M9.52707 39.1241C9.18335 39.1241 8.84792 39.1202 8.50689 39.1134C7.80042 39.1053 7.0956 39.0508 6.39821 38.9503C5.74792 38.8511 5.11798 38.6664 4.52914 38.4024C3.94571 38.1407 3.41356 37.7974 2.9531 37.3858C2.48598 36.9791 2.09708 36.5074 1.80181 35.9893C1.50328 35.4679 1.29669 34.909 1.18922 34.3321C1.07316 33.7123 1.01036 33.0855 1.00138 32.4571C0.994227 32.2462 0.984863 31.544 0.984863 31.544V8.44349C0.984863 8.44349 0.994836 7.75208 1.00144 7.54895C1.01004 6.92163 1.07247 6.2958 1.18818 5.67688C1.29585 5.0984 1.50261 4.53789 1.80129 4.01478C2.09547 3.49709 2.48222 3.02501 2.94653 2.61683C3.41032 2.20477 3.94416 1.85975 4.52859 1.59436C5.11607 1.33124 5.74483 1.14788 6.3938 1.05042C7.09348 0.948982 7.80075 0.894142 8.50967 0.886356L9.52763 0.874146H125.467L126.497 0.886846C127.199 0.894245 127.9 0.948596 128.593 1.04944C129.249 1.14812 129.884 1.33276 130.479 1.59729C131.649 2.13213 132.602 2.97831 133.203 4.01722C133.497 4.53672 133.701 5.09265 133.807 5.66614C133.924 6.29014 133.989 6.92088 134.003 7.55335C134.006 7.83655 134.006 8.14075 134.006 8.44349C134.015 8.81849 134.015 9.17542 134.015 9.53528V30.4639C134.015 30.8272 134.015 31.1817 134.006 31.5391C134.006 31.8643 134.006 32.1622 134.002 32.4688C133.989 33.09 133.924 33.7095 133.809 34.3223C133.704 34.9034 133.498 35.4667 133.2 35.9923C132.902 36.5048 132.516 36.9724 132.054 37.378C131.593 37.7918 131.06 38.1371 130.475 38.4005C129.883 38.6665 129.248 38.8519 128.593 38.9503C127.896 39.0513 127.191 39.1058 126.485 39.1134C126.154 39.1202 125.808 39.1241 125.472 39.1241L124.249 39.1261L9.52707 39.1241Z"
          fill="black"
        />
        <path
          d="M24.769 20.2998C24.7797 19.4652 25.0014 18.6468 25.4135 17.9208C25.8255 17.1949 26.4145 16.585 27.1256 16.1478C26.6738 15.5026 26.0779 14.9717 25.385 14.5971C24.6922 14.2226 23.9216 14.0147 23.1344 13.9901C21.4552 13.8138 19.8272 14.9949 18.9715 14.9949C18.0992 14.9949 16.7817 14.0076 15.363 14.0368C14.4453 14.0664 13.551 14.3333 12.7671 14.8114C11.9832 15.2894 11.3365 15.9624 10.8901 16.7647C8.95607 20.1131 10.3987 25.0341 12.2513 27.7407C13.1782 29.0661 14.2615 30.5465 15.6789 30.494C17.066 30.4365 17.584 29.6096 19.2583 29.6096C20.9171 29.6096 21.4031 30.494 22.8493 30.4607C24.3377 30.4365 25.2754 29.1294 26.1698 27.7915C26.8358 26.8472 27.3483 25.8034 27.6882 24.699C26.8235 24.3333 26.0856 23.7211 25.5665 22.9388C25.0474 22.1565 24.77 21.2387 24.769 20.2998Z"
          fill="white"
        />
        <path
          d="M22.0376 12.21C22.8491 11.2358 23.2489 9.98365 23.1521 8.71942C21.9123 8.84964 20.767 9.4422 19.9445 10.379C19.5424 10.8367 19.2344 11.3691 19.0381 11.9459C18.8419 12.5227 18.7612 13.1325 18.8008 13.7404C19.4209 13.7468 20.0344 13.6124 20.5951 13.3473C21.1558 13.0822 21.649 12.6934 22.0376 12.21Z"
          fill="white"
        />
        <path
          d="M42.3022 27.1388H37.5688L36.4321 30.4952H34.4272L38.9106 18.0772H40.9936L45.477 30.4952H43.438L42.3022 27.1388ZM38.0591 25.59H41.8111L39.9615 20.1427H39.9097L38.0591 25.59Z"
          fill="white"
        />
        <path
          d="M55.1597 25.9689C55.1597 28.7824 53.6538 30.59 51.3814 30.59C50.8057 30.6201 50.2332 30.4875 49.7294 30.2073C49.2256 29.9272 48.8109 29.5108 48.5327 29.006H48.4897V33.4903H46.6313V21.4415H48.4302V22.9474H48.4644C48.7553 22.4449 49.1771 22.0307 49.6847 21.7489C50.1923 21.467 50.7669 21.3281 51.3472 21.3468C53.645 21.3468 55.1597 23.1632 55.1597 25.9689ZM53.2495 25.9689C53.2495 24.1359 52.3023 22.9308 50.857 22.9308C49.437 22.9308 48.482 24.1613 48.482 25.9689C48.482 27.7931 49.437 29.0148 50.857 29.0148C52.3023 29.0148 53.2495 27.8185 53.2495 25.9689Z"
          fill="white"
        />
        <path
          d="M65.1245 25.9689C65.1245 28.7823 63.6187 30.59 61.3462 30.59C60.7706 30.6201 60.1981 30.4875 59.6943 30.2073C59.1905 29.9272 58.7758 29.5108 58.4976 29.006H58.4546V33.4903H56.5962V21.4415H58.395V22.9474H58.4292C58.7201 22.4449 59.1419 22.0307 59.6495 21.7489C60.1571 21.467 60.7317 21.3281 61.312 21.3468C63.6099 21.3468 65.1245 23.1632 65.1245 25.9689ZM63.2144 25.9689C63.2144 24.1359 62.2671 22.9308 60.8218 22.9308C59.4019 22.9308 58.4468 24.1612 58.4468 25.9689C58.4468 27.7931 59.4019 29.0148 60.8218 29.0148C62.2671 29.0148 63.2144 27.8185 63.2144 25.9689Z"
          fill="white"
        />
        <path
          d="M71.7105 27.0353C71.8482 28.2667 73.0445 29.0753 74.6792 29.0753C76.2456 29.0753 77.3726 28.2667 77.3726 27.1563C77.3726 26.1924 76.6929 25.6153 75.0835 25.2198L73.4742 24.8321C71.1939 24.2813 70.1353 23.2149 70.1353 21.4844C70.1353 19.3419 72.0025 17.8702 74.6538 17.8702C77.2778 17.8702 79.0767 19.3419 79.1372 21.4844H77.2612C77.1489 20.2452 76.1245 19.4971 74.6274 19.4971C73.1304 19.4971 72.106 20.254 72.106 21.3555C72.106 22.2335 72.7603 22.7501 74.3608 23.1455L75.729 23.4815C78.2769 24.084 79.3355 25.1075 79.3355 26.9239C79.3355 29.2471 77.4849 30.7022 74.5415 30.7022C71.7876 30.7022 69.9282 29.2813 69.8081 27.0352L71.7105 27.0353Z"
          fill="white"
        />
        <path
          d="M83.3462 19.299V21.4415H85.0679V22.9132H83.3462V27.9044C83.3462 28.6798 83.6909 29.0411 84.4477 29.0411C84.6521 29.0376 84.8562 29.0232 85.0591 28.9981V30.461C84.7188 30.5246 84.3729 30.5534 84.0268 30.547C82.1938 30.547 81.479 29.8585 81.479 28.1026V22.9132H80.1626V21.4415H81.479V19.299H83.3462Z"
          fill="white"
        />
        <path
          d="M86.0649 25.9689C86.0649 23.1202 87.7427 21.3302 90.3589 21.3302C92.9839 21.3302 94.6538 23.1202 94.6538 25.9689C94.6538 28.8253 92.9927 30.6075 90.3589 30.6075C87.726 30.6075 86.0649 28.8253 86.0649 25.9689ZM92.7602 25.9689C92.7602 24.0148 91.8647 22.8615 90.3589 22.8615C88.853 22.8615 87.9585 24.0236 87.9585 25.9689C87.9585 27.9308 88.853 29.0753 90.3589 29.0753C91.8647 29.0753 92.7602 27.9308 92.7602 25.9689Z"
          fill="white"
        />
        <path
          d="M96.186 21.4415H97.9585V22.9825H98.0015C98.1214 22.5012 98.4034 22.0759 98.8 21.778C99.1966 21.4801 99.6836 21.3279 100.179 21.3468C100.393 21.346 100.607 21.3693 100.816 21.4161V23.1544C100.546 23.0718 100.264 23.0339 99.981 23.0421C99.711 23.0312 99.4418 23.0788 99.192 23.1816C98.9421 23.2845 98.7175 23.4403 98.5335 23.6382C98.3496 23.836 98.2106 24.0714 98.1262 24.3281C98.0417 24.5847 98.0139 24.8566 98.0444 25.1251V30.4952H96.186L96.186 21.4415Z"
          fill="white"
        />
        <path
          d="M109.384 27.8361C109.134 29.4796 107.534 30.6075 105.486 30.6075C102.852 30.6075 101.217 28.8429 101.217 26.0118C101.217 23.172 102.861 21.3302 105.408 21.3302C107.913 21.3302 109.488 23.0509 109.488 25.796V26.4327H103.093V26.545C103.064 26.8782 103.105 27.2139 103.216 27.5297C103.326 27.8456 103.502 28.1344 103.732 28.3769C103.963 28.6195 104.242 28.8102 104.552 28.9365C104.861 29.0628 105.195 29.1218 105.529 29.1095C105.968 29.1506 106.409 29.0489 106.785 28.8195C107.162 28.59 107.455 28.2451 107.62 27.8361L109.384 27.8361ZM103.102 25.1339H107.628C107.645 24.8343 107.6 24.5345 107.495 24.2533C107.39 23.9721 107.229 23.7155 107.02 23.4998C106.812 23.284 106.561 23.1137 106.283 22.9995C106.006 22.8852 105.708 22.8296 105.408 22.8361C105.105 22.8343 104.805 22.8924 104.525 23.0071C104.245 23.1219 103.99 23.2909 103.776 23.5046C103.562 23.7182 103.392 23.9722 103.276 24.2518C103.16 24.5315 103.101 24.8313 103.102 25.1339Z"
          fill="white"
        />
        <path
          d="M39.3926 14.6969H35.6709V8.73016H36.5977V13.8449H39.3926V14.6969Z"
          fill="white"
        />
        <path
          d="M40.3291 13.4235C40.3291 12.6129 40.9326 12.1457 42.0039 12.0792L43.2236 12.0089V11.6203C43.2236 11.1447 42.9092 10.8761 42.3018 10.8761C41.8057 10.8761 41.4619 11.0583 41.3633 11.3766H40.5029C40.5937 10.6032 41.3213 10.1071 42.3428 10.1071C43.4717 10.1071 44.1084 10.6691 44.1084 11.6203V14.6969H43.2529V14.0641H43.1826C43.0399 14.2911 42.8395 14.4762 42.6019 14.6004C42.3643 14.7246 42.0979 14.7835 41.8301 14.7711C41.641 14.7908 41.45 14.7706 41.2692 14.7119C41.0885 14.6532 40.922 14.5573 40.7806 14.4303C40.6392 14.3033 40.5259 14.1482 40.4482 13.9747C40.3704 13.8013 40.3298 13.6135 40.3291 13.4235ZM43.2236 13.0387V12.6623L42.124 12.7326C41.5039 12.7741 41.2226 12.985 41.2226 13.382C41.2226 13.7872 41.5742 14.0231 42.0576 14.0231C42.1993 14.0374 42.3423 14.0231 42.4784 13.981C42.6144 13.939 42.7405 13.8699 42.8493 13.7781C42.9581 13.6863 43.0473 13.5735 43.1116 13.4464C43.1759 13.3194 43.214 13.1808 43.2236 13.0387Z"
          fill="white"
        />
        <path
          d="M47.1455 10.1071C48.1582 10.1071 48.8203 10.5782 48.9072 11.3722H48.0547C47.9727 11.0416 47.6494 10.8307 47.1455 10.8307C46.6494 10.8307 46.2725 11.066 46.2725 11.4176C46.2725 11.6866 46.5 11.8561 46.9883 11.9679L47.7363 12.1412C48.5928 12.34 48.9941 12.7081 48.9941 13.3698C48.9941 14.2174 48.2041 14.7838 47.1289 14.7838C46.0576 14.7838 45.3594 14.2999 45.2803 13.5021H46.1699C46.2466 13.692 46.3848 13.8506 46.5624 13.9525C46.7401 14.0544 46.9468 14.0937 47.1494 14.0641C47.7031 14.0641 48.0967 13.8161 48.0967 13.4562C48.0967 13.1876 47.8857 13.0138 47.4346 12.9064L46.6494 12.7242C45.793 12.5216 45.3965 12.1373 45.3965 11.4674C45.3965 10.6691 46.1279 10.1071 47.1455 10.1071Z"
          fill="white"
        />
        <path
          d="M51.3613 9.07288V10.2145H52.3369V10.963H51.3613V13.2784C51.3613 13.7501 51.5557 13.9567 51.998 13.9567C52.1113 13.9563 52.2244 13.9495 52.3369 13.9362V14.6764C52.1773 14.705 52.0156 14.7202 51.8535 14.7218C50.8652 14.7218 50.4717 14.3741 50.4717 13.506V10.963H49.7568V10.2144H50.4717V9.07288H51.3613Z"
          fill="white"
        />
        <path
          d="M55.9522 10.194H56.8077V10.9093H56.874C56.9867 10.6524 57.1767 10.437 57.4176 10.2932C57.6584 10.1494 57.9382 10.0843 58.2178 10.1071C58.4369 10.0906 58.6569 10.1236 58.8615 10.2037C59.0661 10.2838 59.25 10.4089 59.3997 10.5698C59.5494 10.7306 59.661 10.9231 59.7262 11.1329C59.7914 11.3427 59.8085 11.5645 59.7764 11.7819V14.6969H58.8877V12.005C58.8877 11.2814 58.5732 10.9215 57.916 10.9215C57.7673 10.9146 57.6188 10.9399 57.4807 10.9957C57.3426 11.0516 57.2183 11.1366 57.1162 11.245C57.014 11.3534 56.9366 11.4826 56.889 11.6237C56.8415 11.7648 56.8251 11.9146 56.8408 12.0626V14.6969H55.9521L55.9522 10.194Z"
          fill="white"
        />
        <path
          d="M64.9591 13.4811C64.8384 13.8926 64.5768 14.2486 64.2201 14.4868C63.8634 14.7249 63.4344 14.8301 63.008 14.7838C62.7113 14.7917 62.4165 14.7348 62.144 14.6173C61.8715 14.4998 61.6278 14.3244 61.4299 14.1032C61.232 13.8821 61.0846 13.6205 60.9979 13.3367C60.9112 13.0529 60.8873 12.7536 60.9279 12.4596C60.8884 12.1647 60.9127 11.8648 60.9992 11.5801C61.0857 11.2954 61.2323 11.0327 61.4292 10.8096C61.6261 10.5865 61.8686 10.4084 62.1403 10.2872C62.412 10.1659 62.7066 10.1045 63.0041 10.1071C64.257 10.1071 65.0133 10.9631 65.0133 12.3771V12.6872H61.8331V12.737C61.8192 12.9022 61.84 13.0685 61.8941 13.2253C61.9482 13.382 62.0345 13.5258 62.1474 13.6472C62.2603 13.7687 62.3974 13.8652 62.5498 13.9306C62.7022 13.996 62.8665 14.0288 63.0324 14.027C63.2449 14.0525 63.4603 14.0142 63.6511 13.917C63.8418 13.8198 63.9994 13.6681 64.1037 13.4811L64.9591 13.4811ZM61.8331 12.0299H64.1076C64.1188 11.8788 64.0982 11.727 64.0473 11.5843C63.9963 11.4416 63.9161 11.3111 63.8117 11.2012C63.7073 11.0914 63.5811 11.0046 63.4411 10.9464C63.3012 10.8882 63.1507 10.86 62.9992 10.8634C62.8455 10.8615 62.6929 10.8904 62.5506 10.9483C62.4082 11.0062 62.2788 11.0921 62.1702 11.2008C62.0615 11.3095 61.9757 11.4389 61.9178 11.5813C61.8599 11.7237 61.8311 11.8762 61.8331 12.0299Z"
          fill="white"
        />
        <path
          d="M65.9829 12.4435C65.9829 11.0206 66.7144 10.1193 67.8521 10.1193C68.1335 10.1063 68.4128 10.1737 68.6573 10.3136C68.9018 10.4535 69.1015 10.6601 69.2329 10.9093H69.2993V8.43616H70.188V14.6969H69.3364V13.9855H69.2661C69.1245 14.233 68.9179 14.437 68.6686 14.5755C68.4194 14.714 68.137 14.7816 67.8521 14.7711C66.7065 14.7712 65.9829 13.8698 65.9829 12.4435ZM66.9009 12.4435C66.9009 13.3986 67.3511 13.9733 68.104 13.9733C68.853 13.9733 69.3159 13.3903 69.3159 12.4474C69.3159 11.5089 68.8482 10.9176 68.104 10.9176C67.356 10.9176 66.9009 11.4962 66.9009 12.4435Z"
          fill="white"
        />
        <path
          d="M74.4067 10.963H73.6665V10.2145H74.4067V9.77603C74.4067 8.85803 74.9067 8.47769 75.8452 8.47769C76.006 8.47692 76.1665 8.49081 76.3247 8.51919V9.23017C76.195 9.20831 76.0637 9.19737 75.9321 9.19746C75.5063 9.19746 75.2827 9.3918 75.2827 9.83418V10.2146H76.2876V10.9631H75.2954V14.697H74.4067V10.963Z"
          fill="white"
        />
        <path
          d="M77.4194 10.194H78.2749V10.8844H78.3413C78.4371 10.6447 78.6063 10.4416 78.8248 10.3041C79.0432 10.1666 79.2996 10.1019 79.5571 10.1193C79.6902 10.1175 79.8232 10.1299 79.9536 10.1564V11.0334C79.7905 10.9985 79.6243 10.9805 79.4575 10.9797C79.311 10.9665 79.1632 10.9842 79.0239 11.0315C78.8846 11.0788 78.7566 11.1547 78.6484 11.2544C78.5402 11.3541 78.454 11.4754 78.3954 11.6104C78.3368 11.7454 78.307 11.8911 78.3081 12.0383V14.697H77.4194L77.4194 10.194Z"
          fill="white"
        />
        <path
          d="M80.6519 13.4235C80.6519 12.6129 81.2554 12.1457 82.3267 12.0792L83.5464 12.0089V11.6203C83.5464 11.1447 83.2319 10.8761 82.6245 10.8761C82.1284 10.8761 81.7847 11.0583 81.686 11.3766H80.8257C80.9165 10.6032 81.644 10.1071 82.6655 10.1071C83.7944 10.1071 84.4311 10.6691 84.4311 11.6203V14.6969H83.5757V14.0641H83.5054C83.3626 14.2911 83.1623 14.4762 82.9246 14.6004C82.687 14.7246 82.4207 14.7835 82.1528 14.7711C81.9638 14.7908 81.7727 14.7706 81.592 14.7119C81.4112 14.6532 81.2448 14.5573 81.1033 14.4303C80.9619 14.3033 80.8487 14.1482 80.7709 13.9747C80.6932 13.8013 80.6526 13.6135 80.6519 13.4235ZM83.5464 13.0387V12.6623L82.4468 12.7326C81.8267 12.7741 81.5454 12.985 81.5454 13.382C81.5454 13.7872 81.897 14.0231 82.3804 14.0231C82.5221 14.0374 82.6651 14.0231 82.8011 13.981C82.9371 13.9389 83.0633 13.8699 83.1721 13.7781C83.2809 13.6862 83.3701 13.5735 83.4344 13.4464C83.4987 13.3194 83.5368 13.1808 83.5464 13.0387Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11825_176836">
          <rect
            width="135"
            height="40"
            fill="white"
            transform="translate(0 -0.000854492)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
