import { SvgIcon, SvgIconProps } from '@mui/material'

export const CompanionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.2787 2C9.1853 2.00785 8.31904 2.84758 8.27241 3.9849C8.28036 5.0916 9.129 5.94937 10.2787 5.99167C11.3024 5.9778 12.2118 5.13375 12.2636 3.9849C12.2558 2.8827 11.4122 2.05025 10.2787 2ZM10.8334 6.43958C10.378 6.43958 9.99381 6.5925 9.68075 6.89843C9.4264 7.15372 9.14163 7.71678 9.23283 8.16875H9.21148L10.3854 13.825C10.5065 14.4729 11.0397 14.8906 11.7516 14.9349C13.3869 14.9304 15.16 14.8933 16.8313 14.9136L19.649 19.8229C19.966 20.4921 20.7779 20.633 21.3672 20.1432C21.65 19.8627 21.7643 19.3853 21.5698 18.9693L18.3042 13.2912C18.0689 12.8044 17.6047 12.5301 17.0235 12.5016H13.3948L12.9682 10.4099H15.8068C16.4204 10.4553 16.8625 9.98698 16.7354 9.34272C16.6421 9.02792 16.3159 8.73808 15.9349 8.72347H12.6052C12.4374 7.86575 12.4641 7.34725 11.8047 6.75992C11.5272 6.54645 11.2033 6.43958 10.8334 6.43958ZM7.77136 8.86042C7.68114 8.86172 7.59135 8.87307 7.50364 8.89427C6.24624 9.38352 5.11502 10.2243 4.238 11.3917C3.46518 12.4815 3.03312 13.7933 3 15.2339C3.01287 16.9412 3.74602 18.6933 5.0599 20.0151C6.33337 21.228 8.10099 21.9601 10.0011 22C11.3869 21.9924 12.7723 21.5831 14.0355 20.7729C15.17 20.0023 16.059 18.8965 16.5964 17.5177C16.696 17.2758 16.7815 17.0051 16.8526 16.7063C16.9568 16.2365 16.6931 15.8549 16.1912 15.7031C15.7346 15.602 15.3263 15.8539 15.1667 16.3438C15.1243 16.5561 15.0599 16.7635 14.9745 16.9625C14.612 17.921 13.9373 18.7506 13.0427 19.3958C12.173 19.9873 11.1313 20.3094 10.0011 20.3349C8.65691 20.3254 7.36219 19.813 6.27657 18.8412C5.34376 17.9368 4.7602 16.6426 4.72917 15.2339C4.73514 14.2296 5.05854 13.2386 5.66824 12.3417C6.26616 11.5116 7.10318 10.8705 8.12293 10.4526C8.55265 10.3117 8.78415 9.86677 8.63491 9.38542C8.49076 9.0394 8.15118 8.85528 7.77136 8.86042Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
