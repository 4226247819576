import { SvgIcon, SvgIconProps } from '@mui/material'

export const InfoSmallIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.002 4.79951C10.0924 4.79951 8.26105 5.55808 6.91078 6.90834C5.56052 8.2586 4.80195 10.09 4.80195 11.9995C4.80195 13.9091 5.56052 15.7404 6.91078 17.0907C8.26105 18.4409 10.0924 19.1995 12.002 19.1995C13.9115 19.1995 15.7429 18.4409 17.0931 17.0907C18.4434 15.7404 19.202 13.9091 19.202 11.9995C19.202 10.09 18.4434 8.2586 17.0931 6.90834C15.7429 5.55808 13.9115 4.79951 12.002 4.79951ZM3.00195 11.9995C3.00195 7.02881 7.03125 2.99951 12.002 2.99951C16.9727 2.99951 21.002 7.02881 21.002 11.9995C21.002 16.9702 16.9727 20.9995 12.002 20.9995C7.03125 20.9995 3.00195 16.9702 3.00195 11.9995ZM12.002 16.4995C12.2406 16.4995 12.4696 16.4047 12.6383 16.2359C12.8071 16.0671 12.902 15.8382 12.902 15.5995V11.9995C12.902 11.7608 12.8071 11.5319 12.6383 11.3631C12.4696 11.1943 12.2406 11.0995 12.002 11.0995C11.7633 11.0995 11.5343 11.1943 11.3656 11.3631C11.1968 11.5319 11.102 11.7608 11.102 11.9995V15.5995C11.102 15.8382 11.1968 16.0671 11.3656 16.2359C11.5343 16.4047 11.7633 16.4995 12.002 16.4995ZM12.002 7.49951C11.7633 7.49951 11.5343 7.59433 11.3656 7.76312C11.1968 7.9319 11.102 8.16082 11.102 8.39951C11.102 8.63821 11.1968 8.86712 11.3656 9.03591C11.5343 9.20469 11.7633 9.29951 12.002 9.29951H12.011C12.2496 9.29951 12.4786 9.20469 12.6473 9.03591C12.8161 8.86712 12.911 8.63821 12.911 8.39951C12.911 8.16082 12.8161 7.9319 12.6473 7.76312C12.4786 7.59433 12.2496 7.49951 12.011 7.49951H12.002Z"
        fill="#1D1D1D"
      />
    </SvgIcon>
  )
}
