import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson, get, post } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import {
  TCheckoutTicketWithoutSeatMap,
  TCheckoutWithoutSeatMap,
  TCheckoutWithSeatMap,
  TCheckTicketsAgeValidationResponse,
  TNotifyMeAvailable,
  TPromoCodeExist,
  TTicketMinimumPrice,
} from './ticket.codecs'

const getTicketsRoute = (eventSlug: string) =>
  `cms/trades/events/${eventSlug}/tickets` as const
const getTicketRoute = (eventSlug: string) =>
  `${getTicketsRoute(eventSlug)}/:ticketSeatId` as const

export type GetMinimumTicketPriceInput = {
  eventSlug: string
}

export const getMinimumTicketPrice = async (
  input: GetMinimumTicketPriceInput,
) => {
  return pipe(
    await get(
      `cms/trades/public/events/${input.eventSlug}/tickets/min-price`,
      {},
    ),
    decodeJson(TTicketMinimumPrice),
  )
}

export type GetPublicTicketsInput = {
  eventSlug: string
  promoCode?: string | null
} & LocaleInput

const getPublicTicketsPayload = (
  input: GetPublicTicketsInput & { deprecatedView: boolean },
) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  const query = new URLSearchParams({
    deprecatedView: String(input.deprecatedView),
  })

  if (input.promoCode !== undefined && input.promoCode !== null) {
    query.set('promoCode', input.promoCode)
  }

  return { headers, query }
}

export const getPublicTicketsWithoutSeatMap = async (
  input: GetPublicTicketsInput,
) => {
  const { headers, query } = getPublicTicketsPayload({
    ...input,
    deprecatedView: true,
  })

  return pipe(
    await get(getTicketsRoute(input.eventSlug), {
      headers,
      query,
    }),
    decodeJson(TCheckoutWithoutSeatMap),
  )
}

export const getPublicTicketsWithSeatMap = async (
  input: GetPublicTicketsInput,
) => {
  const { headers, query } = getPublicTicketsPayload({
    ...input,
    deprecatedView: false,
  })

  return pipe(
    await get(getTicketsRoute(input.eventSlug), {
      headers,
      query,
    }),
    decodeJson(TCheckoutWithSeatMap),
  )
}

export type GetPublicTicketInput = {
  eventSlug: string
  ticketSeatId: UUID
}

export const getPublicTicket = async (input: GetPublicTicketInput) => {
  return pipe(
    await get(getTicketRoute(input.eventSlug), {
      params: {
        ticketSeatId: input.ticketSeatId,
      },
    }),
    decodeJson(TCheckoutTicketWithoutSeatMap),
  )
}

type NotifyOnSalesStartInput = {
  eventSlug: string
  email: string
  contactEmail: boolean
}

export const notifyOnSalesStart = (input: NotifyOnSalesStartInput) => {
  return post(`${getTicketsRoute(input.eventSlug)}/notify/me`, {
    body: {
      email: input.email.trim(),
      contactEmail: input.contactEmail,
    },
  })
}

export type CheckTicketsAgeValidationInput = {
  eventSlug: string
}

export const checkTicketsAgeValidation = async (
  input: CheckTicketsAgeValidationInput,
) => {
  return pipe(
    await get(`${getTicketsRoute(input.eventSlug)}/validate-age`, {}),
    decodeJson(TCheckTicketsAgeValidationResponse),
  )
}

export type CheckPossibleTicketsNotificationInput = {
  eventSlug: string
}

export const checkPossibleTicketsNotification = async (
  input: CheckPossibleTicketsNotificationInput,
) => {
  return pipe(
    await get(`${getTicketsRoute(input.eventSlug)}/notify/available/me`, {}),
    decodeJson(TNotifyMeAvailable),
  )
}

type CheckPromoCodeExistInput = {
  eventSlug: string
  promoCode: string
}

export const checkPromoCodeExist = async (input: CheckPromoCodeExistInput) => {
  return pipe(
    await get(
      `${getTicketsRoute(input.eventSlug)}/promo/${input.promoCode}/exists`,
      {},
    ),
    decodeJson(TPromoCodeExist),
  )
}

type CheckPromoCodeExistForTicketInput = {
  eventSlug: string
  promoCode: string
  ticketId: UUID
}

export const checkPromoCodeExistForTicket = async (
  input: CheckPromoCodeExistForTicketInput,
) => {
  return pipe(
    await get(
      `${getTicketsRoute(input.eventSlug)}/:ticketId/promo/${
        input.promoCode
      }/exists`,
      { params: { ticketId: input.ticketId } },
    ),
    decodeJson(TPromoCodeExist),
  )
}
