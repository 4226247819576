import {
  intersection,
  number,
  string,
  type,
  TypeOf,
  boolean,
  literal,
} from 'io-ts'
import { UUID } from 'io-ts-types'
import { TOptionalString } from 'src/lib/types'
import { TAvailableLanguages } from 'src/app/locale'
import { TImage } from 'src/api/file'

//Venue schema

export const TVenue = type({
  name: string,
  venueId: UUID,
  country: string,
  city: TOptionalString,
  street: TOptionalString,
  streetNumber: TOptionalString,
  zipCode: TOptionalString,
  venueSlug: string,
  active: boolean,
})
export type IVenue = TypeOf<typeof TVenue>

export const TVenueWithCoverImage = intersection([
  TVenue,
  type({ coverImage: TImage }),
])

export const TVenueDetails = intersection([
  TVenueWithCoverImage,
  type({
    latitude: number,
    longitude: number,
    description: string,
    availableLanguages: TAvailableLanguages,
  }),
])
export type IVenueDetails = TypeOf<typeof TVenueDetails>

//Venue with Payload

export enum VenuePayloadType {
  WITH_EVENT_COUNT = 'WITH_EVENT_COUNT',
}

const TVenuePayload = type({
  payloadType: literal(VenuePayloadType.WITH_EVENT_COUNT),
  eventCount: number,
})

export const TVenueWithPayload = intersection([
  TVenueWithCoverImage,
  type({
    payload: TVenuePayload,
  }),
])

export type IVenueWithPayload = TypeOf<typeof TVenueWithPayload>

//Venues Countries

export const TVenuesCountries = type({
  key: string,
  value: boolean,
})
