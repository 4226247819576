import { Translate } from 'next-translate'
import { z } from 'zod'

export const phoneRegex = /^\+\d{8,16}$/
export const passwordSpecialCharacters = /^[\d!#$%&*+,.<>?@A-Za-z~-]*$/
export const passwordRegex =
  /^(?=.*?[A-Z])(?=(.*[a-z]))(?=(.*\d))(?=(.*\W))(?!.*\s).{8,}$/
export const fullNameRegex = /^(?=(.*[A-Za-zÀ-žА-я]))[ '.A-Za-zÀ-žА-я-]*$/

export const emailSchema = (t: Translate) =>
  z
    .string()
    .min(1, {
      message: t('validation_required_field', {
        name: t('email'),
      }),
    })
    .max(50, {
      message: t('validation_too_big', {
        name: t('email'),
        maximum: 50,
      }),
    })
    .email({ message: t('validation_email') })

export const passwordSchemaWithoutSymbolsValidation = (t: Translate) =>
  z.string().max(50, {
    message: t('validation_too_big', {
      name: t('password'),
      maximum: 50,
    }),
  })

export const passwordSchema = (t: Translate) =>
  z
    .string()
    .regex(passwordRegex, t('validation_password'))
    .max(50, {
      message: t('validation_too_big', {
        name: t('password'),
        maximum: 50,
      }),
    })

export const phoneNumberSchema = (t: Translate) =>
  z.string().regex(phoneRegex, t('validation_phone_with_example'))

export const entityNameSchema = (props: { t: Translate; fieldName: string }) =>
  z
    .string()
    .min(1, {
      message: props.t('validation_required_field', {
        name: props.t(`${props.fieldName}`),
      }),
    })
    .regex(
      fullNameRegex,
      props.t('validation_one_letter_spec_characters', {
        value: 2,
        characters: props.t('characters_and_space', { characters: ".-'" }),
      }),
    )
    .min(2, {
      message: props.t('validation_too_small', {
        name: props.t(`${props.fieldName}`),
        minimum: 2,
      }),
    })
    .max(50, {
      message: props.t('validation_too_big', {
        name: props.t(`${props.fieldName}`),
        maximum: 50,
      }),
    })
