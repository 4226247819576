import { SvgIcon, SvgIconProps } from '@mui/material'

export const OpenedEye = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.3425 18.4145C7.16956 18.3877 3.39299 14.1492 2.07183 11.9162C2.02774 11.8417 2.03521 11.7495 2.08186 11.6765C2.6903 10.7252 5.76529 5 12.3425 5C17.8376 5 21.4814 9.40634 22.6713 11.6992C22.7021 11.7585 22.7004 11.8256 22.6666 11.8834C21.3523 14.1297 17.5417 18.4414 12.3425 18.4145Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="white"
      />
      <circle cx="12.3595" cy="11.7073" r="4.23787" fill="currentColor" />
    </SvgIcon>
  )
}
