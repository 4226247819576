import { loadStripe, Stripe } from '@stripe/stripe-js'
import { env } from 'src/app/env/env'

// eslint-disable-next-line fp/no-let
export let stripePromise: Promise<Stripe | null> | null = null

// initialize stripe only once
export const initStripe = () => {
  const stripeSecret = env.stripeSecret
  // eslint-disable-next-line fp/no-mutation
  stripePromise = loadStripe(stripeSecret)
}
