import { useRouter } from 'next/router'
import {
  IApplicationLocale,
  applicationLocaleToApiLocale,
  decodeApplicationLocale,
  defaultApplicationLocale,
} from 'src/app/locale'

export type LocaleInput = {
  locale: IApplicationLocale
}

export const setAcceptLanguageHeader = (locale: IApplicationLocale) => {
  const acceptLanguage =
    applicationLocaleToApiLocale[locale] ??
    applicationLocaleToApiLocale[defaultApplicationLocale]
  const localeHeaders: Headers = new Headers()
  if (acceptLanguage !== undefined) {
    localeHeaders.set('Accept-Language', acceptLanguage)
  }
  return { headers: localeHeaders }
}

export const useLocale = () => {
  const router = useRouter()

  const locale = router.locale ?? defaultApplicationLocale

  return {
    locale: decodeApplicationLocale(locale),
  }
}
