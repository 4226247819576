import {
  TypeOf,
  array,
  boolean,
  intersection,
  number,
  partial,
  string,
  type,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'
import { TVariationType } from 'src/api/trade'
import { TOptionalString } from 'src/lib/types'
import { TCheckoutProvider } from '../checkout'

export const TLiveOrder = type({
  expiredAt: DateFromISOString,
  companionPassQuantity: number,
  totalFee: number,
  totalPrice: number,
  eventId: UUID,
  orderId: UUID,
  promoCode: TOptionalString,
  totalTicketPrice: number,
})
export type ILiveOrder = TypeOf<typeof TLiveOrder>

const TPaymentProviderFee = type({
  key: TCheckoutProvider,
  value: number,
})
export type IPaymentProviderFee = TypeOf<typeof TPaymentProviderFee>

export const TLiveNonEmptyOrder = intersection([
  TLiveOrder,
  type({
    currency: string,
    providerFees: array(TPaymentProviderFee),
  }),
])
export type ILiveNonEmptyOrder = TypeOf<typeof TLiveNonEmptyOrder>

const TTicketOrderDistributedBucket = intersection([
  type({
    price: number,
    variationType: TVariationType,
    companionPassIncluded: boolean,
    currency: string,
    fees: number,
    referenceId: UUID,
    variationId: UUID,
    holdedAtTimestamp: number,
    purchaseId: UUID,
  }),
  partial({
    companionReferenceId: UUID,
  }),
])
export type ITicketOrderDistributedBucket = TypeOf<
  typeof TTicketOrderDistributedBucket
>

export const TLiveOrderDistributedBucket = intersection([
  TLiveOrder,
  type({
    ticketPricesInfoResponse: array(TTicketOrderDistributedBucket),
  }),
])
export type ILiveOrderDistributedBucket = TypeOf<
  typeof TLiveOrderDistributedBucket
>

export const TLiveNonEmptyOrderDistributedBucket = intersection([
  TLiveNonEmptyOrder,
  type({
    ticketPricesInfoResponse: array(TTicketOrderDistributedBucket),
  }),
])
export type ILiveNonEmptyOrderDistributedBucket = TypeOf<
  typeof TLiveNonEmptyOrderDistributedBucket
>

const TTicketOrderCombinedBucket = type({
  ticketSeatId: UUID,
  quantity: number,
  price: number,
  companionPassQuantity: number,
  variationType: TVariationType,
})
export type ITicketOrderCombinedBucket = TypeOf<
  typeof TTicketOrderCombinedBucket
>

export const TLiveOrderCombinedBucket = intersection([
  TLiveOrder,
  type({
    ticketPricesInfoResponse: array(TTicketOrderCombinedBucket),
  }),
])
export type ILiveOrderCombinedBucket = TypeOf<typeof TLiveOrderCombinedBucket>
