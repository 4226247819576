import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { decodeJson, get } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { TTicketsEvent } from './event.codecs'

const events = 'cms/events' as const

export type GetUserTicketsEventsInput = LocaleInput

export const getUserTicketsEvents = async (
  input: GetUserTicketsEventsInput,
) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${events}/me`, { headers }),
    decodeJson(array(TTicketsEvent)),
  )
}
