import { SvgIcon, SvgIconProps } from '@mui/material'

export const LikeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M11.9731 4.558C10.8835 3.5561 9.45733 3.00005 7.97713 3C7.19366 3.00082 6.4181 3.15656 5.69506 3.45827C4.97203 3.75998 4.31579 4.20169 3.76413 4.758C1.41113 7.121 1.41213 10.817 3.76613 13.17L11.0981 20.502C11.2681 20.801 11.5961 20.994 11.9731 20.994C12.1279 20.9925 12.2802 20.9547 12.4178 20.8837C12.5553 20.8126 12.6743 20.7103 12.7651 20.585L20.1801 13.17C22.5341 10.816 22.5341 7.121 20.1781 4.754C19.6267 4.19872 18.971 3.7579 18.2487 3.45688C17.5264 3.15587 16.7517 3.0006 15.9691 3C14.489 3.00024 13.0628 3.55627 11.9731 4.558ZM18.7641 6.168C20.3271 7.739 20.3281 10.193 18.7661 11.756L11.9731 18.549L5.18012 11.756C3.61812 10.193 3.61913 7.739 5.17813 6.172C5.93813 5.416 6.93213 5 7.97713 5C9.02213 5 10.0121 5.416 10.7661 6.17L11.2661 6.67C11.3589 6.76295 11.4691 6.83668 11.5904 6.88699C11.7117 6.9373 11.8418 6.9632 11.9731 6.9632C12.1045 6.9632 12.2345 6.9373 12.3558 6.88699C12.4771 6.83668 12.5873 6.76295 12.6801 6.67L13.1801 6.17C14.6921 4.661 17.2541 4.665 18.7641 6.168Z"
      fill="currentColor"
    />
  </SvgIcon>
)
