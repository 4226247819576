import { array, intersection, Mixed, type, TypeOf } from 'io-ts'
import { BooleanFromString, NumberFromString } from 'io-ts-types'
import { decodeHeaders, decodeJson } from '../request'

export type PaginationInput = {
  /**
   * The number of entries to skip.
   * Should be greater or equal than 0.
   */
  skip: number
  /**
   * The maximum number of entries to fetch.
   * Should be greater or equal than 0.
   */
  limit: number
}

export type InfiniteScrollPaginationInput = Omit<PaginationInput, 'skip'>
export type InfiniteScrollInput<Input> = {
  pageParam?: number
  input: Input
}

export type SortInput = {
  /**
   * A sorting direction.
   * asc - Ascending (from lowest to highest)
   * desc - Descending (from highest to lowest)
   */
  direction: 'asc' | 'desc'
  /**
   * The name of a property to sort by.
   * Predefined on the server side.
   */
  sort: string
}

export type SearchInput = {
  /**
   * The search query.
   */
  search: string
}

export type FilterInput = {
  /**
   * Filter active or inactive items.
   * Optional
   */
  active?: boolean | null
}

export const TTotalCount = type({
  /**
   * The total number of entries in the database.
   */
  'x-total-count': NumberFromString,
})

const TPaginationNext = type({
  next: BooleanFromString,
})

export const createListQueryParams = (
  input: PaginationInput & SearchInput & SortInput & FilterInput,
) => {
  const params = new URLSearchParams({
    direction: input.direction.toUpperCase(),
    limit: input.limit.toString(),
    skip: input.skip.toString(),
    sort: input.sort.toUpperCase(),
  })

  if (input.active !== undefined && input.active !== null) {
    params.set('active', input.active ? 'true' : 'false')
  }

  const search = input.search.trim()
  const minSearchSymbols = 1
  if (search.length > minSearchSymbols) {
    params.set('search', search)
  }

  return params
}

export type JsonArrayWithTotal<T> = {
  rows: Array<T>
  total: number
  isNext: boolean
}

export const decodeJsonWithTotal = <TCodec extends Mixed>(codec: TCodec) => {
  type Result = JsonArrayWithTotal<TypeOf<TCodec>>

  return async (response: Response): Promise<Result> => {
    const headers = decodeHeaders(intersection([TTotalCount, TPaginationNext]))(
      response,
    )
    const rows = await decodeJson(array(codec))(response)
    return { rows, total: headers['x-total-count'], isNext: headers['next'] }
  }
}
