import { validateClientSideEnvVariables } from 'src/lib/env-variables'

export const env = validateClientSideEnvVariables({
  facebookClientId: process.env['NEXT_PUBLIC_FACEBOOK_CLIENT_ID'],
  stripeSecret: process.env['NEXT_PUBLIC_STRIPE_SECRET'],
  gtmAuth: process.env['NEXT_PUBLIC_GTM_AUTH'],
  gtmPreview: process.env['NEXT_PUBLIC_GTM_PREVIEW'],
  gtmId: process.env['NEXT_PUBLIC_GTM_ID'],
  originUrl: process.env['NEXT_PUBLIC_ORIGIN_URL'],
  googleApiKey: process.env['NEXT_PUBLIC_GOOGLE_API_KEY'],
  appId: process.env['NEXT_PUBLIC_ID'],
  environment: process.env['NEXT_PUBLIC_ENVIRONMENT'],
  reCaptchaKey: process.env['NEXT_PUBLIC_RECAPTCHA_KEY'],
})
