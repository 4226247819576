import { signOut, useSession } from 'next-auth/react'
import { ReactNode, useEffect } from 'react'
import { Session } from 'next-auth'
import { Box } from '@mui/system'
import { useRouter } from 'next/router'
import { pages, pathsObject } from '../pages'
import { useBoolean } from 'src/lib/use-boolean'
import { mutateGlobalAccessToken } from 'src/lib/use-auth'
import { DialogSignIn } from 'src/modules/sign-in/dialog-sign-in'
import { toRouterPath } from 'src/lib/route-generator'

export const getTokenExpirationTime = ({
  tokenExpireTime,
  tokenLifeTime,
}: {
  tokenExpireTime: number
  tokenLifeTime: number
}) => {
  return Math.round(tokenExpireTime * 1000 - Date.now() - tokenLifeTime / 2)
}

const isInvalidSession = (session: Session) => {
  return (
    session.error !== undefined ||
    session.accessToken === undefined ||
    session.accessTokenExpiry === undefined ||
    session.accessTokenLifeTime === undefined
  )
}

const pagesForAuthenticated = new Set<string>([
  pathsObject['/my-profile'],
  pathsObject['/my-profile/navigation'],
  pathsObject['/tickets'],
  pathsObject['/favorites'],
  pathsObject['/memberships'],
  pathsObject['/payment-methods'],
  pathsObject['/security'],
  pathsObject['/contacts'],
  pathsObject['/events/[eventSlug]/checkout'],
  pathsObject['/events/[eventSlug]/checkout/seat-map'],
  pathsObject['/notifications/settings'],
])

type Props = {
  setInterval: (interval: number) => void
  children: ReactNode
  isAuthRequired: boolean
}

export const AccessTokenHandler = ({
  setInterval,
  children,
  isAuthRequired,
}: Props) => {
  const session = useSession()
  const router = useRouter()

  const dialogSignIn = useBoolean(false)
  const openDialogSignIn = dialogSignIn.setTrue

  const isSessionLoading = useBoolean(true)
  const isSessionLoadingSetTrue = isSessionLoading.setTrue
  const isSessionLoadingSetFalse = isSessionLoading.setFalse

  const accessTokenExpiry = session.data?.accessTokenExpiry
  const accessTokenLifeTime = session.data?.accessTokenLifeTime

  useEffect(() => {
    if (session.status === 'loading') {
      return
    }
    if (session.status === 'authenticated' && isInvalidSession(session.data)) {
      signOut()
    }
    if (session.data?.accessToken) {
      mutateGlobalAccessToken(session.data.accessToken)
      isSessionLoadingSetFalse()
      return
    }
    mutateGlobalAccessToken(null)
    isSessionLoadingSetFalse()
  }, [isSessionLoadingSetFalse, isSessionLoadingSetTrue, session, setInterval])

  useEffect(() => {
    if (accessTokenExpiry && accessTokenLifeTime) {
      const refetchTime = getTokenExpirationTime({
        tokenExpireTime: accessTokenExpiry,
        tokenLifeTime: accessTokenLifeTime,
      })
      setInterval(refetchTime / 1000)
      return
    }
    setInterval(0)
  }, [accessTokenExpiry, accessTokenLifeTime, setInterval])

  const isRequiredSignIn =
    session.status === 'unauthenticated' &&
    pagesForAuthenticated.has(toRouterPath(router.pathname))
  useEffect(() => {
    if (isRequiredSignIn) {
      openDialogSignIn()
    }
  }, [openDialogSignIn, isRequiredSignIn, router.pathname])

  if (isSessionLoading.isTrue && isAuthRequired) {
    return null
  }

  if (isRequiredSignIn) {
    return (
      <Box>
        {dialogSignIn.isTrue && (
          <DialogSignIn
            onClose={() => {
              dialogSignIn.setFalse()
              router.push(pages['/'])
            }}
          />
        )}
      </Box>
    )
  }

  return <Box>{children}</Box>
}
