/* eslint-disable @typescript-eslint/no-explicit-any */
import { pipe } from 'fp-ts/lib/function'
import { map } from 'fp-ts/lib/ReadonlyRecord'
import { RouteParams } from './route-params'
import { UUID } from 'io-ts-types'
import { generatePath } from './utils'

export const makeRoutes = <T extends Record<string, string>, ParamType = UUID>(
  routes: T,
): {
  [K in keyof T]: keyof RouteParams<T[K]> extends never
    ? T[K]
    : (params: RouteParams<T[K], ParamType>) => string
} => {
  return pipe(
    routes,
    map((value) => {
      if (value.includes(':')) {
        return (params: any) => generatePath(value, params)
      }
      return value
    }),
  ) as any
}
