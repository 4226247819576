import { SvgIcon, SvgIconProps } from '@mui/material'

export const AmericanExpressLogoIcon = ({
  sx = { width: 24, height: 24 },
  ...props
}: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={sx}>
      <g clipPath="url(#clip0_2118_36919)">
        <path
          d="M0 -0.00244141H23.9404V12.9535L22.7554 14.8096L23.9404 16.4616V23.9976H0V11.7841L0.740616 10.9303L0 10.1136V-0.00244141Z"
          fill="#016FD0"
        />
        <path
          d="M4.64746 16.7223V12.9543H8.62715L9.05412 13.5123L9.49522 12.9543H23.9405V16.4625C23.9405 16.4625 23.5627 16.7186 23.1258 16.7223H15.1272L14.6458 16.1284V16.7223H13.0683V15.7084C13.0683 15.7084 12.8528 15.8499 12.3869 15.8499H11.85V16.7223H9.46146L9.0351 16.1524L8.60218 16.7223H4.64746Z"
          fill="white"
        />
        <path
          d="M0 10.1133L0.897435 8.01587H2.44945L2.95876 9.19077V8.01587H4.88806L5.19125 8.86505L5.48518 8.01587H14.1458V8.44278C14.1458 8.44278 14.601 8.01587 15.3493 8.01587L18.1593 8.02571L18.6598 9.18524V8.01587H20.2744L20.7187 8.68192V8.01587H22.3481V11.7838H20.7187L20.2929 11.1156V11.7838H17.9207L17.6822 11.1899H17.0445L16.8098 11.7838H15.2011C14.5573 11.7838 14.1458 11.3656 14.1458 11.3656V11.7838H11.7202L11.2388 11.1899V11.7838H2.21953L1.98115 11.1899H1.34547L1.10877 11.7838H0V10.1133Z"
          fill="white"
        />
        <path
          d="M1.21484 8.48218L0.00439453 11.3035H0.792456L1.0158 10.7386H2.31419L2.53637 11.3035H3.34179L2.13251 8.48218H1.21484ZM1.66268 9.13879L2.05845 10.126H1.26576L1.66268 9.13879Z"
          fill="#016FD0"
        />
        <path
          d="M3.42529 11.3001V8.47876L4.54517 8.48293L5.19653 10.302L5.83229 8.47876H6.94322V11.3001H6.23963V9.22122L5.49382 11.3001H4.87677L4.12888 9.22122V11.3001H3.42529Z"
          fill="#016FD0"
        />
        <path
          d="M7.4248 11.3001V8.47876H9.72071V9.10985H8.1358V9.59245H9.68368V10.1864H8.1358V10.6876H9.72071V11.3001H7.4248Z"
          fill="#016FD0"
        />
        <path
          d="M10.1279 8.48218V11.3035H10.8315V10.3012H11.1278L11.9714 11.3035H12.8312L11.9054 10.2641C12.2853 10.2319 12.6773 9.90503 12.6773 9.39749C12.6773 8.80378 12.2124 8.48218 11.6936 8.48218H10.1279ZM10.8315 9.11327H11.6358C11.8287 9.11327 11.9691 9.26456 11.9691 9.41025C11.9691 9.59769 11.7872 9.70724 11.6462 9.70724H10.8315V9.11327Z"
          fill="#016FD0"
        />
        <path
          d="M13.6828 11.3001H12.9644V8.47876H13.6828V11.3001Z"
          fill="#016FD0"
        />
        <path
          d="M15.3863 11.3001H15.2312C14.4809 11.3001 14.0254 10.7075 14.0254 9.90103C14.0254 9.07461 14.4758 8.47876 15.4233 8.47876H16.2009V9.14697H15.3949C15.0102 9.14697 14.7382 9.44788 14.7382 9.90799C14.7382 10.4544 15.0493 10.6839 15.4974 10.6839H15.6825L15.3863 11.3001Z"
          fill="#016FD0"
        />
        <path
          d="M16.9175 8.48218L15.707 11.3035H16.4951L16.7184 10.7386H18.0168L18.239 11.3035H19.0444L17.8351 8.48218H16.9175ZM17.3653 9.13879L17.7611 10.126H16.9684L17.3653 9.13879Z"
          fill="#016FD0"
        />
        <path
          d="M19.1265 11.3001V8.47876H20.021L21.1632 10.2514V8.47876H21.8667V11.3001H21.0012L19.8301 9.48108V11.3001H19.1265Z"
          fill="#016FD0"
        />
        <path
          d="M5.12891 16.2386V13.4172H7.42482V14.0483H5.8399V14.5309H7.38778V15.1249H5.8399V15.6261H7.42482V16.2386H5.12891Z"
          fill="#016FD0"
        />
        <path
          d="M16.3789 16.2386V13.4172H18.6748V14.0483H17.0899V14.5309H18.6304V15.1249H17.0899V15.6261H18.6748V16.2386H16.3789Z"
          fill="#016FD0"
        />
        <path
          d="M7.51392 16.2386L8.63179 14.8453L7.4873 13.4172H8.37373L9.05533 14.3001L9.73924 13.4172H10.5909L9.46151 14.8279L10.5814 16.2386H9.69515L9.03334 15.3697L8.38762 16.2386H7.51392Z"
          fill="#016FD0"
        />
        <path
          d="M10.665 13.4172V16.2386H11.3871V15.3476H12.1278C12.7544 15.3476 13.2294 15.0143 13.2294 14.3662C13.2294 13.8293 12.8569 13.4172 12.2192 13.4172H10.665ZM11.3871 14.0553H12.1671C12.3696 14.0553 12.5143 14.1797 12.5143 14.3801C12.5143 14.5684 12.3703 14.7049 12.1648 14.7049H11.3871V14.0553Z"
          fill="#016FD0"
        />
        <path
          d="M13.5347 13.4172V16.2386H14.2383V15.2363H14.5345L15.3781 16.2386H16.2379L15.3121 15.1991C15.6921 15.167 16.084 14.8401 16.084 14.3325C16.084 13.7388 15.6192 13.4172 15.1004 13.4172H13.5347ZM14.2383 14.0483H15.0425C15.2354 14.0483 15.3758 14.1996 15.3758 14.3453C15.3758 14.5328 15.194 14.6423 15.0529 14.6423H14.2383V14.0483Z"
          fill="#016FD0"
        />
        <path
          d="M19.0005 16.2386V15.6261H20.4086C20.6169 15.6261 20.7071 15.5132 20.7071 15.3894C20.7071 15.2708 20.6172 15.1509 20.4086 15.1509H19.7723C19.2192 15.1509 18.9111 14.8131 18.9111 14.3059C18.9111 13.8535 19.1932 13.4172 20.0151 13.4172H21.3853L21.089 14.052H19.904C19.6775 14.052 19.6078 14.1712 19.6078 14.285C19.6078 14.4019 19.6939 14.5309 19.867 14.5309H20.5335C21.1501 14.5309 21.4177 14.8815 21.4177 15.3407C21.4177 15.8343 21.1195 16.2386 20.5 16.2386H19.0005Z"
          fill="#016FD0"
        />
        <path
          d="M21.583 16.2386V15.6261H22.9911C23.1994 15.6261 23.2896 15.5132 23.2896 15.3894C23.2896 15.2708 23.1997 15.1509 22.9911 15.1509H22.3548C21.8017 15.1509 21.4937 14.8131 21.4937 14.3059C21.4937 13.8535 21.7757 13.4172 22.5976 13.4172H23.9678L23.6715 14.052H22.4865C22.26 14.052 22.1903 14.1712 22.1903 14.285C22.1903 14.4019 22.2765 14.5309 22.4495 14.5309H23.1161C23.7326 14.5309 24.0002 14.8815 24.0002 15.3407C24.0002 15.8343 23.702 16.2386 23.0825 16.2386H21.583Z"
          fill="#016FD0"
        />
      </g>
      <defs>
        <clipPath id="clip0_2118_36919">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
