import { array, number, string, type } from 'io-ts'
import { UUID } from 'io-ts-types'

export const TAccessToken = type({
  /**
   * Token status
   */
  active: string,

  /**
   * Audience of a token
   */
  aud: string,

  /**
   * Token expiration date (UNIX date)
   */
  exp: number,

  /**
   * Different user roles have different scopes.
   * These are actions the user is permitted to do.
   */
  scopes: array(string),

  userId: UUID,
})
