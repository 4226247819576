import { FieldError } from 'react-hook-form'

export const textFieldError = (error?: FieldError) => {
  return error === undefined
    ? {}
    : {
        error: true,
        helperText: error?.message ?? '',
      }
}
