module.exports = {
  locales: ['en', 'no'],
  defaultLocale: 'en',
  // Set up namespaces for each separate page. Currently all pages have 'common' namespace
  pages: {
    '*': ['common'],
    '/privacy-policy': ['privacy'],
    '/mobile-app/privacy-policy': ['privacy'],
    '/purchase-policy': ['purchase'],
    '/mobile-app/purchase-policy': ['purchase'],
    '/terms-of-use': ['terms'],
    '/mobile-app/terms-of-use': ['terms'],
    '/about-fabnite': ['about'],
    '/events/[eventSlug]/checkout': ['membership'],
    '/events/[eventSlug]/checkout/seat-map': ['membership'],
    '/organizations/[organizationSlug]/clc': ['membership'],
  },
  defaultNS: 'common',
}
