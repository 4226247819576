import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FabniteLogoCosmosBackgroundIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6275 23.9999 12 23.9999C5.37262 23.9999 0 18.6274 0 12C0 5.37254 5.37262 0 12 0C18.6275 0 24 5.37254 24 12"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7764 7.96696C13.3081 7.86351 13.6998 7.59917 13.9095 7.20219C14.1256 6.79288 14.1393 6.23494 13.9444 5.77937C13.5437 5.07863 12.9432 4.66916 11.9981 4.45193C11.661 4.37448 11.2936 4.31348 10.9105 4.31348C10.0421 4.31348 9.093 4.62749 8.232 5.77681C7.34662 6.95873 7.14833 7.99102 6.93855 9.084C6.69478 10.353 6.44278 11.6652 5.13761 13.3708C5.07436 13.4478 5.06031 13.4946 5.06691 13.5118C5.07428 13.5311 5.1179 13.5682 5.32652 13.6001C5.53435 13.632 7.50363 13.3481 7.94158 12.3181C8.1096 11.923 8.23013 11.5496 8.3467 11.1885C8.73599 9.98241 9.04341 9.02983 10.7448 8.70224C10.8572 8.68066 10.9462 8.64682 11.0167 8.59878C11.1487 8.50899 11.1464 8.42812 11.1306 8.2812C11.1005 8.00483 10.9425 7.9831 10.6165 7.96347C10.2602 7.94197 9.77216 7.91248 9.52855 7.24278C9.35105 6.75477 9.47437 6.24162 9.87562 5.79792C10.3757 5.24487 11.1956 4.92046 11.7427 5.05907C12.0112 5.12729 12.1865 5.30044 12.2496 5.55997C12.3784 6.08981 12.0676 6.35896 11.818 6.57526C11.6724 6.7013 11.5351 6.82028 11.5008 6.96898C11.4414 7.2278 11.5245 7.51287 11.718 7.71294C11.9593 7.96261 12.3353 8.05288 12.7764 7.96696"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1068 12.7051C10.8786 12.7051 10.7253 12.4867 10.7253 12.162C10.7253 11.3682 11.1469 10.1519 11.6663 10.1519C11.8584 10.1519 11.9731 10.2713 11.9731 10.4711C11.9731 11.2382 11.6357 12.7051 11.1068 12.7051V12.7051ZM11.9328 12.6986L11.9793 12.8153C11.9817 12.8212 12.2356 13.4094 12.9217 13.4094C13.5394 13.4094 14.0219 13.1918 14.3558 12.7626C14.4781 12.6033 14.5372 12.522 14.5372 12.4157C14.5372 12.3849 14.5097 12.3246 14.4462 12.3246C14.4073 12.3246 14.3784 12.3397 14.2934 12.4247L14.2791 12.4361C13.9468 12.6463 13.6842 12.6121 13.5225 12.546C13.2568 12.4376 13.0393 12.1496 12.9548 11.7942C12.8209 11.2306 13.1929 10.5058 13.4647 9.97661C13.5814 9.74921 13.6917 9.53439 13.6917 9.45453C13.6917 9.37622 13.6666 9.35107 13.5883 9.35107C13.4688 9.35107 13.3017 9.43924 13.1401 9.52453C12.9497 9.62496 12.7527 9.72888 12.5737 9.72888C12.4875 9.72888 12.4036 9.69636 12.2971 9.65523C12.1197 9.58662 11.8768 9.49263 11.467 9.49263C10.2521 9.49263 9.02612 10.2796 9.02612 12.0378C9.02612 12.9853 9.71507 13.4094 10.3977 13.4094C11.2124 13.4094 11.6718 12.9837 11.8315 12.8007L11.8437 12.7872L11.9328 12.6986Z"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08366 17.8754C9.62165 17.8754 9.87186 17.7332 10.1013 17.5039C10.3717 17.2334 10.4046 16.8698 10.2186 16.9637C10.1367 17.005 10.0584 17.0417 9.95708 16.9997C9.80388 16.9362 9.63865 16.744 9.63865 16.5443C9.63865 16.282 9.74233 15.996 9.85223 15.6932C9.96158 15.3917 10.0747 15.08 10.0747 14.8004C10.0747 14.3816 9.64144 14.1001 8.99658 14.1001C8.27799 14.1001 7.62389 14.6157 7.41978 14.8653L7.17314 15.1669L7.27543 14.7911C7.27612 14.7883 7.34706 14.5256 7.34706 14.3403C7.34706 14.2809 7.34706 14.1001 7.00728 14.1001C6.22172 14.1001 5.54861 14.3074 5.54861 14.4771C5.54861 14.536 5.56242 14.5691 5.58144 14.615C5.60557 14.6732 5.63568 14.7457 5.63568 14.875C5.63568 15.252 5.47627 15.934 5.33564 16.5358C5.2214 17.0245 5.11353 17.4861 5.11353 17.6848C5.11353 17.8468 5.17096 17.8754 5.29156 17.8754C5.36607 17.8754 5.52625 17.8647 5.71174 17.8523C5.97437 17.8349 6.30119 17.8132 6.52245 17.8132C6.68093 17.8132 6.71299 17.7832 6.71299 17.6351C6.71299 17.2296 7.21442 15.8797 7.75024 15.391C7.8821 15.2706 8.00993 15.2098 8.13069 15.2098C8.14155 15.2098 8.15242 15.2103 8.16313 15.2113C8.28001 15.222 8.36328 15.2657 8.41047 15.3409C8.51361 15.5052 8.41606 15.7661 8.29235 16.0966C8.18563 16.382 8.06448 16.7055 8.06448 17.001C8.06448 17.5321 8.46456 17.8754 9.08366 17.8754"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5447 15.6927C11.7597 15.7025 11.9884 15.6179 12.1662 15.4606C12.3601 15.2892 12.4734 15.0492 12.4853 14.7848C12.4921 14.6319 12.4237 14.4851 12.2926 14.3715C12.1579 14.2546 11.9738 14.1854 11.7742 14.1765C11.5235 14.1666 11.2862 14.2597 11.1089 14.4424C10.9446 14.6117 10.8444 14.8412 10.8341 15.0719C10.8187 15.418 11.1109 15.6733 11.5447 15.6927"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4381 14.0037C17.4406 13.4377 15.483 14.4887 15.6332 12.8032C15.6427 12.7189 15.6109 12.6761 15.562 12.6488C15.4795 12.6028 15.3745 12.6076 15.2952 12.659C15.2421 12.6935 15.1917 12.7452 15.1569 12.8147C14.6144 13.9 13.7917 13.8919 13.2908 13.9357C13.1901 13.9445 12.8546 13.9362 12.8218 14.2006C12.7928 14.4336 13.4568 14.4336 13.6228 14.4336C14.6162 14.4336 13.3577 15.5799 13.033 16.3015C12.7326 16.9694 12.5582 17.7265 12.9127 18.6216C13.2767 19.5405 14.1754 19.7855 14.6788 19.8497C15.5366 19.9592 16.7952 19.6089 16.7952 19.3599C16.7893 19.1794 16.5708 19.2294 16.4199 19.2262C16.1412 19.2205 15.7596 19.2125 15.4116 19.0734C14.9271 18.8795 14.3359 18.4078 14.1294 17.6652C13.9991 17.1966 13.9869 16.4488 14.6683 15.5482C15.5526 14.3795 16.3443 14.3437 16.8677 14.3126C17.1272 14.2971 17.4406 14.2677 17.4381 14.0037"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7343 16.6622C16.7343 16.056 17.1274 15.4385 17.5134 15.4385C17.6978 15.4385 17.8078 15.5512 17.8078 15.7399C17.8078 16.0249 17.5218 16.6118 16.8328 16.7437L16.7343 16.7626V16.6622ZM18.9346 17.6693C18.9346 17.6433 18.9101 17.6068 18.8561 17.6068C18.8088 17.6068 18.7237 17.6647 18.6254 17.7319C18.3878 17.8917 18.0702 18.0679 17.6375 18.0679C17.1355 18.0679 16.7503 17.7238 16.7217 17.2496L16.7163 17.1616H16.8046C17.8695 17.1616 18.7358 16.5673 18.7358 15.8367C18.7358 15.3041 18.272 14.9731 17.5256 14.9731C16.264 14.9731 14.9231 15.7267 14.9231 17.1232C14.9231 18.1349 15.6407 18.715 16.8917 18.715C17.6928 18.715 18.359 18.342 18.6862 18.0226C18.8788 17.8345 18.9346 17.7553 18.9346 17.6693V17.6693Z"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5309 9.11457C16.7673 9.09734 16.8966 9.22586 16.9163 9.49679C16.9458 9.89912 16.673 11.5129 16.1728 11.5497C16.1631 11.5504 16.1533 11.5508 16.1438 11.5508C16.0541 11.5508 15.9763 11.5192 15.9171 11.4585C15.8581 11.3979 15.8231 11.3133 15.8158 11.2138C15.7624 10.484 16.1025 9.14584 16.5309 9.11457M14.2969 11.647C14.3766 11.6263 14.4629 11.6039 14.548 11.6039C14.6038 11.6039 14.6591 11.6134 14.7121 11.6394C14.7671 11.6664 14.8258 11.7058 14.8938 11.7515C15.1359 11.9141 15.5021 12.1595 16.1395 12.113C17.9041 11.984 18.5906 10.4584 18.5145 9.41642C18.4452 8.4685 17.7351 8.32042 17.1494 8.36302C16.6578 8.39904 16.2893 8.64723 16.1231 8.84878L15.9409 9.06982L15.9767 8.78561C16.0501 8.20408 16.316 5.50822 16.2731 4.92033C16.2332 4.3752 15.8514 4.08936 15.2267 4.13453C14.5317 4.18536 14.0283 4.49006 14.0408 4.66297C14.0487 4.68238 14.1215 4.72995 14.1697 4.76146C14.237 4.80539 14.3133 4.85521 14.379 4.92041C14.5538 5.09379 14.9644 5.55184 15.0016 6.06112C15.0801 7.13493 14.6588 9.05926 14.5507 9.38491L14.5548 9.38553C14.4827 9.82038 14.2983 10.2075 14.1357 10.5491C13.9159 11.0102 13.7425 11.3744 13.9565 11.625C14.03 11.7112 14.1336 11.6896 14.2969 11.647"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6462 19.3637C12.0688 19.4009 12.4334 19.2545 12.5263 19.163C12.5198 19.1526 12.5122 19.1404 12.5037 19.1269C12.4506 19.0417 12.3702 18.9127 12.3341 18.8251C12.0039 18.0233 12.0066 17.2145 12.3428 16.2799C12.3563 16.242 12.3476 16.1917 12.3091 16.1728C12.2324 16.1354 12.1085 16.1255 12.009 16.121C11.9429 16.1182 11.879 16.1167 11.8177 16.1167C11.1813 16.1167 10.8007 16.2683 10.7934 16.4315C10.7911 16.4824 10.8303 16.5496 10.8717 16.6207C10.9309 16.722 10.9978 16.8368 10.9917 16.9739C10.9844 17.1369 10.922 17.3096 10.8558 17.4926C10.7449 17.7993 10.6193 18.1468 10.7096 18.5533C10.8461 19.1681 11.2943 19.3333 11.6462 19.3637"
        fill="#00247A"
      />
    </SvgIcon>
  )
}
