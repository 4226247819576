import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import useTranslation from 'next-translate/useTranslation'
import { AppStoreEnIcon } from './icons/app-store/app-store-en'
import { AppLink } from './app-link'
import { AppStoreNoIcon } from './icons/app-store/app-store-no'
import { GooglePlayEnIcon } from './icons/google-play/google-play-en'
import { GooglePlayNoIcon } from './icons/google-play/google-play-no'

export const AppLinks = () => {
  const { t } = useTranslation()

  return (
    <Stack spacing={{ xs: 2, sm: 1, lg: 1.5 }}>
      <Typography variant="h3ExtraBold">{t('get_our_app')}</Typography>
      <Stack direction={{ xs: 'row', md: 'column' }} spacing={{ xs: 1 }}>
        <AppLink
          href="https://apps.apple.com/app/fabnite/id6444892357"
          icons={{ en: AppStoreEnIcon, no: AppStoreNoIcon }}
        />
        <AppLink
          href="https://play.google.com/store/apps/details?id=com.fabnite.android"
          icons={{ en: GooglePlayEnIcon, no: GooglePlayNoIcon }}
        />
      </Stack>
    </Stack>
  )
}
