export const GreenCloud = () => (
  <svg width="219" height="126" viewBox="0 0 219 126" fill="none">
    <path
      d="M59.2628 123.732C61.7438 117.81 64.8876 112.017 68.8091 106.651C72.4961 101.606 77.0539 96.7602 82.8628 93.4862C88.5004 90.3091 94.923 89.1717 101.189 90.3729C107.591 91.5996 112.814 94.8402 117.899 98.1553C126.187 103.559 142.444 114.492 152.834 104.825C157.451 100.528 158.908 94.5609 161.103 89.2661C163.439 83.6348 166.196 78.0398 169.713 72.8319C172.883 68.138 177.032 63.3178 182.588 60.6201C188.37 57.8122 194.81 58.4028 199.954 61.2713C201.337 62.0426 202.627 62.9308 203.843 63.8871"
      stroke="#CCF6E8"
      strokeWidth="116.773"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.2628 130.732C61.7438 124.81 64.8876 119.017 68.8091 113.651C72.4961 108.606 77.0539 103.76 82.8628 100.486C88.5004 97.3091 94.923 96.1717 101.189 97.3729C107.591 98.5996 112.814 101.84 117.899 105.155C126.187 110.559 142.444 121.492 152.834 111.825C157.451 107.528 158.908 101.561 161.103 96.2661C163.439 90.6348 166.196 85.0398 169.713 79.8319C172.883 75.138 177.032 70.3178 182.588 67.6201C188.37 64.8122 194.81 65.4028 199.954 68.2713C201.337 69.0426 202.627 69.9308 203.843 70.8871"
      stroke="#4AC398"
      strokeWidth="116.773"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
