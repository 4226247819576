import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FabniteElements = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 456 239" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.761 18.21C116.062 33.4957 114.5 56.0372 126.615 73.034C139.881 91.6454 165.223 97.5439 182.392 90.1019C211.45 77.5065 217.417 26.5555 192.582 8.3829C176.037 -3.72236 145.283 -1.67868 128.761 18.21Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M300.649 169.626C299.885 158.111 307.676 147.715 318.813 145.932C324.633 145 331.358 146.422 337.386 153.964C344.683 163.094 350.877 156.698 358.748 146.339C373.237 127.27 400.733 144.678 409.901 157.978C419.309 171.623 427.148 198.313 403.238 210.554C388.063 218.322 370.704 179.851 344.049 206.139C326.832 223.12 302.261 193.924 300.649 169.626Z"
        fill="#CCF6E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.064 175.277C127.064 175.277 143.214 106.487 195.62 125.457C221.788 134.929 245.63 140.749 252.779 111.948C261.94 75.0371 282.016 48.1471 312.346 67.9705C326.31 77.097 363.261 136.669 338.804 172.322C314.347 207.976 310.843 134.894 282.698 208.62C257.339 275.049 232.489 173.77 191.02 191.434C150.349 208.758 109.061 235.859 127.064 175.277Z"
        fill="#FC605B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0297 118.71C44.4761 130.541 51.7128 142.104 63.4069 146.266C76.2119 150.823 90.1887 145.02 95.6133 135.493C104.795 119.369 89.5806 92.3974 71.5018 92.0216C59.4585 91.7717 45.7503 103.315 45.0297 118.71Z"
        fill="#4AC398"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.205 111.974C141.589 106.677 148.134 98.8609 150.132 89.366C150.818 86.1033 150.942 82.6301 150.553 79.141C148.951 64.7584 138.586 52.8351 124.75 48.8485C116.225 46.3923 106.417 46.7519 94.5357 49.7269C87.3182 51.5342 80.2663 56.8769 92.7087 57.6378C98.6554 58.0016 103.309 60.0283 106.141 65.0508C111.923 75.3036 107.081 82.7398 103.192 88.7136C100.925 92.1953 98.7852 95.482 98.9539 98.7767C99.2474 104.51 102.645 109.944 107.824 112.959C114.283 116.721 122.589 116.372 131.205 111.974Z"
        fill="#CCF6E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.834 124.361C315.135 120.294 303.208 88.7047 348.639 65.454C394.069 42.2034 403.46 71.0988 402.275 89.5595C401.091 108.02 384.533 128.428 349.834 124.361Z"
        fill="#9E7BFF"
      />
      <path
        d="M49.0232 189.45C51.8275 184.87 55.1636 180.481 59.0881 176.544C62.7779 172.842 67.1623 169.416 72.381 167.448C77.4457 165.539 82.9 165.416 87.9274 167.206C93.0639 169.035 96.9693 172.378 100.751 175.766C106.915 181.287 118.964 192.394 128.783 185.724C133.146 182.759 135.11 178.011 137.599 173.913C140.246 169.555 143.237 165.281 146.807 161.423C150.025 157.946 154.068 154.489 159.004 152.965C164.141 151.38 169.39 152.687 173.278 155.713C174.323 156.527 175.276 157.425 176.159 158.37"
        stroke="#FFCFCF"
        strokeWidth="97.6715"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M392.681 168.834C392.681 168.834 414.073 170.3 409.791 157.958C405.508 145.617 389.673 141.631 398.595 133.195C407.516 124.76 439.719 138.075 424.016 111.936"
        stroke="#00247A"
        strokeWidth="53.9228"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.248 42.9697C277.536 47.2857 281.587 50.5152 286.307 50.5218C291.475 50.5295 295.761 46.6704 296.51 42.5622C297.777 35.6092 288.926 27.8644 282.391 30.0278C278.038 31.4689 274.57 37.3539 276.248 42.9697Z"
        fill="#4AC398"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M301.735 34.1942C302.624 37.1707 305.418 39.3982 308.673 39.4029C312.237 39.4079 315.193 36.7466 315.709 33.9134C316.583 29.1176 310.479 23.7765 305.972 25.2686C302.97 26.2624 300.578 30.3212 301.735 34.1942Z"
        fill="#CCF6E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.338 94.1639C291.338 127.148 264.745 153.886 231.941 153.886C199.136 153.886 172.542 127.148 172.542 94.1639C172.542 61.18 199.136 34.4414 231.941 34.4414C264.745 34.4414 291.338 61.18 291.338 94.1639Z"
        fill="#00247A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.78 74.0971C238.411 73.5822 240.351 72.2666 241.389 70.2909C242.458 68.2538 242.526 65.477 241.561 63.2097C239.578 59.7222 236.606 57.6843 231.927 56.6031C230.259 56.2177 228.44 55.9141 226.544 55.9141C222.245 55.9141 217.548 57.4769 213.286 63.1969C208.903 69.0792 207.922 74.2168 206.883 79.6564C205.677 85.9721 204.429 92.5029 197.969 100.991C197.656 101.375 197.587 101.607 197.619 101.693C197.656 101.789 197.872 101.974 198.904 102.133C199.933 102.291 209.681 100.878 211.848 95.7521C212.68 93.7857 213.277 91.9274 213.854 90.1301C215.781 84.1277 217.302 79.3868 225.724 77.7564C226.28 77.6491 226.72 77.4807 227.07 77.2416C227.723 76.7947 227.712 76.3922 227.633 75.661C227.485 74.2855 226.702 74.1774 225.089 74.0797C223.325 73.9727 220.909 73.8259 219.704 70.4929C218.825 68.0641 219.435 65.5102 221.421 63.302C223.897 60.5495 227.955 58.935 230.663 59.6248C231.992 59.9643 232.86 60.8261 233.172 62.1177C233.81 64.7547 232.271 66.0942 231.036 67.1707C230.315 67.798 229.635 68.3901 229.466 69.1302C229.172 70.4184 229.583 71.8371 230.541 72.8328C231.735 74.0754 233.596 74.5246 235.78 74.0971Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.513 97.6839C226.384 97.6839 225.625 96.5973 225.625 94.9812C225.625 91.0306 227.712 84.9768 230.282 84.9768C231.233 84.9768 231.801 85.5713 231.801 86.5659C231.801 90.3832 230.131 97.6839 227.513 97.6839ZM231.602 97.6545L231.832 98.2351C231.844 98.2644 233.101 101.192 236.498 101.192C239.555 101.192 241.943 100.109 243.596 97.9728C244.202 97.1802 244.494 96.7754 244.494 96.2466C244.494 96.0933 244.358 95.7931 244.043 95.7931C243.851 95.7931 243.708 95.8681 243.287 96.291L243.216 96.3482C241.572 97.3942 240.272 97.2238 239.471 96.8947C238.156 96.3555 237.079 94.9221 236.661 93.1531C235.998 90.3485 237.84 86.7412 239.185 84.1074C239.763 82.9756 240.309 81.9065 240.309 81.509C240.309 81.1193 240.184 80.9941 239.797 80.9941C239.206 80.9941 238.379 81.4329 237.578 81.8574C236.636 82.3572 235.661 82.8744 234.775 82.8744C234.348 82.8744 233.933 82.7126 233.406 82.5079C232.528 82.1664 231.325 81.6987 229.297 81.6987C223.284 81.6987 217.215 85.6153 217.215 94.3655C217.215 99.0813 220.625 101.192 224.004 101.192C228.037 101.192 230.31 99.0732 231.101 98.1624L231.161 98.0952L231.602 97.6545Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.502 123.404C220.165 123.404 221.403 122.697 222.539 121.556C223.878 120.21 224.04 118.4 223.12 118.867C222.714 119.073 222.326 119.255 221.825 119.046C221.067 118.73 220.249 117.774 220.249 116.78C220.249 115.474 220.762 114.051 221.306 112.544C221.847 111.043 222.407 109.492 222.407 108.101C222.407 106.016 220.263 104.615 217.071 104.615C213.514 104.615 210.276 107.182 209.266 108.424L208.045 109.925L208.551 108.054C208.555 108.041 208.906 106.733 208.906 105.811C208.906 105.515 208.906 104.615 207.224 104.615C203.336 104.615 200.004 105.647 200.004 106.492C200.004 106.784 200.072 106.949 200.166 107.178C200.286 107.468 200.435 107.828 200.435 108.472C200.435 110.348 199.646 113.742 198.95 116.737C198.384 119.17 197.85 121.467 197.85 122.456C197.85 123.262 198.135 123.404 198.732 123.404C199.1 123.404 199.893 123.351 200.811 123.29C202.111 123.203 203.729 123.095 204.824 123.095C205.609 123.095 205.767 122.946 205.767 122.208C205.767 120.191 208.249 113.472 210.902 111.04C211.554 110.441 212.187 110.138 212.785 110.138C212.838 110.138 212.892 110.141 212.945 110.146C213.524 110.199 213.936 110.416 214.17 110.791C214.68 111.608 214.197 112.907 213.585 114.552C213.057 115.972 212.457 117.582 212.457 119.053C212.457 121.696 214.437 123.404 217.502 123.404Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.684 112.549C230.748 112.598 231.88 112.177 232.76 111.395C233.72 110.541 234.281 109.347 234.34 108.031C234.374 107.27 234.035 106.539 233.386 105.974C232.719 105.392 231.808 105.048 230.82 105.004C229.579 104.954 228.405 105.418 227.527 106.327C226.714 107.17 226.218 108.311 226.166 109.46C226.09 111.183 227.537 112.453 229.684 112.549Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.853 104.137C258.865 101.32 249.175 106.55 249.919 98.1621C249.966 97.7427 249.809 97.5295 249.567 97.3939C249.158 97.1648 248.639 97.1888 248.246 97.4445C247.983 97.616 247.734 97.8736 247.562 98.2193C244.876 103.621 240.804 103.581 238.325 103.798C237.826 103.842 236.166 103.801 236.003 105.117C235.86 106.277 239.146 106.277 239.968 106.277C244.885 106.277 238.656 111.981 237.049 115.573C235.562 118.897 234.698 122.665 236.453 127.12C238.255 131.693 242.703 132.912 245.195 133.232C249.441 133.777 255.671 132.033 255.671 130.794C255.642 129.896 254.56 130.145 253.813 130.129C252.434 130.1 250.545 130.061 248.822 129.368C246.424 128.403 243.498 126.056 242.475 122.36C241.831 120.027 241.77 116.306 245.143 111.824C249.52 106.007 253.439 105.829 256.03 105.674C257.314 105.597 258.865 105.451 258.853 104.137Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M255.373 117.379C255.373 114.363 257.319 111.289 259.229 111.289C260.142 111.289 260.687 111.85 260.687 112.789C260.687 114.208 259.271 117.129 255.861 117.785L255.373 117.879V117.379ZM266.264 122.389C266.264 122.26 266.143 122.078 265.875 122.078C265.641 122.078 265.22 122.366 264.733 122.701C263.558 123.496 261.985 124.373 259.844 124.373C257.359 124.373 255.452 122.66 255.31 120.3L255.284 119.862H255.721C260.992 119.862 265.28 116.904 265.28 113.269C265.28 110.618 262.984 108.971 259.29 108.971C253.045 108.971 246.408 112.721 246.408 119.672C246.408 124.706 249.96 127.594 256.152 127.594C260.117 127.594 263.415 125.737 265.034 124.147C265.988 123.212 266.264 122.817 266.264 122.389Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.366 79.8187C255.536 79.7329 256.176 80.3726 256.274 81.721C256.419 83.7233 255.069 91.7551 252.593 91.9379C252.545 91.9413 252.497 91.9436 252.45 91.9436C252.006 91.9436 251.62 91.7861 251.328 91.484C251.035 91.1827 250.862 90.7617 250.826 90.2661C250.562 86.6342 252.245 79.9743 254.366 79.8187ZM243.307 92.4178C243.701 92.3146 244.129 92.203 244.55 92.203C244.826 92.203 245.1 92.2505 245.362 92.3799C245.634 92.5143 245.925 92.7106 246.261 92.9377C247.46 93.7469 249.273 94.9686 252.428 94.7368C261.162 94.0949 264.56 86.5023 264.183 81.3164C263.84 76.5987 260.325 75.8617 257.427 76.0737C254.993 76.253 253.169 77.4882 252.346 78.4913L251.444 79.5914L251.622 78.1769C251.985 75.2827 253.301 61.8657 253.089 58.9398C252.892 56.2268 251.002 54.8042 247.909 55.029C244.469 55.282 241.977 56.7984 242.04 57.659C242.078 57.7556 242.439 57.9923 242.677 58.1492C243.011 58.3678 243.388 58.6158 243.714 58.9402C244.579 59.8031 246.611 62.0828 246.795 64.6174C247.184 69.9616 245.099 79.5388 244.563 81.1596L244.583 81.1627C244.227 83.3269 243.314 85.2535 242.509 86.9534C241.421 89.2485 240.563 91.0612 241.622 92.3081C241.986 92.7372 242.499 92.6298 243.307 92.4178Z"
        fill="#FFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.187 130.818C232.279 131.003 234.083 130.275 234.543 129.82C234.511 129.767 234.473 129.707 234.432 129.64C234.168 129.215 233.77 128.573 233.592 128.137C231.958 124.147 231.971 120.122 233.635 115.47C233.702 115.282 233.659 115.031 233.468 114.937C233.088 114.751 232.475 114.702 231.983 114.68C231.656 114.666 231.339 114.658 231.036 114.658C227.886 114.658 226.002 115.413 225.965 116.225C225.954 116.478 226.148 116.813 226.353 117.167C226.646 117.671 226.977 118.242 226.947 118.924C226.911 119.736 226.602 120.595 226.275 121.506C225.726 123.032 225.104 124.762 225.551 126.785C226.227 129.845 228.445 130.667 230.187 130.818Z"
        fill="#FFCFCF"
      />
    </SvgIcon>
  )
}
