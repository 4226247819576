import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Clouds = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 531 151" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M284.657 61.6176C315.666 85.5859 307.468 132.792 303.56 171.829C299.841 208.967 296.077 251.904 263.736 270.567C232.731 288.458 196.614 265.813 163.334 252.749C134.888 241.582 104.781 230.711 91.7691 203.059C77.8453 173.468 78.1249 137.219 96.0905 109.841C113.078 83.9533 146.651 81.3937 176.636 73.7255C213.575 64.2788 254.523 38.3262 284.657 61.6176Z"
      fill="#9E7BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M516.61 130.538C516.61 130.538 426.8 -11.2214 323.281 72.2254C271.591 113.894 222.314 145.553 183.839 86.5016C134.533 10.8239 68.3739 -33.8537 15.3232 34.2888C-9.1007 65.6608 -46.2507 228.161 36.5903 289.085C119.432 350.01 70.6378 183.286 191.339 326.767C300.092 456.045 277.581 209.399 384.847 216.686C490.05 223.832 604.229 252.436 516.61 130.538"
      fill="#4AC398"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M516.61 138.488C516.61 138.488 426.8 -3.2712 323.281 80.1756C271.591 121.844 222.314 153.503 183.839 94.4518C134.533 18.7741 68.3739 -25.9035 15.3232 42.239C-9.1007 73.611 -46.2507 236.111 36.5903 297.035C119.432 357.96 70.6378 191.236 191.339 334.717C300.092 463.995 277.581 217.349 384.847 224.636C490.05 231.782 604.229 260.386 516.61 138.488"
      fill="#FFCFCF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M284.657 69.5707C315.666 93.539 307.468 140.745 303.56 179.782C299.841 216.92 296.077 259.857 263.736 278.52C232.731 296.412 196.614 273.766 163.334 260.702C134.888 249.535 104.781 238.664 91.7691 211.012C77.8453 181.421 78.1249 145.173 96.0905 117.794C113.078 91.9064 146.651 89.3468 176.636 81.6786C213.575 72.232 254.523 46.2793 284.657 69.5707Z"
      fill="#CCF6E8"
    />
    <defs>
      <clipPath id="clip0_4868_80817">
        <rect width="531" height="151" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
