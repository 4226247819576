export const artistHeaderGradient =
  'linear-gradient(.25turn, #00247A 1.08%, #9E7BFF 42.96%, #4AC398 97.96%)'

export const venueHeaderGradient =
  'linear-gradient(.25turn, #00247A -37.75%, #9E7BFF 23.1%, #FFCFCF 103.02%)'

export const articleHeaderGradient =
  'linear-gradient(.25turn, #9E7BFF 2.59%, #4AC398 56.07%, #CCF6E8 107.93%)'

export const placeHeaderGradient =
  'linear-gradient(.25turn, #FC605B 10.62%, #9E7BFF 95.66%)'

export const exploreHeaderGradient =
  'linear-gradient(.25turn, #00247A 7.34%, #3A3F9B 29.65%, #9D6CD3 58.28%, #B175DE 69.97%, #BA73CE 76.53%, #C66FB9 83.33%, #CE6DAB 89.94%, #DF688D 97.69%, #FC605B 105.92%);'

export const organizationHeaderGradient =
  'linear-gradient(.25turn, #FC605B -28.46%, #9E7BFF 40.06%, #4AC398 105.79%)'

export const aboutFabniteHeaderGradient = '#00247A'

export const pagesGradient: Record<string, string> = {
  '/artists/[artistSlug]': artistHeaderGradient,
  '/events/[eventSlug]': exploreHeaderGradient,
  '/articles/[articleSlug]': articleHeaderGradient,
  '/places/[placeSlug]': placeHeaderGradient,
  '/': exploreHeaderGradient,
  '/organizations/[organizationSlug]': organizationHeaderGradient,
  '/organizations/[organizationSlug]/events': organizationHeaderGradient,
  '/organizations/[organizationSlug]/clc': organizationHeaderGradient,
  '/about-fabnite': aboutFabniteHeaderGradient,
}
