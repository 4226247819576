import { TypeOf, array, string, type } from 'io-ts'
import { TAvailableLanguages } from 'src/app/locale'

export const TSeoResponseRow = type({
  key: string,
  value: TAvailableLanguages,
})
export type ISeoResponseRow = TypeOf<typeof TSeoResponseRow>

export const TSeoResponse = array(TSeoResponseRow)
export type ISeoResponse = TypeOf<typeof TSeoResponse>
