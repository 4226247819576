import { globalAccessToken } from 'src/lib/use-auth'
import { useBoolean } from 'src/lib/use-boolean'

type Props<T> = {
  authorizedAction: (params: T) => void
}

export const useAuthenticationRequired = <T,>({
  authorizedAction,
}: Props<T>) => {
  const dialogUnauthenticated = useBoolean(false)

  const onClickAuthenticationRequired = (params: T) => {
    if (globalAccessToken === null) {
      dialogUnauthenticated.setTrue()
      return
    }
    authorizedAction(params)
  }

  return {
    dialogUnauthenticated,
    onClickAuthenticationRequired,
  }
}
