import { Avatar, useTheme } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'

type Props = {
  profileImageSrc: undefined | string
  name: string
  proportion?: ResponsiveStyleValue<number>
  color?: string
}

export const Profile = ({
  profileImageSrc,
  name,
  proportion,
  color,
}: Props) => {
  const theme = useTheme()

  const defaultProportion = proportion ?? { xs: 32, sm: 40, lg: 32 }

  if (profileImageSrc) {
    return (
      <Avatar
        src={profileImageSrc}
        sx={{
          width: defaultProportion,
          height: defaultProportion,
        }}
      />
    )
  }

  return (
    <Avatar
      sx={{
        '&&': {
          ...theme.typography.bodyBold,
          width: defaultProportion,
          height: defaultProportion,
        },
        backgroundColor: 'transparent',
        color: color ?? theme.palette.common.white,
        border: '2px solid',
        borderColor: color ?? theme.palette.common.white,
      }}
    >
      {name.charAt(0)}
    </Avatar>
  )
}
