export const toRouterPath = (path: string) => {
  return path.replaceAll(']', '').replaceAll('[', ':')
}

type ReplaceParams<T extends string> =
  T extends `${infer Start}/[${infer Param}]${infer Rest}`
    ? `${Start}/:${Param}${ReplaceParams<Rest>}`
    : T

export const toPathsObject = <T extends string>(
  paths: ReadonlyArray<T>,
): {
  [Path in T]: ReplaceParams<Path>
} => {
  return Object.fromEntries(
    paths.map((path) => [path, toRouterPath(path)]),
  ) as {
    [Path in T]: ReplaceParams<Path>
  }
}
