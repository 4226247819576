import {
  TextField as MuiTextField,
  InputLabel,
  Box,
  Stack,
  Typography,
  TextFieldProps,
  useTheme,
} from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { forwardRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

type Props = TextFieldProps & {
  withClear?: boolean
  onClearClick?: () => void
  isFilter?: boolean
}

export const TextField = forwardRef<HTMLDivElement | null, Props>(
  ({ label, withClear, onClearClick, isFilter, ...props }, ref) => {
    const id = uuidv4()
    const { t } = useTranslation()
    const theme = useTheme()

    return (
      <Box width="100%">
        <Stack
          direction="row"
          sx={{
            '& .MuiInputLabel-root': isFilter
              ? {
                  ...theme.typography.smallTextSemiBold,
                  color: 'black',
                }
              : {},
          }}
        >
          {label && (
            <InputLabel htmlFor={props.id ?? id} required={props.required}>
              {label}
            </InputLabel>
          )}
          {withClear && (
            <Typography
              variant="smallTextSemiBold"
              color="primary2.main"
              onClick={(event) => {
                if (withClear && onClearClick) {
                  event.stopPropagation()
                  onClearClick()
                }
              }}
              sx={{ ml: 'auto', cursor: 'pointer' }}
            >
              {t('clear')}
            </Typography>
          )}
        </Stack>
        <MuiTextField ref={ref} id={props.id ?? id} {...props} />
      </Box>
    )
  },
)
