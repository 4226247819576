import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { decodeJson, get } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { UserCoordinates } from 'src/lib/use-user-geolocation'
import { TEvent } from '../event/event.codecs'
import { TPlace } from '../place/place.codecs'
import { mapCoordinatesToQueryParam } from '../public.utils'
import { TEventsNearby } from './explore.codecs'

const explore = `cms/public/explore` as const

export type GetExploreBannerInput = LocaleInput

export const getExploreBanner = async (input: GetExploreBannerInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${explore}/banner`, {
      headers,
    }),
    decodeJson(array(TEvent)),
  )
}

export type GetExplorePlacesInput = {
  coordinates: UserCoordinates
} & LocaleInput

export const getExplorePlaces = async (input: GetExplorePlacesInput) => {
  const query = mapCoordinatesToQueryParam(input.coordinates)
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${explore}/places`, {
      query,
      headers,
    }),
    decodeJson(array(TPlace)),
  )
}

export type GetEventsNearbyInput = {
  coordinates: UserCoordinates
} & LocaleInput

export const getEventsNearby = async (input: GetEventsNearbyInput) => {
  const query = mapCoordinatesToQueryParam(input.coordinates)
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${explore}/events/nearby`, {
      query,
      headers,
    }),
    decodeJson(TEventsNearby),
  )
}

export type GetRecommendedEventsInput = LocaleInput

export const getRecommendedEvents = async (
  input: GetRecommendedEventsInput,
) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${explore}/recommendations/events`, {
      headers,
    }),
    decodeJson(array(TEvent)),
  )
}
