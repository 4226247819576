import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.9998 9.94055C10.1028 9.94055 8.55725 8.395 8.55725 6.49805C8.55725 4.60109 10.1028 3.05555 11.9998 3.05555C13.8967 3.05555 15.4423 4.60109 15.4423 6.49805C15.4423 8.395 13.8967 9.94055 11.9998 9.94055ZM13.9998 14.0555C17.2555 14.0555 19.911 16.6919 19.942 19.9405H4.05753C4.08852 16.6918 6.74309 14.0555 9.99975 14.0555H13.9998Z"
        stroke="currentColor"
        strokeWidth="2.115"
        fill="none"
      />
    </SvgIcon>
  )
}
