import { getOrElseW, mapLeft } from 'fp-ts/Either'
import { flow } from 'fp-ts/function'
import { strict } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'

export const throwEnvValidationError = (
  errors: ReadonlyArray<string>,
): never => {
  const prettyErrors = errors.map((error) => `❗ ${error}`).join('\n\n')
  const message =
    'Some environment variables (`process.env`) failed runtime validation. Make sure that all provided variables match the contract and restart the application.'
  throw new Error(
    `${message}\n====================\n${prettyErrors}\n====================`,
  )
}

export const getEnvVariables = flow(
  strict,
  (Codec) => Codec.decode(process.env),
  mapLeft(formatValidationErrors),
  getOrElseW(throwEnvValidationError),
)
