import { boolean, string, type, TypeOf, number, array } from 'io-ts'
import { optionFromNullable, UUID } from 'io-ts-types'
import { TImage } from 'src/api/file'
import { TOptionalString } from 'src/lib/types'

// Organization CLC
export const TCustomerLoyaltyClub = type({
  logo: TImage,
  name: string,
  description: string,
  joined: boolean,
  clcId: UUID,
  colorTheme: optionFromNullable(string),
  organizationName: string,
  organizationSlug: string,
})
export type ICustomerLoyaltyClub = TypeOf<typeof TCustomerLoyaltyClub>

//Organization

export const TOrganization = type({
  name: string,
  logo: TImage,
  description: TOptionalString,
  organizationId: UUID,
  customerLoyaltyClub: optionFromNullable(TCustomerLoyaltyClub),
  city: string,
  country: string,
  headerImage: optionFromNullable(TImage),
  colorTheme: optionFromNullable(string),
  organizationSlug: string,
  eventsCount: number,
  vatNumber: string,
})
export type IOrganization = TypeOf<typeof TOrganization>

// Organization Countries

export const TOrganizationsCountries = array(string)
