import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CurveLineDashed = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 1536 487" {...props} fill="none">
    <path
      d="M-2.86318 442.858C21.4609 378.942 171.999 262.134 444.191 296.784C784.431 340.097 906.076 356.978 1056.68 216.647C1207.28 76.3159 1267.21 37.2848 1508.55 12.8957"
      stroke="#FFCFCF"
      strokeWidth="1.4"
      strokeDasharray="6.99 6.99"
      fill="none"
    />
    <path
      d="M0.176664 455.109C23.7385 390.908 172.876 272.316 445.461 303.725C786.193 342.987 908.03 358.419 1056.95 216.306C1205.88 74.1932 1265.34 34.4518 1506.37 7.19282"
      stroke="#C4C4C4"
      strokeWidth="1.4"
      strokeDasharray="6.99 6.99"
      fill="none"
    />
  </SvgIcon>
)
