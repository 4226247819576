import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ResponsiveStyleValue } from '@mui/system'
import { NextLink } from 'src/components/next-link'

export type FooterSection = {
  title: string
  links: Array<{ label: string; href: string; target?: string }>
  width?: ResponsiveStyleValue<number | string>
}

const hoverStyleProps = {
  textDecoration: 'underline',
  textUnderlineOffset: '4px',
  textDecorationColor: 'transparent',
  transition: '0.4s',
  '&:hover': {
    textDecorationColor: 'currentColor',
  },
} as const

export const FooterSection = ({ title, links, width }: FooterSection) => {
  return (
    <Stack spacing={{ xs: 1, lg: 1.5 }} width={width}>
      <Typography variant="h3ExtraBold">{title}</Typography>
      <Stack spacing={{ xs: 0.5, lg: 1 }}>
        {links.map((link) => (
          <NextLink
            href={link.href}
            key={link.label}
            target={link.target}
            rel="noopener noreferrer"
          >
            <Typography
              variant="bodyRegular"
              color="accent1.main"
              sx={{ ...hoverStyleProps }}
            >
              {link.label}
            </Typography>
          </NextLink>
        ))}
      </Stack>
    </Stack>
  )
}
