import { LinearProgress, SxProps } from '@mui/material'

export const GlobalLoading = ({
  isPageLoading,
  isWithDefaultBackground,
  sx,
}: {
  isPageLoading: boolean
  isWithDefaultBackground?: boolean
  sx?: SxProps
}) => {
  if (isPageLoading) {
    return (
      <LinearProgress
        sx={{
          width: '100%',
          backgroundColor: isWithDefaultBackground
            ? 'transparent'
            : 'primary3.main',
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'accent2.main',
          },
          ...sx,
        }}
      />
    )
  }
  return null
}
