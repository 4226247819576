import { pipe } from 'fp-ts/lib/function'
import { boolean, type } from 'io-ts'
import { decodeJson, get, post } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'

const register = 'auth/register' as const

export type SignUpByEmailInput = {
  email: string
  password: string
  fullName: string
} & LocaleInput

export const signUpByEmail = (input: SignUpByEmailInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return post(`${register}/emails`, {
    body: {
      email: input.email.trim(),
      password: input.password,
      fullName: input.fullName.trim(),
    },
    headers,
  })
}

export type SignUpByPhoneInput = {
  phoneNumber: string
  fullName: string
  clientId: string
  captchaToken: string
} & LocaleInput

export const signUpByPhone = (input: SignUpByPhoneInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return post(`${register}/sms`, {
    body: {
      phoneNumber: input.phoneNumber,
      fullName: input.fullName.trim(),
      clientId: input.clientId,
      captchaToken: input.captchaToken,
    },
    headers,
  })
}

export type CheckPhoneNumberExistInput = {
  phoneNumber: string
}

export const checkPhoneNumberExist = async (
  input: CheckPhoneNumberExistInput,
) => {
  const query = new URLSearchParams({
    phoneNumber: input.phoneNumber,
  })

  return pipe(
    await get(`${register}/phones/exists`, {
      query,
    }),
    decodeJson(type({ value: boolean })),
  )
}
