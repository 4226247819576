import { useState, useEffect } from 'react'
import { EnumType } from '../types'
import constate from 'constate'

type Geolocation = {
  country_code: string
  country_name: string
  city: string
  postal: string
  latitude: number
  longitude: number
  IPv4: string
  state: string
}

export enum IPGeolocationState {
  initHook = 'initHook',
  loading = 'loading',
  finished = 'finished',
}

const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState<Geolocation | null>(null)
  const [geolocationStatus, setGeolocationStatus] = useState<
    EnumType<IPGeolocationState>
  >(IPGeolocationState.initHook)

  useEffect(() => {
    const getUserIPGeolocationInfo = async () => {
      setGeolocationStatus(IPGeolocationState.loading)
      try {
        const response = await fetch('https://geolocation-db.com/json/')
        const data = await response.json()
        setGeolocation(data)
      } catch (error) {
        console.log(error)
      } finally {
        setGeolocationStatus(IPGeolocationState.finished)
      }
    }

    getUserIPGeolocationInfo()
  }, [])

  return {
    geolocationStatus,
    geolocation,
  }
}

export const [IPGeolocationProvider, useIPGeolocation] =
  constate(useGeolocation)
