import styles from './freakflags.module.css'

export type Props = Readonly<{
  countryCode: string
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'sm-guest'
  round?: boolean
  squareRound?: boolean
}>

export const CountryFlag = ({
  countryCode,
  size = 'md',
  round = false,
  squareRound,
}: Props) => {
  const code = countryCode === 'EN' ? 'US' : countryCode

  return (
    <div
      style={{ backgroundImage: `url(/country-flag-sprite.png)` }}
      className={`${styles['fflag']} ${styles[`fflag-${code}`]} ${
        styles[`ff-${size}`]
      } ${round ? styles['ff-round'] : ''} ${
        squareRound ? styles['ff-square-round'] : ''
      }`}
    />
  )
}
