import { SvgIcon, SvgIconProps } from '@mui/material'

export const InfoBigIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.8752 2.37479C9.3556 2.37479 6.93919 3.3757 5.15757 5.15733C3.37595 6.93895 2.37504 9.35535 2.37504 11.8749C2.37504 14.3945 3.37595 16.8109 5.15757 18.5926C6.93919 20.3742 9.3556 21.3751 11.8752 21.3751C14.3948 21.3751 16.8112 20.3742 18.5928 18.5926C20.3744 16.8109 21.3753 14.3945 21.3753 11.8749C21.3753 9.35535 20.3744 6.93895 18.5928 5.15733C16.8112 3.3757 14.3948 2.37479 11.8752 2.37479ZM0 11.8749C0 5.31628 5.31652 -0.000244141 11.8752 -0.000244141C18.4339 -0.000244141 23.7504 5.31628 23.7504 11.8749C23.7504 18.4336 18.4339 23.7501 11.8752 23.7501C5.31652 23.7501 0 18.4336 0 11.8749ZM11.8752 17.8125C12.1901 17.8125 12.4922 17.6874 12.7149 17.4647C12.9376 17.242 13.0627 16.94 13.0627 16.625V11.8749C13.0627 11.56 12.9376 11.2579 12.7149 11.0352C12.4922 10.8125 12.1901 10.6874 11.8752 10.6874C11.5602 10.6874 11.2582 10.8125 11.0355 11.0352C10.8128 11.2579 10.6877 11.56 10.6877 11.8749V16.625C10.6877 16.94 10.8128 17.242 11.0355 17.4647C11.2582 17.6874 11.5602 17.8125 11.8752 17.8125ZM11.8752 5.93735C11.5602 5.93735 11.2582 6.06247 11.0355 6.28517C10.8128 6.50787 10.6877 6.80992 10.6877 7.12487C10.6877 7.43982 10.8128 7.74187 11.0355 7.96457C11.2582 8.18728 11.5602 8.31239 11.8752 8.31239H11.8871C12.202 8.31239 12.5041 8.18728 12.7268 7.96457C12.9495 7.74187 13.0746 7.43982 13.0746 7.12487C13.0746 6.80992 12.9495 6.50787 12.7268 6.28517C12.5041 6.06247 12.202 5.93735 11.8871 5.93735H11.8752Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
