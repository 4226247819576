import {
  array,
  boolean,
  intersection,
  literal,
  number,
  string,
  type,
  TypeOf,
  union,
} from 'io-ts'
import {
  DateFromISOString,
  nonEmptyArray,
  optionFromNullable,
  UUID,
} from 'io-ts-types'
import { TCustomerLoyaltyClub } from 'src/api/auth'
import { enumToKeyof, TOptionalString } from 'src/lib/types'

export enum MinPriceStatus {
  SOLD_OUT = 'SOLD_OUT',
  ON_SALE = 'ON_SALE',
  NOTIFY = 'NOTIFY',
  FREE_WITHOUT_TICKETS = 'FREE_WITHOUT_TICKETS',
}

export const TTicketMinimumPrice = union([
  type({ status: literal(MinPriceStatus.SOLD_OUT) }),
  type({ status: literal(MinPriceStatus.NOTIFY) }),
  type({
    status: literal(MinPriceStatus.ON_SALE),
    minTicketPrice: number,
    feesIncluded: boolean,
  }),
  type({ status: literal(MinPriceStatus.FREE_WITHOUT_TICKETS) }),
])

export enum VariationType {
  PUBLIC = 'PUBLIC',
  PROMO = 'PROMO',
  CLC = 'CLC',
}
export const TVariationType = enumToKeyof(VariationType)
export type IVariationType = TypeOf<typeof TVariationType>

export const TTicketDay = type({
  dayNumber: number,
  date: string,
})
export type ITicketDay = TypeOf<typeof TTicketDay>

const TSeating = union([
  literal('STANDING'),
  literal('FREE_SEATS'),
  literal('FREE_SEATS_AND_STANDING'),
])
export type ISeating = TypeOf<typeof TSeating>

const TCheckoutTicket = type({
  ageLimit: number,
  companionPassAllowed: boolean,
  name: string,
  days: nonEmptyArray(TTicketDay),
  description: TOptionalString,
  ticketId: UUID,
  promoAvailable: boolean,
})

const TCheckoutTicketVariation = type({
  basePrice: number,
  currency: TOptionalString,
  promoCode: TOptionalString,
  fees: number,
  feesIncluded: boolean,
  variationType: TVariationType,
  variationId: UUID,
  availableTicketsForUser: number,
  ticketPrice: number,
  maxTicketQuantityPerUser: number,
})
export type ICheckoutTicketVariation = TypeOf<typeof TCheckoutTicketVariation>

export const TCheckoutTicketWithoutSeatMap = intersection([
  TCheckoutTicket,
  TCheckoutTicketVariation,
  type({
    ticketSeatId: UUID,
    maxTicketQuantityPerUser: number,
    seating: TSeating,
  }),
])
export type ICheckoutTicketWithoutSeatMap = TypeOf<
  typeof TCheckoutTicketWithoutSeatMap
>

export const TCheckoutTicketWithSeatMap = intersection([
  TCheckoutTicket,
  type({
    color: string,
    variations: array(TCheckoutTicketVariation),
  }),
])
export type ICheckoutTicketWithSeatMap = TypeOf<
  typeof TCheckoutTicketWithSeatMap
>

const TCheckout = type({
  customerLoyaltyClub: optionFromNullable(TCustomerLoyaltyClub),
  ticketsWillBeAvailable: optionFromNullable(DateFromISOString),
  anyWillAvailable: boolean,
  promoAvailable: boolean,
  publicAvailable: boolean,
})

export const TCheckoutWithoutSeatMap = intersection([
  TCheckout,
  type({ tickets: array(TCheckoutTicketWithoutSeatMap) }),
])
export type ICheckoutWithoutSeatMap = TypeOf<typeof TCheckoutWithoutSeatMap>

export const TSeatMapLegendItem = type({
  color: string,
  currency: string,
  fromPrice: number,
})
export type ISeatMapLegendItem = TypeOf<typeof TSeatMapLegendItem>

export const TCheckoutWithSeatMap = intersection([
  TCheckout,
  type({
    tickets: array(TCheckoutTicketWithSeatMap),
    seatMapLegend: array(TSeatMapLegendItem),
  }),
])
export type ICheckoutWithSeatMap = TypeOf<typeof TCheckoutWithSeatMap>

export const TCheckTicketsAgeValidationResponse = type({
  theEventAgeRestrictionPassed: boolean,
})

export const TNotifyMeAvailable = type({
  noticeAvailable: boolean,
})

export const TPromoCodeExist = type({
  isPromoCodeValid: boolean,
})
