import {
  TypeOf,
  array,
  intersection,
  literal,
  string,
  type,
  union,
} from 'io-ts'
import { UUID } from 'io-ts-types'
import { enumToKeyof } from 'src/lib/types'
import { TVariationType } from '../../trade'
import { TFile } from 'src/api/file'

export enum SeatMapItemStatus {
  AVAILABLE = 'AVAILABLE',
  RESERVED = 'RESERVED',
  IN_CART = 'IN_CART',
  UNAVAILABLE = 'UNAVAILABLE',
}

export const TSeatMapItemStatus = enumToKeyof(SeatMapItemStatus)
export type ISeatMapItemStatus = TypeOf<typeof TSeatMapItemStatus>

export enum SeatMapItemType {
  SEATING = 'SEATING',
  STANDING = 'STANDING',
  WHEELCHAIR = 'WHEELCHAIR',
  WHEELCHAIR_AREA = 'WHEELCHAIR_AREA',
}

export const TSeatMapItemType = enumToKeyof(SeatMapItemType)
export type ISeatMapItemType = TypeOf<typeof TSeatMapItemType>

const TSeatMapSeat = type({
  number: string,
  row: string,
  type: union([
    literal(SeatMapItemType.SEATING),
    literal(SeatMapItemType.WHEELCHAIR),
  ]),
})

const TSeatMapZone = type({
  type: union([
    literal(SeatMapItemType.STANDING),
    literal(SeatMapItemType.WHEELCHAIR_AREA),
  ]),
})

const TSeatMapUnavailableItem = type({
  status: union([
    literal(SeatMapItemStatus.RESERVED),
    literal(SeatMapItemStatus.UNAVAILABLE),
  ]),
})

const TSeatMapAvailableItem = type({
  status: union([
    literal(SeatMapItemStatus.IN_CART),
    literal(SeatMapItemStatus.AVAILABLE),
  ]),
  ticketId: UUID,
  color: string,
})

export const TSeatMapItem = intersection([
  type({
    id: string,
    seatId: UUID,
    section: string,
    availableVariations: array(TVariationType),
  }),
  union([TSeatMapSeat, TSeatMapZone]),
  union([TSeatMapUnavailableItem, TSeatMapAvailableItem]),
])
export type ISeatMapItem = TypeOf<typeof TSeatMapItem>

export const TEventSeatMap = type({
  file: TFile,
  items: array(TSeatMapItem),
})
export type IEventSeatMap = TypeOf<typeof TEventSeatMap>
