import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import {
  decodeJsonWithTotal,
  InfiniteScrollInput,
  InfiniteScrollPaginationInput,
} from 'src/lib/get-list'
import { decodeJson, get } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { UserCoordinates } from 'src/lib/use-user-geolocation'
import { TEvent } from '../event/event.codecs'
import {
  mapCoordinatesToQueryParam,
  mapSeeAllQueryParams,
} from '../public.utils'
import {
  TPlace,
  TPlacesCountries,
  TPlaceDetails,
  TPlacesSelectList,
} from './place.codecs'
import { EnumType } from 'src/lib/types'
import { IMonth, TMonths } from '../public.codecs'
import { isEmpty } from 'fp-ts/lib/string'

const places = `cms/public/places` as const

export enum EntityToCoverOption {
  EVENT = 'EVENT',
  VENUE = 'VENUE',
}

export type GetPlaceInput = {
  placeSlug: string
} & LocaleInput

export const getPlace = async (input: GetPlaceInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${places}/${input.placeSlug}`, {
      headers,
    }),
    decodeJson(TPlaceDetails),
  )
}

export type GetPlaceMonthsInput = {
  placeSlug: string
}

export const getPlaceMonths = async (input: GetPlaceMonthsInput) => {
  return pipe(
    await get(`${places}/${input.placeSlug}/events/months`, {}),
    decodeJson(TMonths),
  )
}

export type GetPlaceEventsInput = InfiniteScrollPaginationInput & {
  placeSlug: string
  month: IMonth
} & LocaleInput

export const getPlaceEvents = async ({
  pageParam = 0,
  input,
}: InfiniteScrollInput<GetPlaceEventsInput>) => {
  const query = new URLSearchParams({
    limit: String(input.limit),
    skip: String(input.limit * pageParam),
  })
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${places}/${input.placeSlug}/months/${input.month}/events`, {
      query,
      headers,
    }),
    decodeJsonWithTotal(TEvent),
  )
}

export type GetPlacesSelectListInput = {
  entityToCover: EnumType<EntityToCoverOption>
  country: string | null
} & LocaleInput

export const getPlacesSelectList = async (input: GetPlacesSelectListInput) => {
  const query = new URLSearchParams({
    entityToCover: input.entityToCover,
  })

  const { headers } = setAcceptLanguageHeader(input.locale)

  if (input.country !== null && !isEmpty(input.country)) {
    query.set('country', input.country)
  }

  return pipe(
    await get(`${places}/select-list`, {
      headers,
      query,
    }),
    decodeJson(array(TPlacesSelectList)),
  )
}

export type GetPlacesCountriesInput = {
  coordinates: UserCoordinates
}
export const getPlacesCountries = async (input: GetPlacesCountriesInput) => {
  const query = mapCoordinatesToQueryParam(input.coordinates)
  return pipe(
    await get(`${places}/countries`, {
      query,
    }),
    decodeJson(array(TPlacesCountries)),
  )
}

export enum PlaceSortOption {
  PUBLISHED_EVENT_COUNT = 'PUBLISHED_EVENT_COUNT',
  DISTANCE = 'DISTANCE',
}

export type GetPlacesListInput = InfiniteScrollPaginationInput & {
  coordinates: UserCoordinates
  country: string
  sort: string
}

export const getPlacesList = async ({
  pageParam = 0,
  input: { coordinates, country, limit, sort },
}: InfiniteScrollInput<GetPlacesListInput>) => {
  const query = mapSeeAllQueryParams({
    coordinates,
    limit,
    pageParam,
  })
  query.set('sort', sort)
  if (country) {
    query.set('country', country)
  }

  return pipe(
    await get(places, {
      query,
    }),
    decodeJsonWithTotal(TPlace),
  )
}
