import { pipe } from 'fp-ts/lib/function'
import { decodeJson, del, post } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { TAuthResult } from './token.codecs'
import { defaultApplicationLocale } from 'src/app/locale'

const tokens = 'auth/tokens' as const

export type IssueTokenInput = {
  email: string
  password: string
  clientId: string
} & Partial<LocaleInput>

export const issueTokenRequest = async (input: IssueTokenInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale ?? defaultApplicationLocale)

  return await post(`${tokens}/credentials/emails`, {
    body: {
      clientId: input.clientId,
      email: input.email.trim(),
      password: input.password,
    },
    headers,
  })
}

export const issueToken = async (input: IssueTokenInput) => {
  return pipe(await issueTokenRequest(input), decodeJson(TAuthResult))
}

type RefreshTokenInput = {
  clientId: string
  headers?: Headers
}

export const refreshTokenRequest = async (input: RefreshTokenInput) => {
  return await post(`${tokens}/refresh`, {
    body: {
      clientId: input.clientId,
    },
    headers: input.headers,
  })
}

export const refreshToken = async (input: RefreshTokenInput) => {
  return pipe(await refreshTokenRequest(input), decodeJson(TAuthResult))
}

export const signOut = async () => {
  return del(`${tokens}/me`, {})
}

type SendCodeForSignInInput = {
  phoneNumber: string
} & LocaleInput

export const sendCodeForSignIn = (input: SendCodeForSignInInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return post(`${tokens}/credentials/sms`, {
    body: {
      phoneNumber: input.phoneNumber,
    },
    headers,
  })
}
