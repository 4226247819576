import { Button, Dialog, Stack, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { pages } from 'src/app/pages'
import { DialogHeader } from 'src/components/dialog-header'
import { NextLink } from 'src/components/next-link'
import { getRelativePathWithLocale } from 'src/lib/get-path-with-locale'
import { useLocale } from 'src/lib/use-locale'

type Props = {
  onClose: () => void
}

export const DialogSignIn = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const router = useRouter()

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: 324, sm: 414, lg: 552 },
        },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          background: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(8px)',
        },
      }}
    >
      <DialogHeader onClose={onClose} />
      <Stack sx={{ py: { xs: 4, lg: 5 }, px: { xs: 3, sm: 4, lg: 5 } }}>
        <Stack spacing={{ xs: 3, sm: 4 }}>
          <Stack spacing={{ xs: 2 }}>
            <Typography variant="h2Black" color="primary3.main">{`👋 ${t(
              'nice_to_meet_you',
            )}`}</Typography>
            <Stack spacing={{ xs: 1, lg: 1.5 }}>
              <Typography variant="h4">
                {t('to_buy_tickets_we_require_account')}
              </Typography>
              <Typography variant="bodyRegular">
                {t('some_information_we_ask_is_necessary')}
              </Typography>
            </Stack>
          </Stack>
          <NextLink
            href={{
              query: {
                callbackUrl: getRelativePathWithLocale({
                  locale,
                  path: router.asPath,
                }),
              },
              pathname: pages['/sign-in'],
            }}
            underline="none"
          >
            <Button variant="primary" size="big" fullWidth>
              {t('continue')}
            </Button>
          </NextLink>
        </Stack>
      </Stack>
    </Dialog>
  )
}
