import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CurveLine = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 17" {...props}>
    <path
      d="M3.00821 12.5531C3.00821 12.5531 8.25681 16.1799 9.03759 12.3722C9.8184 8.56466 6.37406 5.13797 9.93837 4.33655C13.5026 3.53523 19.7149 11.8363 19.6723 2.76343"
      stroke="currentColor"
      strokeWidth="4.03354"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)
