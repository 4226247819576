export const CheckboxSelectedIcon = () => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.749 0.96541C13.0837 1.30014 13.0837 1.84286 12.749 2.1776L5.89182 9.0348C5.55706 9.3695 5.01436 9.3695 4.67963 9.0348L1.25105 5.60621C0.916315 5.27144 0.916315 4.72877 1.25105 4.394C1.58579 4.05923 2.1285 4.05923 2.46323 4.394L5.04329 6.97408C5.17718 7.10793 5.39427 7.10793 5.52819 6.97408L11.5368 0.96541C11.8715 0.63067 12.4142 0.63067 12.749 0.96541Z"
      fill="#4AC398"
      stroke="#4AC398"
      strokeWidth="0.8"
    />
  </svg>
)
