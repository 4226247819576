import { env } from 'src/app/env/env'
import { defaultApplicationLocale } from 'src/app/locale'

export const getRelativePathWithLocale = ({
  locale,
  path,
}: {
  locale: string
  path: string
}) => {
  const localePath = locale === defaultApplicationLocale ? '' : `/${locale}`

  return `${localePath}${path}`
}

export const getAbsoluteUrlWithLocale = ({
  locale,
  path,
}: {
  locale: string
  path: string
}) => {
  return `${env.originUrl}${getRelativePathWithLocale({
    locale,
    path,
  })}`
}
