import { isNonEmpty } from 'fp-ts/lib/Array'
import { throwEnvValidationError } from './env-variables'

export const validateClientSideEnvVariables = <T extends string>(
  env: Record<T, undefined | string>,
) => {
  const missedEnvironments = Object.entries(env).filter(
    ([, value]) => value === undefined,
  )

  if (isNonEmpty(missedEnvironments)) {
    throwEnvValidationError(
      missedEnvironments.map(
        ([variable]) => `${variable} environment variable is missed.`,
      ),
    )
  }

  return env as Record<T, string>
}
