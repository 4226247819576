import { Mixed, intersection, literal, string, type, union } from 'io-ts'
import { UUID } from 'io-ts-types'
import { EnumType, enumToKeyof } from 'src/lib/types'

export enum PurchaseStatus {
  SUCCESS = 'SUCCESS',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  FAILED = 'FAILED',
}

export enum CheckoutProvider {
  STRIPE = 'STRIPE',
  VIPPS = 'VIPPS',
}
export const TCheckoutProvider = enumToKeyof(CheckoutProvider)
export type ICheckoutProvider = EnumType<CheckoutProvider>

const createCheckoutStatusCodec = <T extends Mixed>(codec: T) =>
  intersection([
    type({
      liveOrderId: UUID,
    }),
    union([
      type({
        purchaseStatus: union([
          literal(PurchaseStatus.SUCCESS),
          literal(PurchaseStatus.FAILED),
        ]),
      }),
      type({
        purchaseStatus: literal(PurchaseStatus.REQUIRES_ACTION),
        challengeResponse: codec,
      }),
    ]),
  ])

export const TCheckoutStatus = createCheckoutStatusCodec(
  type({ clientSecret: string, payload: string }),
)

export const TVippsCheckoutStatus = createCheckoutStatusCodec(
  type({ redirectUrl: string }),
)

export enum OrderPaymentStatus {
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
}

export const TOrderPaymentStatus = type({
  status: enumToKeyof(OrderPaymentStatus),
})
