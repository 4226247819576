import { pipe } from 'fp-ts/lib/function'
import { array, string } from 'io-ts'
import {
  decodeJsonWithTotal,
  InfiniteScrollInput,
  InfiniteScrollPaginationInput,
} from 'src/lib/get-list'
import { decodeJson, get, post } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { TEvent } from '../event/event.codecs'
import {
  eventRowPreviewQuery,
  mapInfinitePaginationToUrlParams,
} from '../public.utils'
import {
  TArtist,
  TArtistDetails,
  TArtistRow,
  TArtistTag,
} from './artist.codecs'
import { isEmpty } from 'fp-ts/Array'

const artists = 'cms/public/performers' as const

export type GetArtistInput = {
  performerSlug: string
} & LocaleInput

export const getArtist = async (input: GetArtistInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${artists}/${input.performerSlug}`, {
      headers,
    }),
    decodeJson(TArtistDetails),
  )
}

export type getArtistEventsInput = {
  performerSlug: string
} & LocaleInput

export const getArtistEvents = async (input: getArtistEventsInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${artists}/${input.performerSlug}/events`, {
      query: eventRowPreviewQuery,
      headers,
    }),
    decodeJsonWithTotal(TEvent),
  )
}

export type GetArtistRecommendationsInput = {
  performerSlug: string
}

export const getArtistRecommendations = async (
  input: GetArtistRecommendationsInput,
) => {
  return pipe(
    await get(`${artists}/${input.performerSlug}/recommendations`, {}),
    decodeJson(array(TArtist)),
  )
}

export const getArtistsCountries = async () => {
  return pipe(await get(`${artists}/countries`, {}), decodeJson(array(string)))
}

export type GetArtistsTagsInput = LocaleInput

export const getArtistsTags = async (input: GetArtistsTagsInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${artists}/tags`, {
      headers,
    }),
    decodeJson(array(TArtistTag)),
  )
}

export type GetArtistsListInput = {
  search: string
  countries: Array<string>
  tagIds: Array<string>
} & LocaleInput &
  InfiniteScrollPaginationInput

export const getArtistsList = async ({
  pageParam = 0,
  input: { limit, locale, search, countries, tagIds },
}: InfiniteScrollInput<GetArtistsListInput>) => {
  const { headers } = setAcceptLanguageHeader(locale)

  const query = mapInfinitePaginationToUrlParams({
    limit,
    pageParam,
  })

  if (search.trim() !== '') {
    query.set('search', search)
  }

  const body = {
    countries: !isEmpty(countries)
      ? countries.filter((country) => country !== '')
      : undefined,
    tagIds: !isEmpty(tagIds) ? tagIds.filter((tag) => tag !== '') : undefined,
  }

  return pipe(
    await post(`${artists}/search`, {
      headers,
      body,
      query,
    }),
    decodeJsonWithTotal(TArtistRow),
  )
}
