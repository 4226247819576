import { UUID } from 'io-ts-types'
import { pipe } from 'fp-ts/lib/function'
import { TFavoriteCategories, TFavoriteArtist } from './favorites.codecs'
import { TEvent } from '../public'
import { decodeJson, get, put } from 'src/lib/request'
import {
  decodeJsonWithTotal,
  InfiniteScrollPaginationInput,
  InfiniteScrollInput,
} from 'src/lib/get-list'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { mapInfinitePaginationToUrlParams } from '../public/public.utils'

const favorites = 'cms/favourites' as const
const favoriteArtists = `${favorites}/performers` as const
const favoriteEvents = `${favorites}/events` as const

type AddFavoriteArtistInput = {
  artistId: UUID
  favourite: boolean
}

export const addFavoriteArtist = (input: AddFavoriteArtistInput) => {
  const query = new URLSearchParams({ favourite: String(input.favourite) })

  return put(`${favoriteArtists}/:performerId`, {
    params: { performerId: input.artistId },
    query: query,
  })
}

export const getFavoriteCategory = async () => {
  return pipe(await get(favorites, {}), decodeJson(TFavoriteCategories))
}

export type GetFavoriteArtistsInput = InfiniteScrollPaginationInput

export const getFavoriteArtists = async ({
  pageParam = 0,
  input: { limit },
}: InfiniteScrollInput<GetFavoriteArtistsInput>) => {
  const query = mapInfinitePaginationToUrlParams({
    pageParam,
    limit,
  })

  return pipe(
    await get(favoriteArtists, {
      query,
    }),
    decodeJsonWithTotal(TFavoriteArtist),
  )
}

type AddFavoriteEventInput = {
  eventId: UUID
  favourite: boolean
}

export const addFavoriteEvent = (input: AddFavoriteEventInput) => {
  const query = new URLSearchParams({ favourite: String(input.favourite) })

  return put(`${favoriteEvents}/:eventId`, {
    params: { eventId: input.eventId },
    query,
  })
}

export type GetFavoriteEventsInput = InfiniteScrollPaginationInput & LocaleInput

export const getFavoriteEvents = async ({
  pageParam = 0,
  input: { limit, locale },
}: InfiniteScrollInput<GetFavoriteEventsInput>) => {
  const query = mapInfinitePaginationToUrlParams({
    pageParam,
    limit,
  })

  const { headers } = setAcceptLanguageHeader(locale)

  return pipe(
    await get(favoriteEvents, {
      query,
      headers,
    }),
    decodeJsonWithTotal(TEvent),
  )
}
