export const palette = {
  resolutionBlue: '#00247A',
  carnation: '#FC605B',
  supernova: '#FACA08',
  puertoRico: '#4AC398',
  heliotrope: '#9E7BFF',
  orange: '#FA9908',
  mintTulip: '#CCF6E8',
  cosmos: '#FFCFCF',
  black: '#1D1D1D',
  gallery: '#F0F0F0',
  white: '#FFFFFF',
  lightGray: '#EAE9E9',
  gray: '#C4C4C4',
  darkGray: '#787878',
  wetAsphalt: '#4C4C4C',
  strongBlue: '#1877F2',
  background: '#F9FAFB',
  middleBlue: '#EBF2FB',
  middleGreen: '#E6F0ED',
  middleGrey: '#A9A9A9',
  greenCards: '#E9F8F3',
  lightBlue: '#F1F7FE',
  lightRed: '#FFF7F7',
  lightGreen: '#F6FCFA',
  lightOrange: '#F5EDE1',
  paleRed: '#FEA6A3',
  buttonPrimaryHover: '#F2C201',
  buttonPrimaryPressed: '#E9BC07',
  buttonPrimaryDisabled: '#FDE895',
  buttonSecondaryHover: '#F4F4F4',
  buttonSecondaryPressed: '#ECECEC',
  buttonCancelHover: '#FFFBFB',
  buttonDestructiveHover: '#E94C47',
  buttonDestructivePressed: '#CA4D49',
  fieldsDisabled: '#F7F6F6',
} as const
