import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const MenuIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7V7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7V7ZM8 12C8 11.4477 8.44772 11 9 11H19C19.5523 11 20 11.4477 20 12V12C20 12.5523 19.5523 13 19 13H9C8.44771 13 8 12.5523 8 12V12ZM13 17C13 16.4477 13.4477 16 14 16H19C19.5523 16 20 16.4477 20 17V17C20 17.5523 19.5523 18 19 18H14C13.4477 18 13 17.5523 13 17V17Z"
        fill="white"
      />
    </SvgIcon>
  )
}
