import { SvgIcon, SvgIconProps } from '@mui/material'

export const ChevronIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.7319 8.14584C5.26015 7.74149 4.54994 7.79612 4.14559 8.26786C3.74124 8.7396 3.79587 9.44981 4.26762 9.85416L5.7319 8.14584ZM19.7319 9.85416C20.2036 9.44981 20.2583 8.7396 19.8539 8.26786C19.4496 7.79612 18.7394 7.74149 18.2676 8.14584L19.7319 9.85416ZM11.9998 15L11.2676 15.8542L11.9998 16.4817L12.7319 15.8542L11.9998 15ZM4.26762 9.85416L11.2676 15.8542L12.7319 14.1458L5.7319 8.14584L4.26762 9.85416ZM12.7319 15.8542L19.7319 9.85416L18.2676 8.14584L11.2676 14.1458L12.7319 15.8542Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
