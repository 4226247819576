import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { UUID } from 'io-ts-types'
import { decodeJson, get, post } from 'src/lib/request'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import { TPurchasedTicket } from './user.codecs'

const purchases = 'purchases' as const

export type GetPurchasedTicketsInput = {
  eventSlug: string
} & LocaleInput

export const getPurchasedTickets = async (input: GetPurchasedTicketsInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await get(`${purchases}/events/${input.eventSlug}/tickets/me`, { headers }),
    decodeJson(array(TPurchasedTicket)),
  )
}

export type SendPurchasedTicketInput = {
  purchaseId: UUID
  email: string
  contactEmail: boolean
} & LocaleInput

export const sendPurchasedTicket = async (input: SendPurchasedTicketInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return await post(`${purchases}/:purchaseId/me/pdf`, {
    params: { purchaseId: input.purchaseId },
    body: {
      email: input.email.trim(),
      contactEmail: input.contactEmail,
    },
    headers,
  })
}
