import { Box, InputAdornment, TextField } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useTheme } from '@mui/material'
import { DashedLine } from './dashed-line'
import { SearchIcon } from 'src/assets/icons'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import { pages } from 'src/app/pages'
import { useEffect } from 'react'

const autoCompleteStyleOverride = {
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
    {
      WebkitBoxShadow: '0 0 0 30px #00247A inset !important',
      borderRadius: 0,
    },
  'input:-webkit-autofill': {
    WebkitTextFillColor: '#fff !important',
  },
} as const

const searchSchema = z.object({
  search: z.string(),
})
type SearchFieldValues = z.infer<typeof searchSchema>

type Props = {
  onSubmit: () => void
}

export const MenuSearchForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const router = useRouter()

  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm<SearchFieldValues>({
    defaultValues: { search: '' },
    resolver: zodResolver(searchSchema),
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form
      noValidate
      style={{ width: '100%' }}
      onSubmit={handleSubmit(({ search }) => {
        const preparedSearch = search.trim()

        if (preparedSearch !== '') {
          onSubmit()
          router.push({
            pathname: pages['/events'],
            query: {
              search: preparedSearch,
            },
          })
        }
      })}
    >
      <Box>
        <Controller
          control={control}
          name="search"
          render={({ field }) => (
            <TextField
              {...field}
              placeholder={t('search_events')}
              sx={{
                ...autoCompleteStyleOverride,
                '.MuiInputBase-root': {
                  backgroundColor: 'transparent',
                  border: 'none',
                  p: 0,
                  '& fieldset': {
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: 0,
                    p: 0,
                  },
                  '&:hover': {
                    background: 'none',
                  },
                  '&.Mui-disabled': {
                    color: theme.palette.secondary7.main,
                    'input:disabled': {
                      WebkitTextFillColor: theme.palette.secondary7.main,
                    },
                    fieldset: {
                      backgroundColor: 'transparent !important',
                    },
                  },
                  '& input': {
                    pl: 0,
                    pr: '8px',
                    color: 'common.white',
                    '&::placeholder': {
                      color: 'common.white',
                    },
                  },
                  '&:hover input': {
                    backgroundColor: 'transparent',
                  },
                  ...theme.typography.h4,
                  '& input::placeholder': {
                    ...theme.typography.h4,
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{
                        color: 'common.white',
                        fontSize: { xs: 22, sm: 28, lg: 24, xl: 28 },
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <DashedLine color={theme.palette.accent1.main} opacity="0.6" />
      </Box>
    </form>
  )
}
