import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_5398_4990)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.18076 4.11758C3.74141 4.55693 3.74141 5.26924 4.18075 5.70858L10.4332 11.961L4.84341 17.5508C4.40407 17.9901 4.40407 18.7024 4.84342 19.1418C5.28276 19.5811 5.99507 19.5811 6.43441 19.1418L12.0242 13.552L17.6135 19.1413C18.0528 19.5806 18.7651 19.5806 19.2044 19.1413C19.6438 18.7019 19.6438 17.9896 19.2044 17.5503L13.6152 11.961L19.8671 5.70906C20.3064 5.26972 20.3064 4.55741 19.8671 4.11807C19.4278 3.67873 18.7155 3.67873 18.2761 4.11807L12.0242 10.37L5.77175 4.11758C5.33241 3.67824 4.6201 3.67824 4.18076 4.11758Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5398_4990">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
