import { useMediaQuery } from '@mui/material'
import { theme } from 'src/app/theme'

export const tabletBreakpoint = theme.breakpoints.up('sm')
export const smallDesktopBreakpoint = theme.breakpoints.up('lg')
export const bigDesktopBreakpoint = theme.breakpoints.up('xl')

export const useCurrentBreakpoint = () => {
  const isTablet = useMediaQuery(tabletBreakpoint)
  const isSmallDesktop = useMediaQuery(smallDesktopBreakpoint)
  const isBigDesktop = useMediaQuery(bigDesktopBreakpoint)

  const breakpointMap = [
    {
      breakpoint: bigDesktopBreakpoint,
      isCurrent: isBigDesktop,
    },
    {
      breakpoint: smallDesktopBreakpoint,
      isCurrent: isSmallDesktop,
    },
    {
      breakpoint: tabletBreakpoint,
      isCurrent: isTablet,
    },
  ]

  return breakpointMap.find(({ isCurrent }) => isCurrent)?.breakpoint ?? null
}
