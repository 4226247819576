import { format } from 'date-fns'
import { pipe } from 'fp-ts/lib/function'
import { matchW, fromNullable } from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post, put } from 'src/lib/request'
import {
  EnumType,
  mapOptionalFieldToPayload,
  mapOptionalStringFieldToPayload,
} from 'src/lib/types'
import { LocaleInput, setAcceptLanguageHeader } from 'src/lib/use-locale'
import {
  IAuthTypes,
  TContacts,
  TProfile,
  TSignInMethod,
  UserGender,
} from './user.codecs'

const myProfile = 'auth/users/me' as const
const mySecurity = 'auth/security/me' as const

export const getOwnProfile = async () => {
  return pipe(await get(myProfile, {}), decodeJson(TProfile))
}

export type UpdateOwnProfileInput = {
  fullName: string
  dateOfBirth: Date | null
  gender: EnumType<UserGender> | null
  placeId: string | null
}

export const updateOwnProfile = async (input: UpdateOwnProfileInput) => {
  return pipe(
    await put('auth/users/me', {
      body: {
        fullName: input.fullName.trim(),
        dateOfBirth: pipe(
          input.dateOfBirth,
          fromNullable,
          matchW(
            () => null,
            (date) => format(date, 'yyyy-MM-dd'),
          ),
        ),
        gender: mapOptionalFieldToPayload(input.gender),
        placeId: mapOptionalFieldToPayload(input.placeId),
      },
    }),
    decodeJson(TProfile),
  )
}

type ChangeOwnPasswordInput = {
  oldPassword: string
  newPassword: string
}

export const changeOwnPassword = async (input: ChangeOwnPasswordInput) => {
  return pipe(
    await patch('auth/users/me/password', {
      body: {
        oldPassword: input.oldPassword,
        newPassword: input.newPassword,
      },
    }),
  )
}

type UpdateProfileImageInput = {
  fileId: UUID
}

export const updateProfileImage = async (input: UpdateProfileImageInput) => {
  return pipe(
    await patch('auth/users/me/images', {
      body: {
        imageId: input.fileId,
      },
    }),
  )
}

export const deleteProfileImage = async () => {
  return await del('auth/users/me/images', {})
}

export const getContacts = async () => {
  return pipe(await get(`${myProfile}/contacts`, {}), decodeJson(TContacts))
}

type SubscribeMailingInput = {
  contactEmail: string
}

export const subscribeToMailing = async (input: SubscribeMailingInput) => {
  const query = new URLSearchParams({ contactEmail: input.contactEmail.trim() })
  return pipe(await patch(`${myProfile}/mailing/subscribe`, { query }))
}

export const unsubscribeFromMailing = async () => {
  return pipe(await patch(`${myProfile}/mailing/unsubscribe`, {}))
}

export type UpdateContactsInput = {
  contactEmail?: string
  contactPhone?: string
}

export const updateContacts = async (input: UpdateContactsInput) => {
  return pipe(
    await put('auth/users/me/contacts', {
      body: {
        contactEmail: mapOptionalStringFieldToPayload(input.contactEmail),
        contactPhone: mapOptionalStringFieldToPayload(input.contactPhone),
      },
    }),
    decodeJson(TContacts),
  )
}

export const getSignInMethods = async () => {
  return pipe(await get(mySecurity, {}), decodeJson(TSignInMethod))
}

type AddSignInMethodInput = {
  authType: IAuthTypes
  subject: string
} & LocaleInput

export const addSignInMethod = async (input: AddSignInMethodInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await post(mySecurity, {
      body: {
        authType: input.authType,
        subject: input.subject,
      },
      headers,
    }),
  )
}

type UpdateSignInMethodInput = {
  authType: IAuthTypes
  subject: string
} & LocaleInput

export const updateSignInMethod = async (input: UpdateSignInMethodInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return pipe(
    await patch(mySecurity, {
      body: {
        authType: input.authType,
        subject: input.subject,
      },
      headers,
    }),
  )
}

export const cancelEmailChange = async () => {
  return await del('auth/confirmations/emails/change', {})
}

export const resendEmailChange = async (input: LocaleInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return await post('auth/confirmations/emails/change', { headers })
}

export const resendPhoneChange = async (input: LocaleInput) => {
  const { headers } = setAcceptLanguageHeader(input.locale)

  return await post('auth/confirmations/sms/change', { headers })
}

export const deleteOwnProfile = async () => {
  return await del('auth/users/delete', {})
}
