import { Dialog, Stack, Typography, Box } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { z } from 'zod'
import { Translate } from 'next-translate'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { TextField } from '../text-field'
import { emailSchema, textFieldError } from 'src/lib/validations'
import { qk, subscribeToMailing } from 'src/api'
import { useMutation, useQueryClient } from 'react-query'
import { LoadingButton } from '@mui/lab'
import { ClientError } from '../client-error'
import { DialogHeader } from '../dialog-header'

const textWordWrapProps = {
  wordWrap: 'break-word',
} as const

export const createNewsletterEmailSchema = (t: Translate) =>
  z.object({
    contactEmail: emailSchema(t),
  })

export type NewsletterEmailFieldValues = z.infer<
  ReturnType<typeof createNewsletterEmailSchema>
>

type Props = {
  onClose: () => void
  defaultEmail: string
}

export const DialogSubscribeNewsletter = ({ onClose, defaultEmail }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const schema = createNewsletterEmailSchema(t)

  const $subscribeToMailing = useMutation(subscribeToMailing)

  const { control, handleSubmit } = useForm<NewsletterEmailFieldValues>({
    defaultValues: {
      contactEmail: defaultEmail,
    },
    resolver: zodResolver(schema),
  })

  const onSubmit = ({ contactEmail }: NewsletterEmailFieldValues) => {
    $subscribeToMailing.mutate(
      { contactEmail },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(qk.auth.user.contacts.toKey())
          onClose()
        },
      },
    )
  }

  return (
    <Dialog onClose={onClose} open>
      <DialogHeader onClose={onClose} />
      <Stack
        spacing={{ xs: 2 }}
        sx={{ px: { xs: 3, sm: 4, lg: 5 }, py: { xs: 4, lg: 5 } }}
      >
        <Typography color="primary3.main" variant="h2Black">
          {`📨 ${t('stay_up_to_date')}`}
        </Typography>
        <Stack spacing={{ xs: 1, lg: 1.5 }}>
          <Typography variant="h4">{`${t(
            'explore_the_latest',
          )}! 😎`}</Typography>
          <Typography variant="bodyRegular">
            {t('explore_the_latest_dialog_description')}
          </Typography>
        </Stack>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={{ xs: 3, lg: 4 }}>
            {defaultEmail === '' ? (
              <Box>
                <Controller
                  control={control}
                  name="contactEmail"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      helperText={t('newsletter_email_helper_text')}
                      {...textFieldError(fieldState.error)}
                      label={t('email')}
                      placeholder={t('email')}
                      required
                    />
                  )}
                />
              </Box>
            ) : (
              <Stack spacing={{ lg: 0.25 }}>
                <Typography color="secondary8.main" variant="smallTextSemiBold">
                  {t('newsletter_will_sent_to')}
                </Typography>
                <Typography variant="h4" sx={textWordWrapProps}>{defaultEmail}</Typography>
              </Stack>
            )}
            <ClientError error={$subscribeToMailing.error} />
            <LoadingButton
              loading={$subscribeToMailing.isLoading}
              variant="primary"
              size="big"
              type="submit"
            >
              {t('subscribe')}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  )
}
