import { typography } from 'src/app/theme/typography'

type Typography = typeof typography
type TypographyVariant = Record<
  string,
  Record<string, string | number> | string | number
>

export const getTypographyProperty = ({
  typographyVariant,
  currentBreakpoint,
  fontProperty,
}: {
  typographyVariant: keyof Typography
  currentBreakpoint: string | null
  fontProperty: keyof typeof typography[keyof Typography]
}) => {
  if (currentBreakpoint === null) {
    return String(typography[typographyVariant][fontProperty] ?? '')
  }

  const breakpointStyles = (typography[typographyVariant] as TypographyVariant)[
    currentBreakpoint
  ]

  return typeof breakpointStyles === 'object'
    ? String(breakpointStyles[fontProperty] ?? '')
    : ''
}
