import { Stack } from '@mui/system'
import {
  AmericanExpressLogoIcon,
  ApplePayLogoIcon,
  GooglePayLogoIcon,
  MastercardLogoIcon,
  VippsLogoIcon,
  VisaWhiteLogoIcon,
} from 'src/assets/icons'

export const FooterPaymentProviders = () => {
  const PaymentLogosFirstRow = (
    <>
      <VippsLogoIcon sx={{ width: { xs: 53, sm: 60 }, height: 'auto' }} />
      <VisaWhiteLogoIcon sx={{ width: { xs: 81 }, height: 'auto' }} />
      <MastercardLogoIcon sx={{ width: { xs: 45, sm: 52 }, height: 'auto' }} />
      <AmericanExpressLogoIcon sx={{ width: { xs: 40 }, height: 'auto' }} />
    </>
  )

  const PaymentLogosSecondRow = (
    <>
      <ApplePayLogoIcon sx={{ width: { xs: 55, sm: 62 }, height: 'auto' }} />
      <GooglePayLogoIcon sx={{ width: { xs: 66, sm: 75 }, height: 'auto' }} />
    </>
  )

  // TODO: move to css grid
  return (
    <Stack>
      <Stack spacing={{ xs: '13px' }} display={{ sm: 'none' }}>
        <Stack spacing={{ xs: 3.5 }} direction="row" alignItems="center">
          {PaymentLogosFirstRow}
        </Stack>
        <Stack spacing={{ xs: 3.5 }} direction="row" alignItems="center">
          {PaymentLogosSecondRow}
        </Stack>
      </Stack>
      <Stack
        display={{ xs: 'none', sm: 'flex' }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {PaymentLogosFirstRow}
        {PaymentLogosSecondRow}
      </Stack>
    </Stack>
  )
}
