import { Box, Stack, Divider, Typography } from '@mui/material'
import { FabniteFooterIcon } from './icons/fabnite'
import useTranslation from 'next-translate/useTranslation'
import { Container } from '@mui/system'
import { useMemo } from 'react'
import { FooterSection } from './footer-section'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'
import { ExploreButton } from 'src/components/explore-button'
import { globalAccessToken } from 'src/lib/use-auth'
import { DialogSubscribeNewsletter } from 'src/components/dialog-subscribe-newsletter'
import { qk, getContacts } from 'src/api'
import { useQuery } from 'react-query'
import { toNullable } from 'fp-ts/lib/Option'
import { usePreview } from 'src/lib/use-preview'
import { AppLinks } from './app-links/app-links'
import { FooterPaymentProviders } from './footer-payment-providers'

export const Footer = () => {
  const { t } = useTranslation()
  const dialogSubscribeNewsletter = usePreview<{ contactEmail: string }>()

  const $contacts = useQuery(qk.auth.user.contacts.toKey(), getContacts, {
    enabled: globalAccessToken !== null,
    refetchOnWindowFocus: false,
  })

  const footerSections: Array<FooterSection> = useMemo(
    () => [
      {
        title: t('terms_and_privacy'),
        links: [
          {
            href: pages['/purchase-policy'],
            label: t('purchase_policy'),
          },
          {
            href: pages['/terms-of-use'],
            label: t('terms_of_use'),
          },
          {
            href: pages['/privacy-policy'],
            label: t('privacy_policy'),
          },
        ],
      },
      {
        title: t('find_out_more'),
        links: [
          {
            href: pages['/about-fabnite'],
            label: t('about_fabnite'),
          },
          {
            href: pages['/help-and-contact'],
            label: t('help_and_contact'),
          },
          {
            href: 'https://start.fabnite.com',
            label: t('sell_tickets_with_fabnite'),
            target: '_blank',
          },
        ],
      },
      {
        title: t('follow_us'),
        links: [
          {
            href: 'https://www.facebook.com/fabnite',
            label: t('social_media_FACEBOOK'),
            target: '_blank',
          },
          {
            href: 'https://www.instagram.com/fabniteapp',
            label: t('social_media_INSTAGRAM'),
            target: '_blank',
          },
        ],
      },
    ],
    [t],
  )

  return (
    <footer>
      {dialogSubscribeNewsletter.isOpen &&
        dialogSubscribeNewsletter.value !== null && (
          <DialogSubscribeNewsletter
            onClose={dialogSubscribeNewsletter.close}
            defaultEmail={dialogSubscribeNewsletter.value.contactEmail}
          />
        )}
      <Box
        sx={{
          backgroundColor: 'primary3.main',
          pt: { xs: 4, sm: 5, lg: 6 },
          pb: 6,
          color: 'common.white',
        }}
      >
        <Container maxWidth="md">
          <NextLink href={pages['/']}>
            <FabniteFooterIcon
              sx={{ mb: { xs: 3, lg: 5 }, fontSize: 40, display: 'block' }}
            />
          </NextLink>
          <Stack spacing={{ xs: 4, sm: 3, lg: 5 }}>
            <Stack
              spacing={{ xs: 4, sm: 0 }}
              mr={{ sm: 3.5, md: 0 }}
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={{ sm: 'space-between' }}
            >
              {footerSections.map((footerSection) => (
                <FooterSection {...footerSection} key={footerSection.title} />
              ))}
              <Box display={{ xs: 'none', md: 'block' }}>
                <AppLinks />
              </Box>
            </Stack>
            {$contacts.isSuccess && !$contacts.data.mailingSubscribed && (
              <Stack spacing={{ xs: 1, lg: 1.5 }}>
                <Typography variant="h3ExtraBold">
                  {t('stay_up_to_date')}
                </Typography>
                <Stack
                  spacing={{ xs: 2, sm: 3 }}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant="bodyRegular" color="accent1.main">
                    {t('subscribe_to_fabnite_description')}
                  </Typography>

                  <ExploreButton
                    onClick={() =>
                      dialogSubscribeNewsletter.open({
                        contactEmail:
                          toNullable($contacts.data.contactEmail) ?? '',
                      })
                    }
                    label={t('subscribe')}
                    sx={{ alignSelf: { xs: 'start', sm: 'center' } }}
                  />
                </Stack>
              </Stack>
            )}
            <Box display={{ md: 'none' }}>
              <AppLinks />
            </Box>
          </Stack>
          <Divider sx={{ my: 5, opacity: 0.4 }} />
          <Stack spacing={{ xs: 3, lg: 3.5 }}>
            <FooterPaymentProviders />
            <Typography variant="bodyRegular">
              {t('all_rights_reserved', { year: new Date().getFullYear() })}
            </Typography>
          </Stack>
        </Container>
      </Box>
    </footer>
  )
}
