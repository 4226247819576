import { array, keyof, TypeOf } from 'io-ts'

const TMonth = keyof({
  JANUARY: null,
  FEBRUARY: null,
  MARCH: null,
  APRIL: null,
  MAY: null,
  JUNE: null,
  JULY: null,
  AUGUST: null,
  SEPTEMBER: null,
  OCTOBER: null,
  NOVEMBER: null,
  DECEMBER: null,
})
export type IMonth = TypeOf<typeof TMonth>

export const TMonths = array(TMonth)
export type IMonths = TypeOf<typeof TMonths>
