import toast from 'react-hot-toast'
import { theme } from 'src/app/theme/theme'
import { AlertErrorIcon } from 'src/assets/icons/alert/error'
import { AlertSuccessIcon } from 'src/assets/icons/alert/success'

export const toasterProps = {
  position: 'bottom-right',
  toastOptions: {
    success: {
      icon: <AlertSuccessIcon />,
      style: {
        background:
          'linear-gradient(0deg, rgba(74, 195, 152, 0.05), rgba(74, 195, 152, 0.05)), #FFFFFF',
        borderColor: theme.palette.secondary2.main,
        color: theme.palette.secondary2.main,
        ...theme.typography.smallTextSemiBold,
      },
    },
    error: {
      icon: <AlertErrorIcon />,
      style: {
        background:
          'linear-gradient(0deg, rgba(252, 96, 91, 0.05), rgba(252, 96, 91, 0.05)), #FFFFFF',
        borderColor: theme.palette.primary2.main,
        color: theme.palette.primary2.main,
        ...theme.typography.smallTextSemiBold,
      },
    },
    style: {
      border: '1px solid',
      borderRadius: '6px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
      padding: '5px 16px',
    },
  },
} as const

export const toastError = toast.error
export const toastSuccess = toast.success
export const toastDismiss = toast.dismiss
