import {
  array,
  boolean,
  intersection,
  literal,
  number,
  record,
  string,
  type,
  TypeOf,
  union,
} from 'io-ts'
import { nonEmptyArray, optionFromNullable, UUID } from 'io-ts-types'
import { enumToKeyof, TOptionalString } from 'src/lib/types'
import { TArtist } from '../artist/artist.codecs'
import { TVenue } from '../venue/venue.codecs'
import { TAvailableLanguages } from 'src/app/locale'
import { TImage } from 'src/api/file'

const TTag = type({
  name: string,
})

// Event place

export enum EventPlace {
  VENUE = 'VENUE',
  VENUE_WITH_MAP = 'VENUE_WITH_MAP',
  TO_BE_ANNOUNCED = 'TO_BE_ANNOUNCED',
}

const TEventPlace = union([
  type({
    placeType: literal(EventPlace.VENUE),
    venue: TVenue,
  }),
  type({
    placeType: literal(EventPlace.VENUE_WITH_MAP),
    venue: TVenue,
  }),
  type({
    placeType: literal(EventPlace.TO_BE_ANNOUNCED),
    announcedCity: string,
  }),
])
export type IEventPlace = TypeOf<typeof TEventPlace>

// Event schedule

const TEventSchedule = type({
  startDate: string,
  timezone: string,
  endDate: string,
})
export type IEventSchedule = TypeOf<typeof TEventSchedule>

export enum EventTimeframe {
  ONE_DAY = 'ONE_DAY',
  MULTIPLE_DAYS = 'MULTIPLE_DAYS',
}

// Event programme / artists

export enum EventProgrammeStatus {
  HIDDEN = 'HIDDEN',
  LIST = 'LIST',
  PROGRAMME = 'PROGRAMME',
  POSTER = 'POSTER',
}

export enum EventArtistAct {
  MAIN = 'MAIN',
  SUPPORT = 'SUPPORT',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export const TEventArtist = type({
  act: enumToKeyof(EventArtistAct),
  performer: TArtist,
})
export type IEventArtist = TypeOf<typeof TEventArtist>

const TEventArtistsByAct = record(
  enumToKeyof(EventArtistAct),
  optionFromNullable(array(TEventArtist)),
)
export type IEventArtistsByAct = TypeOf<typeof TEventArtistsByAct>

export const TEventArtists = union([
  type({
    status: literal(EventProgrammeStatus.HIDDEN),
  }),
  type({
    status: literal(EventProgrammeStatus.POSTER),
  }),
  type({
    status: literal(EventProgrammeStatus.PROGRAMME),
  }),
  type({
    status: literal(EventProgrammeStatus.LIST),
    performersByAct: TEventArtistsByAct,
  }),
])
export type IEventArtists = TypeOf<typeof TEventArtists>

//Event type

const TEventType = type({
  name: string,
})

// Event price type

export enum EventPriceType {
  PAID = 'PAID',
  FREE = 'FREE',
}

// Event organization

const TEventOrganization = type({
  name: string,
  website: string,
  organizationId: UUID,
  organizationSlug: string,
  country: string,
  vatNumber: string,
})

export type IEventOrganization = TypeOf<typeof TEventOrganization>

//Event status
export enum EventStatus {
  PUBLISHED = 'PUBLISHED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED',
}

// Event schema
export const TEvent = type({
  name: string,
  eventId: UUID,
  country: string,
  mainImage: TImage,
  eventPlace: TEventPlace,
  eventTags: array(TTag),
  favourite: boolean,
  eventSchedules: nonEmptyArray(TEventSchedule),
  timeframe: enumToKeyof(EventTimeframe),
  eventSlug: string,
  status: enumToKeyof(EventStatus),
  eventType: TEventType,
  logoSetting: optionFromNullable(
    type({
      logo: TImage,
      logoBackgroundColor: string,
    }),
  ),
  priceType: enumToKeyof(EventPriceType),
  ticketsExist: boolean,
})
export type IEvent = TypeOf<typeof TEvent>

export const TEventDetails = intersection([
  TEvent,
  type({
    eventHumanId: string,
    ageLimit: optionFromNullable(number),
    description: string,
    subheading: TOptionalString,
    currency: TOptionalString,
    priceType: enumToKeyof(EventPriceType),
    organization: TEventOrganization,
    ticketsExist: boolean,
    availableLanguages: TAvailableLanguages,
    hidden: boolean,
  }),
])
export type IEventDetails = TypeOf<typeof TEventDetails>
