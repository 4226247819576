import { Stack, Typography, useTheme } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'
import { ResponsiveStyleValue } from '@mui/system'
import { CardBrand, IUserSource } from 'src/api'
import {
  AmericanExpressLogoIcon,
  MastercardLogoIcon,
  VisaLogoIcon,
} from 'src/assets/icons'

const cardBrandLogos = {
  [CardBrand.visa]: VisaLogoIcon,
  [CardBrand.mastercard]: MastercardLogoIcon,
  [CardBrand.amex]: AmericanExpressLogoIcon,
}

export type PaymentCardProps = {
  card: IUserSource
  onClick: () => void
  isDefault: boolean
}

type Props = PaymentCardProps & {
  isShowDefault?: boolean
  actions?: ReactNode
  border?: string
  background?: string
  py?: ResponsiveStyleValue<number>
}

export const PaymentCard = ({
  card,
  onClick,
  isDefault,
  isShowDefault,
  actions,
  border,
  background,
  py,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const CardLogo = cardBrandLogos[card.details.brand]

  return (
    <Stack
      sx={{
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '6px',
        p: { xs: 2, lg: 2.25 },
        border,
        background: background ?? theme.palette.common.white,
        py,
        cursor: { md: 'pointer' },
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={isShowDefault ? 'center' : 'flex-start'}
      >
        <Stack spacing={{ xs: 1.875, lg: 1.5 }}>
          <Typography variant="smallTextSemiBold">
            {t(`card_brands_${card.details.brand}`)}
          </Typography>
          <Typography variant="smallTextSemiBold" color="secondary8.main">
            {`**** ${card.details.lastFour}`}
          </Typography>
        </Stack>
        <Stack spacing={{ xs: 1.125, lg: 1 }}>
          <Stack height={{ xs: 24 }} alignItems="flex-end">
            <CardLogo />
          </Stack>
          {isDefault && (isShowDefault ?? true) && (
            <Typography variant="smallTextSemiBold" fontStyle="italic">
              {t('default_card')}
            </Typography>
          )}
        </Stack>
      </Stack>
      {actions}
    </Stack>
  )
}
