import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Dot = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.282073 8.71902C1.12632 11.5459 3.77983 13.6614 6.87116 13.6658C10.2562 13.6706 13.0633 11.1431 13.5536 8.45233C14.3837 3.89766 8.58629 -1.17498 4.30594 0.242115C1.45505 1.18599 -0.816472 5.04073 0.282073 8.71902Z"
      fill="currentColor"
    />
  </SvgIcon>
)
